import React, { useRef, useEffect, useState } from "react"
import { Link } from "gatsby"
import { formatBedrooms } from "../../utils/format"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import useIntersectionObserver from "../../hooks/useIntersectionObserver"
import tagManager from "../../utils/tag-manager"

/** svg */
import StarIcon from "../../../assets/star-icon.svg"
import NorhartDarkCircleIcon from "../../../assets/norhart-dark-circle-icon.svg"
import { Community, FloorPlan } from "../../../plugins/gatsby-source-norhart"

/** props */
interface Props {
  backgroundColorOne: string
  backgroundColorTwo: string
  title: string
  subtitle: string
  community: Community
}

/** const */
const FloorPlanSection: React.FC<Props> = ({ community, backgroundColorOne, backgroundColorTwo }) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const entry = useIntersectionObserver(ref, {})
  const [eventSent, setEventSent] = useState(false)
  useEffect(() => {
    if (!!entry?.isIntersecting && !eventSent) {
      setEventSent(true),
        tagManager.push({
          event: "view-floorplan-list",
          units: community.floorPlans.map((floorPlan, index) => {
            return {
              index: index,
              regionId: community.region?.id,
              communityId: community.id,
              floorPlanId: floorPlan.id,
              floorPlanName: floorPlan.name,
              price: floorPlan.rentMax,
            }
          }),
        })
    }
  }, [entry])

  //divide floorplans into groups
  const floorPlanGroups: Map<number, FloorPlan[]> = new Map()
  for (const floorPlan of community.floorPlans) {
    let key = floorPlan.bedrooms
    //separate penthouses
    if (
      floorPlan.id === "a238c5b4-4365-11ee-b1a3-02d70267f789" || //Sanctuary
      floorPlan.id === "8aa606b4-4365-11ee-b1a3-02d70267f789" //Utopia
    ) {
      key = 9
    }
    if (!floorPlanGroups.has(key)) floorPlanGroups.set(key, [])
    floorPlanGroups.get(key).push(floorPlan)
  }

  const floorPlanComponents = []

  for (let bedrooms = 0; bedrooms < 10; bedrooms++) {
    const floorPlans = floorPlanGroups.get(bedrooms)
    if (floorPlans == null) continue
    const floorPlanGroup = {
      bedrooms: bedrooms,
      floorPlans: floorPlans,
    }
    floorPlanComponents.push(<FloorPlanComponent floorPlanGroup={floorPlanGroup} />)
  }

  return (
    <section
      className="container-fluid p-3"
      style={{ background: `linear-gradient(${backgroundColorOne} 30%, ${backgroundColorTwo} 70%)` }}
      ref={ref}
    >
      {floorPlanComponents}
    </section>
  )
}
/** export */
export default FloorPlanSection

interface FloorPlanComponentProps {
  floorPlanGroup: {
    /** 0 means studio; 9 means penthouse */
    bedrooms: number
    floorPlans: any[]
  }
}

const FloorPlanComponent: React.FC<FloorPlanComponentProps> = (props) => {
  const floorPlanGroup = props.floorPlanGroup
  const floorPlans = floorPlanGroup.floorPlans.sort((a, b) => {
    return b.availableDate != null ? 1 : 0
  })

  let description

  switch (floorPlanGroup.bedrooms) {
    case 0:
      description =
        "Choose our studio floor-plan. We carefully designed each space to be spacious, functional, and luxurious. Just for you!"
      break
    case 1:
      description =
        "Choose our one bedroom floor-plan. We carefully designed each space to be spacious, functional, and luxurious. Just for you!"
      break
    case 2:
      description =
        "Choose our two bedroom floor-plan. We carefully designed each space to be spacious, functional, and luxurious. Just for you!"
      break
    case 3:
      description =
        "Choose our three bedroom floor-plan. We carefully designed each space to be spacious, functional, and luxurious. Just for you!"
      break
    case 9:
      description =
        "Choose our penthouse floor-plan. We carefully designed each space to be spacious, functional, and luxurious. Just for you!"
      break
  }
  return (
    <div>
      <a id="NavFloorPlans"></a>
      <div className="container pl-3 pr-3">
        <div className="container pb-5"></div>
        {floorPlanGroup.bedrooms != 0 && (
          <hr
            className="floor-plan-header hidden-sm hidden-md hidden-lg hidden-xl style-line mt-4 mb-4"
            style={{ borderColor: "rgba(0, 0, 0, 0.2)" }}
          />
        )}

        <h3 className="contentHeroTitle" style={{ color: "rgba(54,92,112,1.0)" }}>
          {formatBedrooms(floorPlanGroup.bedrooms)} Apartment
        </h3>

        <h2 className="contentHeroSubTitle mb-0" style={{ color: "rgba(54,92,112,0.8)" }}>
          {description}
        </h2>

        <div className="col-12 container-fluid text-center pt-2 mt-2">
          <hr style={{ border: "0.5px solid rgba(54,92,112,0.2)" }} />
        </div>

        {floorPlans.map((floorPlan) => {
          return (
            <div
              className="row mt-5"
              style={{
                opacity: floorPlan.availableDate == null ? 0.25 : 1.0,
              }}
            >
              <div className="col-sm-6 mt-4">
                <h1 className="floor-plan-header">{floorPlan.name} </h1>
                <span
                  className="badge badge-primary ms-0 mt-0 mb-2"
                  style={{
                    fontSize: "0.9rem",
                    verticalAlign: "middle",
                    backgroundColor: floorPlan.availableDate == null ? "#CCCCCC" : "#ff7200",
                  }}
                >
                  {floorPlan.availableDate == null ? "Not Available" : "Now Available"}
                </span>
                {floorPlan.rentMin && (
                  <h2 className="floor-plan-image-header ml-0 mt-2 p-0">Starting at ${floorPlan.rentMin}/mo*</h2>
                )}
                <h3 className="floor-plan-image-header ml-0 p-0">{floorPlan.squareFeetMin} Sq Ft</h3>
                <h3 className="floor-plan-sub-header mt-3 ml-0 p-0">{description}</h3>
                <h6 className="floor-plan-sub-header mt-3 ml-0 p-0">
                  {(floorPlan.promotionalMessage ?? "").includes("Promo") == true && <StarIcon className="mb-1" />}{" "}
                  {floorPlan.promotionalMessage}
                </h6>
                <div className="d-flex justify-content-left mt-3 mb-5">
                  <Link
                    key={floorPlan.name}
                    to={floorPlan.urlPath + "/"}
                    className="floor-plan-button btn btn-md btn-light"
                    style={{ backgroundColor: floorPlan.availableDate == null ? "#CCCCCC" : "#259dff" }}
                  >
                    View Floor Plan Details
                  </Link>
                </div>
              </div>
              {floorPlan.floorPlanImage && (
                <div className="col-sm-6 mb-5">
                  <GatsbyImage
                    className="pb-0 pl-0 pr-0 d-none d-sm-block crop"
                    alt={floorPlan.name + " Floor Plan"}
                    image={getImage(floorPlan.floorPlanImage.localFile)}
                  />

                  <GatsbyImage
                    className="pb-0 pt-5 mt-0 d-block d-sm-none w-100"
                    alt={floorPlan.name + " Floor Plan"}
                    image={getImage(floorPlan.floorPlanImage.localFile)}
                  />
                </div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}
