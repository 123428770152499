import {
  IAmenityGroup as AmenityGroup,
  IPetType as PetType,
  IParkingType as ParkingType,
} from "../../../plugins/gatsby-source-norhart"
import chunk from "lodash.chunk"
import uniqBy from "lodash.uniqby"
import { formatBedrooms, formatRentRange } from "../../utils/format"
import React from "react"

/** props */
interface Props {
  icon: any
  fontTextColor?: string
  iconTitle: string
  title: string
  subtitle: string
  backgroundColorOne: string
  backgroundColorTwo: string
  community: any
}

const SpecsSection: React.FC<Props> = (props) => {
  const Icon = props.icon

  let community = props.community

  let amenities: any[] = community.amenities || []
  community.floorPlans.forEach((floorPlan) => {
    amenities = amenities.concat(floorPlan.amenities)
  })
  amenities = uniqBy(amenities, "name")

  const outdoorAmenities = amenities.filter((item) => item?.amenityGroup == AmenityGroup.OutdoorSpace)

  let communityRentMins: number[] = props.community.rentRanges?.map((range) => range.rentMin) || []
  let startingRentMin = Math.min(...communityRentMins)
  startingRentMin = Number.isFinite(startingRentMin) ? startingRentMin : null

  return (
    <section
      className="col-12 p-5"
      style={{
        background: `linear-gradient(${props.backgroundColorOne} 30%, ${props.backgroundColorTwo} 70%)`,
        color: "#365c70",
      }}
    >
      <section className="container-fluid text-center pt-0 pb-5">
        <a id="NavSpecs"></a>
        <div className="container pl-3 pr-3 text-center">
          <div className="container pb-5"></div>
          <div className="text-center pb-2">
            <Icon />
          </div>
          <div
            className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl"
            style={{ fontSize: "15px", color: `${props.fontTextColor}` }}
          >
            {props.iconTitle}
          </div>
          <h3 className="contentHeroTitle" style={{ color: "#365c70" }}>
            {props.title}
          </h3>
          <h2 className="contentHeroSubTitle" style={{ color: "#365c70", opacity: "0.7" }}>
            {props.subtitle}
          </h2>
        </div>
      </section>

      <section className="col-12 container-fluid text-center pt-2 pb-2">
        <div className="container pl-2 pr-2">
          <hr style={{ border: "0.5px solid rgba(54,92,112,0.2)", marginTop: "10px", marginBottom: "10px" }} />
        </div>
      </section>

      <section className="container-fluid text-left pt-1 pb-1">
        <div className="container">
          <div className="row mt-5 mb-5">
            <div className="col-sm font-weight-bold text-left ml-0 mb-3" style={{ fontSize: "24px" }}>
              <strong>Price</strong>
            </div>
            <div className="col-sm">
              <ul className="list-group text-left">
                <li className="mb-2 ms-0 glance-text font-weight-bold ml-0" style={{ listStyleType: "none" }}>
                  <strong>Base</strong>
                </li>

                {startingRentMin != null ? (
                  <li className="mb-5 ms-4 glance-text">Starting at ${startingRentMin}</li>
                ) : (
                  "No Units Available"
                )}
              </ul>
            </div>

            <div className="col-sm">
              <ul className="list-group text-left">
                <li className="mb-2 ms-0 glance-text font-weight-bold ml-0" style={{ listStyleType: "none" }}>
                  <strong>Apartments</strong>
                </li>
                {props.community.rentRanges?.length > 0
                  ? props.community.rentRanges.map((rentRange, index) => (
                      <li className="mb-2 ms-4 glance-text" key={index}>
                        {formatBedrooms(rentRange.bedrooms)}: {formatRentRange(rentRange)}{" "}
                      </li>
                    ))
                  : "No Units Available"}
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="col-12 container-fluid text-center pt-2 pb-2">
        <div className="container pl-2 pr-2">
          <hr style={{ border: "0.5px solid rgba(54,92,112,0.2)", marginTop: "10px", marginBottom: "10px" }} />
        </div>
      </section>

      <AmenitySection title="Property" items={amenities.filter((item) => item?.amenityGroup == AmenityGroup.Feature)} />

      <section className="col-12 container-fluid text-center pt-2 pb-2">
        <div className="container pl-2 pr-2">
          <hr style={{ border: "0.5px solid rgba(54,92,112,0.2)", marginTop: "10px", marginBottom: "10px" }} />
        </div>
      </section>

      <AmenitySection
        title="Apartment"
        items={amenities.filter((item) => item?.amenityGroup == AmenityGroup.UniqueUnitAmenity)}
      />

      <section className="col-12 container-fluid text-center pt-2 pb-2">
        <div className="container pl-2 pr-2">
          <hr style={{ border: "0.5px solid rgba(54,92,112,0.2)", marginTop: "10px", marginBottom: "10px" }} />
        </div>
      </section>

      <AmenitySection
        title="Comfort"
        items={amenities.filter((item) => item?.amenityGroup == AmenityGroup.LivingSpace)}
      />

      <section className="col-12 container-fluid text-center pt-2 pb-2">
        <div className="container pl-2 pr-2">
          <hr style={{ border: "0.5px solid rgba(54,92,112,0.2)", marginTop: "10px", marginBottom: "10px" }} />
        </div>
      </section>

      <AmenitySection title="Kitchen" items={amenities.filter((item) => item?.amenityGroup == AmenityGroup.Kitchen)} />

      <section className="col-12 container-fluid text-center pt-2 pb-2">
        <div className="container pl-2 pr-2">
          <hr style={{ border: "0.5px solid rgba(54,92,112,0.2)", marginTop: "10px", marginBottom: "10px" }} />
        </div>
      </section>

      <AmenitySection
        title="Extras"
        items={amenities.filter((item) => item?.amenityGroup == AmenityGroup.UniquePropertyAmenity)}
      />

      <section className="col-12 container-fluid text-center pt-2 pb-2">
        <div className="container pl-2 pr-2">
          <hr style={{ border: "0.5px solid rgba(54,92,112,0.2)", marginTop: "10px", marginBottom: "10px" }} />
        </div>
      </section>

      <AmenitySection
        title="Recreation"
        items={amenities.filter((item) => item?.amenityGroup == AmenityGroup.FitnessAndRecreation)}
      />

      <section className="col-12 container-fluid text-center pt-2 pb-2">
        <div className="container pl-2 pr-2">
          <hr style={{ border: "0.5px solid rgba(54,92,112,0.2)", marginTop: "10px", marginBottom: "10px" }} />
        </div>
      </section>

      <PetPolicySection community={props.community} />

      <section className="col-12 container-fluid text-center pt-2 pb-2">
        <div className="container pl-2 pr-2">
          <hr style={{ border: "0.5px solid rgba(54,92,112,0.2)", marginTop: "10px", marginBottom: "10px" }} />
        </div>
      </section>

      {outdoorAmenities.length > 0 && <AmenitySection title="Outdoors" items={outdoorAmenities} />}

      {outdoorAmenities.length > 0 && (
        <section className="col-12 container-fluid text-center pt-2 pb-2">
          <div className="container pl-2 pr-2">
            <hr style={{ border: "0.5px solid rgba(54,92,112,0.2)", marginTop: "10px", marginBottom: "10px" }} />
          </div>
        </section>
      )}

      <ParkingSection community={props.community} />

      <section className="col-12 container-fluid text-center pt-2 pb-2">
        <div className="container pl-2 pr-2">
          <hr style={{ border: "0.5px solid rgba(54,92,112,0.2)", marginTop: "10px", marginBottom: "10px" }} />
        </div>
      </section>

      <UtilitiesSection community={props.community} />
    </section>
  )
}
/** export */
export default SpecsSection

interface PetPolicySectionProps {
  community: any
}

const PetPolicySection: React.FC<PetPolicySectionProps> = (props) => {
  const petPolicies = props.community.petPolicies

  const dogsAllowed = petPolicies?.find((policy) => policy.petType == PetType.Dog)?.allowed ?? false
  const catsAllowed = petPolicies?.find((policy) => policy.petType == PetType.Cat)?.allowed ?? false
  const maxPerHousehold = Math.max(...petPolicies.map((policy) => policy.maxAllowed))

  if (dogsAllowed || catsAllowed) {
    return (
      <>
        {/*         {petPolicies.map((petPolicy) => (
          <>
          <div>{petPolicy.comment}</div>
          <div>{petPolicy.maxAllowed}</div>
      </>
        ))} */}

        <section className="container-fluid text-left pt-1 pb-1">
          <div className="container">
            <div className="row mt-5 mb-5">
              <div className="col-sm-4 font-weight-bold text-left ml-0 mb-3" style={{ fontSize: "24px" }}>
                <strong>Pet Policy</strong> <br />{" "}
                <span className="fs-6 me-5">{maxPerHousehold} pets allowed per household.</span>
              </div>

              {petPolicies.map((petPolicy) => {
                let policyType
                switch (petPolicy.petType) {
                  case "DOG":
                    policyType = "Dog"
                    break
                  case "CAT":
                    policyType = "Cat"
                    break
                  default:
                    break
                }

                return (
                  <div className="col-sm-4">
                    <ul className="list-group text-left m-2">
                      <li className="mb-2 ms-0 glance-text font-weight-bold ml-0" style={{ listStyleType: "none" }}>
                        <strong className="">{policyType} Policies</strong>
                      </li>
                      <li className="mb-2 ms-4 glance-text">No weight limit</li>
                      <li className="mb-2 ms-4 glance-text">No breed restrictions</li>
                      <li className="mb-2 ms-4 glance-text">Pet Limit: {petPolicy.maxAllowed}</li>
                      {petPolicy.deposit && <li className="mb-2 ms-4 glance-text">Deposit: ${petPolicy.deposit}</li>}
                      <li className="mb-2 ms-4 glance-text">One-time fee: {petPolicy.feeForFirst}</li>
                      <li className="mb-2 ms-4 glance-text">Monthly Fee: {petPolicy.rent}</li>
                      {petPolicy.dnaFee && <li className="mb-2 ms-4 glance-text">DNA Fee: {petPolicy.dnaFee}</li>}
                    </ul>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
      </>
    )
  } else {
    return (
      <section className="container-fluid text-left pt-1 pb-1">
        <div className="container">
          <div className="row mt-5 mb-5">
            <div className="col-sm font-weight-bold text-left ml-0 mb-3" style={{ fontSize: "24px" }}>
              <strong>Pet Policy</strong>
            </div>
            <div className="col-sm">
              <ul className="list-group text-left">
                <li className="mb-2 ms-4 glance-text">No pets allowed</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

interface AmenitySectionProps {
  title: string
  items: any[]
}

const AmenitySection: React.FC<AmenitySectionProps> = (props) => {
  let chunks = chunk(props.items, Math.ceil(props.items.length / 2))

  return (
    <section className="container-fluid text-left pt-1 pb-1">
      <div className="container">
        <div className="row mt-5 mb-5">
          <div className="col-sm font-weight-bold text-left ml-0 mb-3" style={{ fontSize: "24px" }}>
            <strong>{props.title}</strong>
          </div>
          {chunks.map((chunk) => {
            return (
              <div className="col-sm">
                <ul className="list-group text-left ms-4">
                  {chunk.map((item) => {
                    return <li className="mb-2 ml-4 glance-text">{item.name}</li>
                  })}
                </ul>
              </div>
            )
          })}

          {chunks.length == 1 && <div className="col-sm"></div>}
        </div>
      </div>
    </section>
  )
}

interface ParkingSectionProps {
  community: any
}

const ParkingSection: React.FC<ParkingSectionProps> = (props) => {
  return (
    <section className="container-fluid text-left pt-1 pb-1">
      <div className="container">
        <div className="row mt-5 mb-5">
          <div className="col-sm font-weight-bold text-left ml-0 mb-3" style={{ fontSize: "24px" }}>
            <strong>Parking</strong>
          </div>

          <div className="col-sm">
            <ul className="list-group text-left ms-4">
              {props.community.parkingSpaces.map((item, index) => {
                if (item == null) return
                let type
                switch (item.parkingType) {
                  case ParkingType.Surface:
                    type = "Surface Lot"
                    break
                  case ParkingType.Underground:
                    type = "Underground Parking"
                    break
                  case ParkingType.Garage:
                    type = "Garage"
                    break
                }

                const fee = item.fee && item.fee > 0 ? "$" + item.fee + "/month " : ""

                let details
                if (item.includedSpacesPerUnit == null || item.includedSpacesPerUnit == "0") {
                  if (item.assigned) {
                    details = "Assigned Parking"
                  } else {
                    details = ""
                  }
                } else if (item.includedSpacesPerUnit == "1") {
                  if (item.assigned) {
                    details = "1 Assigned Parking Space"
                  } else {
                    details = "1 Parking Space"
                  }
                } else {
                  if (item.assigned) {
                    details = item.includedSpacesPerUnit + " Assigned Parking Spaces"
                  } else {
                    details = item.includedSpacesPerUnit + " Parking Spaces"
                  }
                }

                if ("" + fee + details == "") {
                  return (
                    <li key={index} className="mb-2 ml-4 glance-text">
                      {type}
                    </li>
                  )
                } else {
                  return (
                    <li key={index} className="mb-2 ml-4 glance-text">
                      {type + ": " + fee + details}
                    </li>
                  )
                }
              })}
            </ul>
          </div>

          <div className="col-sm"></div>
        </div>
      </div>
    </section>
  )
}

interface UtilitiesSectionProps {
  community: any
}

const UtilitiesSection: React.FC<UtilitiesSectionProps> = (props) => {
  const utilities = props.community.utilities
  const filteredUtilities = utilities.filter((item) => item?.included === false)

  return (
    <>
      <section className="container-fluid text-left pt-1 pb-1">
        <div className="container">
          <div className="row mt-5 mb-5">
            <div className="col-sm-4 font-weight-bold text-left ml-0 mb-3" style={{ fontSize: "24px" }}>
              <strong>Utilities</strong>
            </div>

            <div className="col-sm-4">
              <ul className="list-group text-left m-2">
                <li className="mb-2 ms-0 glance-text">Residents are responsible for</li>
              </ul>
            </div>

            <div className="col-sm-4">
              <ul className="list-group text-left m-2">
                {filteredUtilities.map((utility) => {
                  let utilityType
                  switch (utility.utilityType) {
                    case "WATER":
                      utilityType = "Water/Sewer"
                      break
                    case "GAS":
                      utilityType = "Gas"
                      break
                    case "TRASH":
                      utilityType = "Trash"
                      break
                    case "HEAT":
                      utilityType = "Heat"
                      break
                    case "HOT_WATER":
                      utilityType = "Hot Water"
                      break
                    case "SEWER":
                      utilityType = "Sewer"
                      break
                    case "ELECTRICITY":
                      utilityType = "Electricity"
                      break
                    default:
                      utilityType = utility.utilityType
                      break
                  }

                  return <li className="mb-2 ms-0 glance-text">{utilityType}</li>
                })}
              </ul>
            </div>
            <div className="mt-5"></div>
            <hr />

            <div className="mt-2">
              <strong>Non-optional Fees Disclaimer</strong> In addition to the rent amount the following non-optional
              fees apply: Monthly Trash Fee $5 per unit per month. Monthly Pest Control Fee $1 per unit per month.
              Utility Administration Fee (Oakdale, Lexington Lofts, Encore, Gateway Green) $5 per unit per month.
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
