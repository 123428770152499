import Region from "./region"
import MediaItem from "./mediaItem"
import FloorPlan from "./floorPlan"
import Amenity from "./amenity"
import Property from "./property"
import { IPetType, IUtilityType } from "../graphql/generated"

export interface Community {
  id: string
  additionalDescription?: string | null
  adminFee: string
  allowOnlineApplications: boolean
  amenities: Amenity[]
  applicationFee: string
  bedroomsMax?: number | null
  bedroomsMin?: number | null
  drivingDirections?: string | null
  emailFragment?: string | null
  featuredImage: MediaItem
  floorPlans?: FloorPlan[]
  images: MediaItem[]
  longDescription?: string | null
  name: string
  officeHours?: OfficeHour[] | null
  parkingSpaces?: Array<ParkingSpace> | null
  petPolicies?: Array<PetPolicy> | null
  primaryProperty?: Pick<Property, "id"> | null
  promotionalMessage?: string | null
  region: Region
  rentMax?: string | null
  rentMin?: string | null
  rentRanges?: RentRange[] | null
  shortDescription?: string | null
  showOnWebsite: boolean
  url: string
  urlPath: string
  urlSlug?: string | null
  utilities: Utility[]
  websitePromotionalMessage?: string | null
  youtubeUrl?: string | null
}

/** export */
export default Community

interface ParkingSpace {
  parkingType: ParkingType
  maxSpacesPerUnit?: number | null
  includedSpacesPerUnit?: number | null
  assigned?: boolean | null
  fee?: number | null
}

interface PetPolicy {
  petType: IPetType
  additionalRestrictions?: string | null
  allowed: boolean
  comment?: string | null
  deposit?: string | null
  feeForAdditional?: string | null
  feeForFirst?: string | null
  dnaFee?: string | null
  maxAllowed?: number | null
  maxWeight?: number | null
  rent?: string | null
}

export enum ParkingType {
  Garage = "GARAGE",
  Underground = "UNDERGROUND",
  Surface = "SURFACE",
}

export interface Utility {
  utilityType: IUtilityType
  included: boolean
}

export interface RentRange {
  bedrooms: number
  rentMin: number
  rentMax: number
}

interface OfficeHour {
  dayOfWeek?: string | null
  openTime?: string | null
  closeTime?: string | null
}
