import React from "react"
import { graphql, PageProps, Script } from "gatsby"
import { Helmet } from "react-helmet"
import config from "../../site.config"
import tagManager from "../utils/tag-manager"

/** components */
import LayoutCommunity from "../components/LayoutCommunity"
import { theme } from "../components/Theme"

/** dynamic sections */
import RibbonCommunitySection from "../components/dynamic-sections/RibbonCommunitySection"
import FourImageSmallSection from "../components/dynamic-sections/FourImageSmallSection"
import GalleryImageSection from "../components/dynamic-sections/GalleryImageSection"
import FloorPlanSection from "../components/dynamic-sections/FloorPlanSection"
import SpecsSection from "../components/dynamic-sections/SpecsSection"
import AwardsSection from "../components/dynamic-sections/AwardsSection"
import HeroTopCommunitySection from "../components/dynamic-sections/HeroTopCommunitySection"
import HeroBottomSection from "../components/dynamic-sections/HeroBottomSection"
import GalleryImageCommunitySection from "../components/dynamic-sections/GalleryImageCommunitySection"
import HeroRightSection from "../components/dynamic-sections/HeroRightSection"
import VideoCommunitySection from "../components/dynamic-sections/VideoCommunitySection"

/** svg */
import ApartmentIcon from "../../assets/apartment-icon.svg"
import BadgeCheckIcon from "../../assets/badge-check-icon.svg"
import NorhartIcon from "../../assets/norhart-icon.svg"
import GalleryIcon from "../../assets/gallery-icon.svg"
import VideoIcon from "../../assets/video-icon.svg"
import NorhartDarkCircleIcon from "../../assets/norhart-dark-circle-icon.svg"

import { useEffect } from "react"
import useApartmentData from "../utils/useApartmentData"

/** color theme */
const colorPalette = theme.colorPalettes.blue

type CommunityProps = PageProps<{ community; allCommunityLeadSource }, { id: string }, unknown>

const CommunityTemplate: React.FC<CommunityProps> = (props) => {
  useEffect(() => {
    tagManager.push({
      event: "view-community",
      units: [
        {
          index: 0,
          regionId: props.data.community.region?.id,
          communityId: props.data.community.id,
          communityName: props.data.community.name,
        },
      ],
    })
  }, [])

  const { seoTitle, seoDescription, seoKeywords, seriesLabel, phoneLabel, convertUrl } = useApartmentData(
    props.data.community.urlSlug
  )

  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": props.location.pathname,
          name: seoTitle,
          image: `${config.siteMetadata.siteUrl}/apartments/communities/${props.data.community.urlSlug}/norhart-hero-open-graph.png`,
        },
      },
    ],
  }

  const luvWidget = getLUVWidget(props.data.community.name)

  return (
    <LayoutCommunity
      title={seoTitle}
      description={seoDescription}
      keywords={seoKeywords}
      convertUrl={convertUrl}
      luvWidget={luvWidget != null}
      imageTwitter={`${config.siteMetadata.siteUrl}/apartments/communities/${props.data.community.urlSlug}/norhart-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/apartments/communities/${props.data.community.urlSlug}/norhart-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <HeroTopCommunitySection image={props.data.community.featuredImage} />

      <RibbonCommunitySection
        title={`${props.data.community.name}`}
        tagLine={`${props.data.community.primaryProperty.addressLine1} ${props.data.community.primaryProperty.city}, ${props.data.community.primaryProperty.state}  ${props.data.community.primaryProperty.postalCode}`}
        phoneLabel={phoneLabel}
        seriesLabel={seriesLabel}
        colorPalette={colorPalette}
      />

      {/* <HeroBottomSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Apartments"
        title={`${props.data.community.name}`}
        subTitle={`We invite you to look at our exceptional apartments in the ${props.data.community.primaryProperty.city} area. We love to create living experiences where you belong. Make a Norhart apartment your next home.`}
        image="/apartments/norhart-apartments-life-people.png"
        imageAlt="Norhart Support Team"
        textColor={colorPalette.textColor}
      /> */}

      {props.data.community.youtubeUrl && (
        <VideoCommunitySection
          backgroundColorOne={colorPalette.heroTop}
          backgroundColorTwo={colorPalette.heroBottom}
          icon={VideoIcon}
          iconTitle="Video"
          title="Experience #NorhartCulture"
          subtitle="At Norhart our teams have designed and built an exceptional living experience from top to bottom just for you! Go ahead take a look around at what your next home looks like."
          colorPalette={colorPalette}
          youtubeUrl={props.data.community.youtubeUrl}
        />
      )}

      <GalleryImageCommunitySection
        backgroundColorOne="#EFEFEF"
        backgroundColorTwo="#F0F0F0"
        icon={GalleryIcon}
        iconTitle="Gallery"
        title="Take A Look Around"
        subtitle="At Norhart our teams have designed and built an exceptional living experience from top to bottom just for you! Go ahead take a look around at what your next home looks like."
        colorPalette={colorPalette}
        community={props.data.community}
      />

      <HeroRightSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentBottom}
        fontTextColor="#2f5265"
        titleTopLeft="Norhart Floorplans"
        badgeTitle="Now Available"
        title="Beautiful Living Areas"
        subtitle="We carefully designed each space to be spacious, functional, and luxurious. Just for you!"
        imageTitle="Norhart Apartment Floor Plans"
        image="/apartments/norhart-apartments-floor-plan.png"
        colorPalette={colorPalette}
        buttonName="Click for Rates"
        buttonUrl={convertUrl}
        colSizeOne={6}
        colSizeTwo={6}
        bottomMargin={5}
      />

      <FloorPlanSection
        backgroundColorOne="#FFFFFF"
        backgroundColorTwo="#FFFFFF"
        title="Spacious Floor Plans"
        subtitle="We pride ourselves on building apartments that completely focus on residence living experiences and quality of life end-to-end."
        community={props.data.community}
      />

      <FourImageSmallSection
        icon={BadgeCheckIcon}
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        title="Apartment Nice Little Extras"
        subtitle={`We invite you to choose ${props.data.community.name} as your next home. We have carefully crafted and curated a living experience that will enhance your life in every-way possible.`}
        imageOnePrice="$55/mo"
        imageHeaderOne="Add-on"
        imageTitleOne="Pet Friendly"
        imageSubTitleOne="We know how important your furry friends are :-)"
        imageOne="/apartments/norhart-apartments-pet-friendly.webp"
        imageOneAlt="Norhart Apartments are Pet Friendly"
        imageTwoPrice="$75+/mo"
        imageHeaderTwo="Add-on"
        imageTitleTwo="Parking"
        imageSubTitleTwo="With underground parking no more cold mornings!"
        imageTwo="/apartments/norhart-apartments-underground-parking.webp"
        imageTwoAlt="Norhart Undergroud Parking"
        imageThreePrice="Free"
        imageHeaderThree="Limited"
        imageTitleThree="Outdoor Balcony"
        imageSubTitleThree="Relax outside and soak up the sun and atmosphere."
        imageThree="/apartments/norhart-apartments-outdoor-balcony.webp"
        imageThreeAlt="Norhart Apartment Balcony"
        imageFourPrice="$50+/mo"
        imageHeaderFour="Limited"
        imageTitleFour="Internet"
        imageSubTitleFour="Plug and play with our in-unit WIFI $50+/mo."
        imageFour="/apartments/norhart-apartments-smart-home.webp"
        imageFourAlt="Norhart Smart Apartment App"
        colorPalette={colorPalette}
        priceActive={true}
        imageExtraOne="/apartments/gallery/extras/extras-1.webp"
        imageTitleExtraOne="Norhart Extra Image 1"
        imageExtraTwo="/apartments/gallery/extras/extras-2.webp"
        imageTitleExtraTwo="Norhart Extra Image 2"
        imageExtraThree="/apartments/gallery/extras/extras-3.webp"
        imageTitleExtraThree="Norhart Extra Image 3"
        imageExtraFour="/apartments/gallery/extras/extras-4.webp"
        imageTitleExtraFour="Norhart Extra Image 4"
        imageExtraFive="/apartments/gallery/extras/extras-5.webp"
        imageTitleExtraFive="Norhart Extra Image 5"
        imageExtraSix="/apartments/gallery/extras/extras-6.webp"
        imageTitleExtraSix="Norhart Extra Image 6"
        buttonColor="#FFFFFF"
        buttonName="View Apartments"
        buttonUrl="/apartments/"
      />

      <GalleryImageSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        icon={ApartmentIcon}
        iconTitle="Apartment Living"
        title="Loving Life at Norhart"
        subtitle="Norhart offers more than just the old boring plain apartment life. We create living experiences where your home life and your social life unite together. Just another way Norhart is making your life better."
        imageTitleOne="Norhart Loving Life Image 1"
        imageOne="/apartments/gallery/love-life/norhart-loving-life-1.webp"
        imageTitleTwo="Norhart Loving Life Image 2"
        imageTwo="/apartments/gallery/love-life/norhart-loving-life-2.webp"
        imageTitleThree="Norhart Loving Life Image 3"
        imageThree="/apartments/gallery/love-life/norhart-loving-life-3.webp"
        imageTitleFour="Norhart Loving Life Image 4"
        imageFour="/apartments/gallery/love-life/norhart-loving-life-4.webp"
        imageTitleFive="Norhart Loving Life Image 5"
        imageFive="/apartments/gallery/love-life/norhart-loving-life-5.webp"
        imageTitleSix="Norhart Loving Life Image 6"
        imageSix="/apartments/gallery/love-life/norhart-loving-life-6.webp"
        buttonUrl="/services/"
        colorPalette={colorPalette}
        badge={false}
      />

      <SpecsSection
        backgroundColorOne="#EFEFEF"
        backgroundColorTwo="#F0F0F0"
        icon={NorhartDarkCircleIcon}
        iconTitle="Apartment Specs"
        title={`${props.data.community.name} At A Glance`}
        subtitle="All Norhart properties are built with our residents in mind. We offer exceptional amenities in various apartments such as: internet cafe, outdoor pool, fitness center, dog run, movie room and much more!"
        community={props.data.community}
      />

      <AwardsSection
        header={`${props.data.community.name}`}
        title="Modern Luxury Apartments"
        subtitle="At Norhart our focus is to create exceptional apartments and living experiences. We build more than just apartments or units. Our core mission is to change peoples lives!"
        colorPalette={colorPalette}
      />
      {luvWidget}
    </LayoutCommunity>
  )
}

/** export */
/** export */
export default CommunityTemplate

export const pageQuery = graphql`
  query CommunityIndex($id: String!) {
    community(id: { eq: $id }) {
      url
      urlPath
      urlSlug
      amenities {
        name
        amenityGroup
      }
      youtubeUrl
      promotionalMessage
      websitePromotionalMessage
      id
      additionalDescription
      adminFee
      applicationFee
      allowOnlineApplications
      drivingDirections
      images {
        url
        displayOrder
        caption
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(breakpoints: [300, 400, 500, 600, 800, 1000, 1200], formats: WEBP)
          }
        }
      }
      longDescription
      name
      parkingSpaces {
        parkingType
        fee
        includedSpacesPerUnit
        maxSpacesPerUnit
        assigned
      }
      petPolicies {
        additionalRestrictions
        allowed
        comment
        deposit
        feeForAdditional
        feeForFirst
        maxAllowed
        maxWeight
        petType
        rent
      }
      primaryProperty {
        addressLine1
        addressLine2
        city
        dateBuilt
        dateRemodeled
        latitude
        longitude
        # squareFeet
        state
        postalCode
      }
      emailFragment
      youtubeUrl
      shortDescription
      featuredImage {
        url
        caption
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(width: 1920, formats: WEBP)
          }
        }
      }
      officeHours {
        dayOfWeek
        openTime
        closeTime
      }
      floorPlans {
        id
        description
        amenities {
          name
          amenityGroup
        }
        availableDate
        bathrooms
        bedrooms
        dens
        promotionalMessage
        promotionalStartDate
        promotionalEndDate
        floorPlanImage {
          url
          caption
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(breakpoints: [300, 400, 600, 900, 1200], formats: WEBP)
            }
          }
        }
        images {
          caption
          url
        }
        name
        rentMax
        rentMin
        squareFeetMax
        squareFeetMin
        urlPath
        youtubeUrl
        matterportUrl
      }
      region {
        id
        urlSlug
      }
      rentRanges {
        bedrooms
        rentMin
        rentMax
      }
      utilities {
        utilityType
        included
      }
    }
    allCommunityLeadSource {
      nodes {
        communityId
        communityName
        leadSourceAbbreviation
        leadSourceName
        emailAddress
        phoneNumber
      }
    }
  }
`

const getLUVWidget = (communityName: string) => {
  if (communityName == "Greystone Apartments")
    return (
      <>
        <Script>
          window.luv_settings = ['421F3F46-43B8-4C2D-B32E-B856C325114E', 'ngotkfcdt', 'coetemmeppl', 'ceetkfgh']
        </Script>
        <Script src="https://www.iloveleasing.com/pub/widget/js/luv.js"></Script>
      </>
    )
  if (communityName == "Northbrook Apartments")
    return (
      <>
        <Script>
          window.luv_settings = ['421F3F46-43B8-4C2D-B32E-B856C325114E', 'qongmmdeq', 'bctilhrpfbd', 'rmtuploj']
        </Script>
        <Script src="https://www.iloveleasing.com/pub/widget/js/luv.js"></Script>
      </>
    )
  if (communityName == "Springwood Apartments")
    return (
      <>
        <Script>
          window.luv_settings = ['421F3F46-43B8-4C2D-B32E-B856C325114E', 'qongmmdeq', 'boetemmeppl', 'rmtoebns']
        </Script>
        <Script src="https://www.iloveleasing.com/pub/widget/js/luv.js"></Script>
      </>
    )
  if (communityName == "Birchview Apartments")
    return (
      <>
        <Script>
          window.luv_settings = ['421F3F46-43B8-4C2D-B32E-B856C325114E', 'mgotkfcdt', 'nenfmlijeah', 'hgouplos']
        </Script>
        <Script src="https://www.iloveleasing.com/pub/widget/js/luv.js"></Script>
      </>
    )
  if (communityName == "Heritage Heights Apartments")
    return (
      <>
        <Script>
          window.luv_settings = ['421F3F46-43B8-4C2D-B32E-B856C325114E', 'dcsuplgnu', 'rdopgdnoikf', 'msfgmmcn']
        </Script>
        <Script src="https://www.iloveleasing.com/pub/widget/js/luv.js"></Script>
      </>
    )
  if (communityName == "Legacy Pointe Apartments")
    return (
      <>
        <Script>window.luv_settings = ['263E0B50-D857-4BAA-8610-230749578DF5', 'fnjoebipf', 'lgdtemmeppd', '']</Script>
        <Script src="https://www.iloveleasing.com/pub/widget/js/luv.js"></Script>
      </>
    )
  if (communityName == "Mill Pond Apartments")
    return (
      <>
        <Script>window.luv_settings = ['8517461A-FF7F-4E34-AFAD-D107424A0BDF', 'diftkfcdk', 'fgokclmtfqd', '']</Script>
        <Script src="https://www.iloveleasing.com/pub/widget/js/luv.js"></Script>
      </>
    )
  if (communityName == "Gateway Green Townhomes")
    return (
      <>
        <Script>
          window.luv_settings = ['6494DBE4-DC46-4B33-B186-1F544085937C', 'rcsoebipe', 'lceephgogcd', 'nonegrsf']
        </Script>
        <Script src="https://www.iloveleasing.com/pub/widget/js/luv.js"></Script>
      </>
    )
  if (communityName == "Gateway Green Apartments")
    return (
      <>
        <Script>
          window.luv_settings = ['6494DBE4-DC46-4B33-B186-1F544085937C', 'dnjuplgno', 'rdoephgotbb', 'msdqlcce']
        </Script>
        <Script src="https://www.iloveleasing.com/pub/widget/js/luv.js"></Script>
      </>
    )
  if (communityName == "Encore Apartments")
    return (
      <>
        <Script>window.luv_settings = ['BB813197-633F-432D-B30A-7B3EC1F45C2D', 'didtkfcjt', 'fgpqkrshpah', '']</Script>
        <Script src="https://www.iloveleasing.com/pub/widget/js/luv.js"></Script>
      </>
    )
  if (communityName == "Lexington Lofts")
    return (
      <>
        <Script>window.luv_settings = ['60D04989EF904E75892E59CAA557F776','fnjtkfcjp','lgdfmldnuqq', '']</Script>
        <Script src="https://www.iloveleasing.com/pub/widget/js/luv.js"></Script>
      </>
    )
  if (communityName.includes("Oakdale"))
    return (
      <>
        <Script>window.luv_settings = ['1941A8C8DCF14682B24C038F5DEA5AF5','ldokqmgnf','bdooqbgpkpm', '']</Script>
        <Script src="https://www.iloveleasing.com/pub/widget/js/luv.js"></Script>
      </>
    )
  return null
}
