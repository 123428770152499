import { GraphQLClient } from "graphql-request"
import * as Dom from "graphql-request/dist/types.dom"
import gql from "graphql-tag"
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** The `AWSDate` scalar type provided by AWS AppSync, represents a valid ***extended*** [ISO 8601 Date](https://en.wikipedia.org/wiki/ISO_8601#Calendar_dates) string. In other words, this scalar type accepts date strings of the form `YYYY-MM-DD`.  The scalar can also accept "negative years" of the form `-YYYY` which correspond to years before `0000`. For example, "**-2017-05-01**" and "**-9999-01-01**" are both valid dates.  This scalar type can also accept an optional [time zone offset](https://en.wikipedia.org/wiki/ISO_8601#Time_zone_designators). For example, "**1970-01-01**", "**1970-01-01Z**", "**1970-01-01-07:00**" and "**1970-01-01+05:30**" are all valid dates. The time zone offset must either be `Z` (representing the UTC time zone) or be in the format `±hh:mm:ss`. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSDate: any
  /** The `AWSDateTime` scalar type provided by AWS AppSync, represents a valid ***extended*** [ISO 8601 DateTime](https://en.wikipedia.org/wiki/ISO_8601#Combined_date_and_time_representations) string. In other words, this scalar type accepts datetime strings of the form `YYYY-MM-DDThh:mm:ss.SSSZ`.  The scalar can also accept "negative years" of the form `-YYYY` which correspond to years before `0000`. For example, "**-2017-01-01T00:00Z**" and "**-9999-01-01T00:00Z**" are both valid datetime strings.  The field after the two digit seconds field is a nanoseconds field. It can accept between 1 and 9 digits. So, for example, "**1970-01-01T12:00:00.2Z**", "**1970-01-01T12:00:00.277Z**" and "**1970-01-01T12:00:00.123456789Z**" are all valid datetime strings.  The seconds and nanoseconds fields are optional (the seconds field must be specified if the nanoseconds field is to be used).  The [time zone offset](https://en.wikipedia.org/wiki/ISO_8601#Time_zone_designators) is compulsory for this scalar. The time zone offset must either be `Z` (representing the UTC time zone) or be in the format `±hh:mm:ss`. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSDateTime: any
  /** The `AWSEmail` scalar type provided by AWS AppSync, represents an Email address string that complies with [RFC 822](https://www.ietf.org/rfc/rfc822.txt). For example, "**username@example.com**" is a valid Email address. */
  AWSEmail: any
  /** The `AWSPhone` scalar type provided by AWS AppSync, represents a valid Phone Number. Phone numbers are serialized and deserialized as Strings. Segments of the phone number may be whitespace delimited or hyphenated.  The number can specify a country code at the beginning. However, United States numbers without country codes are still considered to be valid. */
  AWSPhone: any
  /** The `AWSTime` scalar type provided by AWS AppSync, represents a valid ***extended*** [ISO 8601 Time](https://en.wikipedia.org/wiki/ISO_8601#Times) string. In other words, this scalar type accepts time strings of the form `hh:mm:ss.SSS`.  The field after the two digit seconds field is a nanoseconds field. It can accept between 1 and 9 digits. So, for example, "**12:00:00.2**", "**12:00:00.277**" and "**12:00:00.123456789**" are all valid time strings. The seconds and nanoseconds fields are optional (the seconds field must be specified if the nanoseconds field is to be used).  This scalar type can also accept an optional [time zone offset](https://en.wikipedia.org/wiki/ISO_8601#Time_zone_designators). For example, "**12:30**", "**12:30Z**", "**12:30:24-07:00**" and "**12:30:24.500+05:30**" are all valid time strings. The time zone offset must either be `Z` (representing the UTC time zone) or be in the format `±hh:mm:ss`. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSTime: any
}

export type IAmenitiesWhereInput = {
  id?: InputMaybe<Scalars["ID"]>
  name?: InputMaybe<Scalars["String"]>
}

export type IAmenity = IIAmenity & {
  __typename?: "Amenity"
  amenityGroup?: Maybe<IAmenityGroup>
  amenityType?: Maybe<IAmenityType>
  id: Scalars["ID"]
  mitsName?: Maybe<IMitsName>
  name?: Maybe<Scalars["String"]>
}

export type IAmenityFloorPlanInput = {
  floorPlanId: Scalars["ID"]
  id: Scalars["ID"]
}

export enum IAmenityGroup {
  Feature = "FEATURE",
  FitnessAndRecreation = "FITNESS_AND_RECREATION",
  Kitchen = "KITCHEN",
  LivingSpace = "LIVING_SPACE",
  OutdoorSpace = "OUTDOOR_SPACE",
  Senior = "SENIOR",
  Service = "SERVICE",
  UniquePropertyAmenity = "UNIQUE_PROPERTY_AMENITY",
  UniqueUnitAmenity = "UNIQUE_UNIT_AMENITY",
  Unit = "UNIT",
}

export enum IAmenityType {
  FloorPlan = "FLOOR_PLAN",
  Property = "PROPERTY",
  Unit = "UNIT",
}

export type IAmenityUnitInput = {
  id: Scalars["ID"]
  unitId: Scalars["ID"]
}

export enum IAmountCalculationType {
  PayInFull = "PAY_IN_FULL",
  PaySpecifiedAmount = "PAY_SPECIFIED_AMOUNT",
}

export type IApplication = {
  __typename?: "Application"
  applicationDate: Scalars["AWSDateTime"]
  decision?: Maybe<IApplicationDecision>
  id: Scalars["ID"]
  leaseId?: Maybe<Scalars["ID"]>
  leaseSignedDate?: Maybe<Scalars["AWSDateTime"]>
  unitId?: Maybe<Scalars["ID"]>
}

export enum IApplicationDecision {
  Accept = "ACCEPT",
  Deny = "DENY",
}

export type IApplicationEmail = {
  __typename?: "ApplicationEmail"
  emailAddress?: Maybe<Scalars["String"]>
  leaseId: Scalars["ID"]
}

export type IApplicationLead = {
  __typename?: "ApplicationLead"
  contactType: IContactType
  dateReceived: Scalars["AWSDateTime"]
  emailAddress?: Maybe<Scalars["String"]>
  phoneNumber?: Maybe<Scalars["String"]>
}

export type IApplicationLeadsWhereInput = {
  maxDateReceived: Scalars["AWSDateTime"]
  minDateReceived: Scalars["AWSDateTime"]
}

export type IApplicationLease = {
  __typename?: "ApplicationLease"
  leaseId: Scalars["ID"]
  moveInDate?: Maybe<Scalars["AWSDateTime"]>
}

export type IApplicationPhoneNumber = {
  __typename?: "ApplicationPhoneNumber"
  leaseId: Scalars["ID"]
  phoneNumber?: Maybe<Scalars["String"]>
}

export type IApplicationUnit = {
  __typename?: "ApplicationUnit"
  bathrooms?: Maybe<Scalars["Float"]>
  bedrooms?: Maybe<Scalars["Float"]>
  community?: Maybe<Scalars["String"]>
  unitId: Scalars["ID"]
}

export type IApplicationsAndLeasesOutput = {
  __typename?: "ApplicationsAndLeasesOutput"
  applications: Array<IDatedType>
  leases: Array<IDatedType>
}

export type IAttachment = {
  __typename?: "Attachment"
  id: Scalars["ID"]
  name: Scalars["String"]
  serviceRequestId: Scalars["ID"]
  size: Scalars["Float"]
  type: Scalars["String"]
  url: Scalars["String"]
}

export type IAttachmentsWhereInput = {
  id?: InputMaybe<Scalars["ID"]>
  serviceRequestId?: InputMaybe<Scalars["ID"]>
}

export type IAuthenticationToken = {
  __typename?: "AuthenticationToken"
  cognitoUser: IVouchCognitoUser
  heroToken: Scalars["String"]
  heroTokenExpirationDate: Scalars["AWSDateTime"]
  userId: Scalars["ID"]
  verifiesEmail: Scalars["Boolean"]
}

export enum IAvailableUnits {
  AvailableUnits = "AVAILABLE_UNITS",
  AvailableUnitsAndNewConstruction = "AVAILABLE_UNITS_AND_NEW_CONSTRUCTION",
  MovingIn = "MOVING_IN",
  MovingOut = "MOVING_OUT",
  MovingOutNoMoveIn = "MOVING_OUT_NO_MOVE_IN",
  NewConstruction = "NEW_CONSTRUCTION",
  TurnOver = "TURN_OVER",
  UnavailableUnits = "UNAVAILABLE_UNITS",
  VacantUnits = "VACANT_UNITS",
}

export type IAverageTurnoverCost = {
  __typename?: "AverageTurnoverCost"
  bathrooms?: Maybe<Scalars["Float"]>
  bedrooms?: Maybe<Scalars["Float"]>
  cleaningFee?: Maybe<Scalars["Float"]>
  community?: Maybe<Scalars["String"]>
  moveOutDate?: Maybe<Scalars["AWSDateTime"]>
}

export type ICalculateFullRecurringPaymentsInput = {
  input: Scalars["Boolean"]
}

export type ICalculateFullRecurringPaymentsOutput = {
  __typename?: "CalculateFullRecurringPaymentsOutput"
  output?: Maybe<Scalars["Boolean"]>
}

export type ICallAnswerPercentageOutput = {
  __typename?: "CallAnswerPercentageOutput"
  answered: Scalars["Int"]
  percent?: Maybe<Scalars["Float"]>
  total: Scalars["Int"]
}

export enum ICampaignType {
  PlainText = "plainText",
  Regular = "regular",
}

export type ICategoriesWhereInput = {
  active?: InputMaybe<Scalars["Boolean"]>
  common?: InputMaybe<Scalars["Boolean"]>
  id?: InputMaybe<Scalars["ID"]>
  name?: InputMaybe<Scalars["String"]>
  unit?: InputMaybe<Scalars["Boolean"]>
}

export type ICategory = {
  __typename?: "Category"
  active: Scalars["Boolean"]
  common: Scalars["Boolean"]
  id: Scalars["ID"]
  name: Scalars["String"]
  unit: Scalars["Boolean"]
}

export type ICellarLease = {
  __typename?: "CellarLease"
  id: Scalars["ID"]
  name: Scalars["String"]
}

export type ICellarUnit = {
  __typename?: "CellarUnit"
  id: Scalars["ID"]
  number: Scalars["String"]
}

export type ICellarUnitInput = {
  id: Scalars["ID"]
  number: Scalars["String"]
}

export type ICommunitiesWhereInput = {
  allowOnlineApplications?: InputMaybe<Scalars["Boolean"]>
  id?: InputMaybe<Scalars["ID"]>
  name?: InputMaybe<Scalars["String"]>
  regionId?: InputMaybe<Scalars["ID"]>
}

export type ICommunity = {
  __typename?: "Community"
  additionalDescription?: Maybe<Scalars["String"]>
  adminFee?: Maybe<Scalars["Float"]>
  allowOnlineApplications: Scalars["Boolean"]
  amenities?: Maybe<Array<Maybe<IAmenity>>>
  applicationFee?: Maybe<Scalars["Float"]>
  communityVideoUrl?: Maybe<Scalars["String"]>
  district?: Maybe<IDistrict>
  drivingDirections?: Maybe<Scalars["String"]>
  emailFragment?: Maybe<Scalars["String"]>
  id: Scalars["ID"]
  images?: Maybe<Array<Maybe<IImage>>>
  internetPlans?: Maybe<Array<Maybe<IInternetPlan>>>
  longDescription?: Maybe<Scalars["String"]>
  name: Scalars["String"]
  officeHours?: Maybe<Array<Maybe<IOfficeHour>>>
  owned: Scalars["Boolean"]
  parkingSpaces?: Maybe<Array<Maybe<IParkingSpace>>>
  petPolicies?: Maybe<Array<Maybe<IPetPolicy>>>
  primaryProperty?: Maybe<IProperty>
  promotionalMessage?: Maybe<Scalars["String"]>
  promotions?: Maybe<Array<Maybe<IPromotion>>>
  region?: Maybe<IRegion>
  routeToEmailAddress?: Maybe<Scalars["String"]>
  shortDescription?: Maybe<Scalars["String"]>
  showOnMarketingSites: Scalars["Boolean"]
  showOnWebsite: Scalars["Boolean"]
  socialMedia?: Maybe<Array<Maybe<ISocialMedia>>>
  squareFeet?: Maybe<Scalars["Float"]>
  tourAccessType?: Maybe<ITourAccessType>
  unfinishedSquareFeet?: Maybe<Scalars["Float"]>
  uriSlug?: Maybe<Scalars["String"]>
  utilities?: Maybe<Array<Maybe<IUtility>>>
  websiteDescription?: Maybe<Scalars["String"]>
  websitePromotionalMessage?: Maybe<Scalars["String"]>
}

export type ICommunityAmenity = IIAmenity & {
  __typename?: "CommunityAmenity"
  amenityGroup?: Maybe<IAmenityGroup>
  amenityType?: Maybe<IAmenityType>
  communityId: Scalars["ID"]
  id: Scalars["ID"]
  mitsName?: Maybe<IMitsName>
  name?: Maybe<Scalars["String"]>
}

export type ICommunityAmenityInput = {
  communityId: Scalars["ID"]
  id: Scalars["ID"]
}

export type ICommunityAndTimeWhereInput = {
  communityId: Scalars["ID"]
  dateMax: Scalars["AWSDateTime"]
  dateMin: Scalars["AWSDateTime"]
}

export type ICommunityDetail = {
  __typename?: "CommunityDetail"
  communityName?: Maybe<Scalars["String"]>
  elevator?: Maybe<Scalars["Boolean"]>
  floors?: Maybe<Scalars["Int"]>
}

export type ICommunityWhereInput = {
  communityId: Scalars["ID"]
}

export type IContact = {
  __typename?: "Contact"
  date: Scalars["AWSDateTime"]
  email?: Maybe<Scalars["AWSEmail"]>
  id: Scalars["ID"]
  message: Scalars["String"]
  name: Scalars["String"]
  phoneNumber?: Maybe<Scalars["AWSPhone"]>
}

export enum IContactType {
  Call = "CALL",
  Email = "EMAIL",
  Website = "WEBSITE",
}

export type IContactsWhereInput = {
  email?: InputMaybe<Scalars["AWSEmail"]>
  id?: InputMaybe<Scalars["ID"]>
  name?: InputMaybe<Scalars["String"]>
  phoneNumber?: InputMaybe<Scalars["AWSPhone"]>
}

export enum ICountry {
  Afghanistan = "Afghanistan",
  AlandIslands = "AlandIslands",
  Albania = "Albania",
  Algeria = "Algeria",
  AmericanSamoa = "AmericanSamoa",
  Andorra = "Andorra",
  Angola = "Angola",
  Anguilla = "Anguilla",
  Antarctica = "Antarctica",
  AntiguaAndBarbuda = "AntiguaAndBarbuda",
  Argentina = "Argentina",
  Armenia = "Armenia",
  Aruba = "Aruba",
  Australia = "Australia",
  Austria = "Austria",
  Azerbaijan = "Azerbaijan",
  Bahamas = "Bahamas",
  Bahrain = "Bahrain",
  Bangladesh = "Bangladesh",
  Barbados = "Barbados",
  Belarus = "Belarus",
  Belgium = "Belgium",
  Belize = "Belize",
  Benin = "Benin",
  Bermuda = "Bermuda",
  Bhutan = "Bhutan",
  Bolivia = "Bolivia",
  BosniaAndHerzegovina = "BosniaAndHerzegovina",
  Botswana = "Botswana",
  BouvetIsland = "BouvetIsland",
  Brazil = "Brazil",
  BritishIndianOceanTerritory = "BritishIndianOceanTerritory",
  BruneiDarussalam = "BruneiDarussalam",
  Bulgaria = "Bulgaria",
  BurkinaFaso = "BurkinaFaso",
  Burundi = "Burundi",
  Cambodia = "Cambodia",
  Cameroon = "Cameroon",
  Canada = "Canada",
  CapeVerde = "CapeVerde",
  CaymanIslands = "CaymanIslands",
  CentralAfricanRepublic = "CentralAfricanRepublic",
  Chad = "Chad",
  Chile = "Chile",
  China = "China",
  ChristmasIsland = "ChristmasIsland",
  CocosKeelingIslands = "CocosKeelingIslands",
  Colombia = "Colombia",
  Comoros = "Comoros",
  Congo = "Congo",
  CongoDemocraticRepublic = "CongoDemocraticRepublic",
  CookIslands = "CookIslands",
  CostaRica = "CostaRica",
  CoteDIvoire = "CoteDIvoire",
  Croatia = "Croatia",
  Cuba = "Cuba",
  Cyprus = "Cyprus",
  CzechRepublic = "CzechRepublic",
  Denmark = "Denmark",
  Djibouti = "Djibouti",
  Dominica = "Dominica",
  DominicanRepublic = "DominicanRepublic",
  Ecuador = "Ecuador",
  Egypt = "Egypt",
  ElSalvador = "ElSalvador",
  EquatorialGuinea = "EquatorialGuinea",
  Eritrea = "Eritrea",
  Estonia = "Estonia",
  Ethiopia = "Ethiopia",
  FalklandIslands = "FalklandIslands",
  FaroeIslands = "FaroeIslands",
  Fiji = "Fiji",
  Finland = "Finland",
  France = "France",
  FrenchGuiana = "FrenchGuiana",
  FrenchPolynesia = "FrenchPolynesia",
  FrenchSouthernTerritories = "FrenchSouthernTerritories",
  Gabon = "Gabon",
  Gambia = "Gambia",
  Georgia = "Georgia",
  Germany = "Germany",
  Ghana = "Ghana",
  Gibraltar = "Gibraltar",
  Greece = "Greece",
  Greenland = "Greenland",
  Grenada = "Grenada",
  Guadeloupe = "Guadeloupe",
  Guam = "Guam",
  Guatemala = "Guatemala",
  Guernsey = "Guernsey",
  Guinea = "Guinea",
  GuineaBissau = "GuineaBissau",
  Guyana = "Guyana",
  Haiti = "Haiti",
  HeardIslandMcdonaldIslands = "HeardIslandMcdonaldIslands",
  HolySeeVaticanCityState = "HolySeeVaticanCityState",
  Honduras = "Honduras",
  HongKong = "HongKong",
  Hungary = "Hungary",
  Iceland = "Iceland",
  India = "India",
  Indonesia = "Indonesia",
  Iran = "Iran",
  Iraq = "Iraq",
  Ireland = "Ireland",
  IsleOfMan = "IsleOfMan",
  Israel = "Israel",
  Italy = "Italy",
  Jamaica = "Jamaica",
  Japan = "Japan",
  Jersey = "Jersey",
  Jordan = "Jordan",
  Kazakhstan = "Kazakhstan",
  Kenya = "Kenya",
  Kiribati = "Kiribati",
  Korea = "Korea",
  Kuwait = "Kuwait",
  Kyrgyzstan = "Kyrgyzstan",
  LaoPeoplesDemocraticRepublic = "LaoPeoplesDemocraticRepublic",
  Latvia = "Latvia",
  Lebanon = "Lebanon",
  Lesotho = "Lesotho",
  Liberia = "Liberia",
  LibyanArabJamahiriya = "LibyanArabJamahiriya",
  Liechtenstein = "Liechtenstein",
  Lithuania = "Lithuania",
  Luxembourg = "Luxembourg",
  Macao = "Macao",
  Macedonia = "Macedonia",
  Madagascar = "Madagascar",
  Malawi = "Malawi",
  Malaysia = "Malaysia",
  Maldives = "Maldives",
  Mali = "Mali",
  Malta = "Malta",
  MarshallIslands = "MarshallIslands",
  Martinique = "Martinique",
  Mauritania = "Mauritania",
  Mauritius = "Mauritius",
  Mayotte = "Mayotte",
  Mexico = "Mexico",
  Micronesia = "Micronesia",
  Moldova = "Moldova",
  Monaco = "Monaco",
  Mongolia = "Mongolia",
  Montenegro = "Montenegro",
  Montserrat = "Montserrat",
  Morocco = "Morocco",
  Mozambique = "Mozambique",
  Myanmar = "Myanmar",
  Namibia = "Namibia",
  Nauru = "Nauru",
  Nepal = "Nepal",
  Netherlands = "Netherlands",
  NetherlandsAntilles = "NetherlandsAntilles",
  NewCaledonia = "NewCaledonia",
  NewZealand = "NewZealand",
  Nicaragua = "Nicaragua",
  Niger = "Niger",
  Nigeria = "Nigeria",
  Niue = "Niue",
  NorfolkIsland = "NorfolkIsland",
  NorthernMarianaIslands = "NorthernMarianaIslands",
  Norway = "Norway",
  Oman = "Oman",
  Pakistan = "Pakistan",
  Palau = "Palau",
  PalestinianTerritory = "PalestinianTerritory",
  Panama = "Panama",
  PapuaNewGuinea = "PapuaNewGuinea",
  Paraguay = "Paraguay",
  Peru = "Peru",
  Philippines = "Philippines",
  Pitcairn = "Pitcairn",
  Poland = "Poland",
  Portugal = "Portugal",
  PuertoRico = "PuertoRico",
  Qatar = "Qatar",
  Reunion = "Reunion",
  Romania = "Romania",
  RussianFederation = "RussianFederation",
  Rwanda = "Rwanda",
  SaintBarthelemy = "SaintBarthelemy",
  SaintHelena = "SaintHelena",
  SaintKittsAndNevis = "SaintKittsAndNevis",
  SaintLucia = "SaintLucia",
  SaintMartin = "SaintMartin",
  SaintPierreAndMiquelon = "SaintPierreAndMiquelon",
  SaintVincentAndGrenadines = "SaintVincentAndGrenadines",
  Samoa = "Samoa",
  SanMarino = "SanMarino",
  SaoTomeAndPrincipe = "SaoTomeAndPrincipe",
  SaudiArabia = "SaudiArabia",
  Senegal = "Senegal",
  Serbia = "Serbia",
  Seychelles = "Seychelles",
  SierraLeone = "SierraLeone",
  Singapore = "Singapore",
  Slovakia = "Slovakia",
  Slovenia = "Slovenia",
  SolomonIslands = "SolomonIslands",
  Somalia = "Somalia",
  SouthAfrica = "SouthAfrica",
  SouthGeorgiaAndSandwichIsl = "SouthGeorgiaAndSandwichIsl",
  Spain = "Spain",
  SriLanka = "SriLanka",
  Sudan = "Sudan",
  Suriname = "Suriname",
  SvalbardAndJanMayen = "SvalbardAndJanMayen",
  Swaziland = "Swaziland",
  Sweden = "Sweden",
  Switzerland = "Switzerland",
  SyrianArabRepublic = "SyrianArabRepublic",
  Taiwan = "Taiwan",
  Tajikistan = "Tajikistan",
  Tanzania = "Tanzania",
  Thailand = "Thailand",
  TimorLeste = "TimorLeste",
  Togo = "Togo",
  Tokelau = "Tokelau",
  Tonga = "Tonga",
  TrinidadAndTobago = "TrinidadAndTobago",
  Tunisia = "Tunisia",
  Turkey = "Turkey",
  Turkmenistan = "Turkmenistan",
  TurksAndCaicosIslands = "TurksAndCaicosIslands",
  Tuvalu = "Tuvalu",
  Uganda = "Uganda",
  Ukraine = "Ukraine",
  UnitedArabEmirates = "UnitedArabEmirates",
  UnitedKingdom = "UnitedKingdom",
  UnitedStates = "UnitedStates",
  UnitedStatesOutlyingIslands = "UnitedStatesOutlyingIslands",
  Uruguay = "Uruguay",
  Uzbekistan = "Uzbekistan",
  Vanuatu = "Vanuatu",
  Venezuela = "Venezuela",
  VietNam = "VietNam",
  VirginIslandsBritish = "VirginIslandsBritish",
  VirginIslandsUs = "VirginIslandsUS",
  WallisAndFutuna = "WallisAndFutuna",
  WesternSahara = "WesternSahara",
  Yemen = "Yemen",
  Zambia = "Zambia",
  Zimbabw = "Zimbabw",
}

export type ICreateAmenityInput = {
  amenityGroup: Scalars["String"]
  amenityType: Scalars["String"]
  mitsName: Scalars["String"]
  name: Scalars["String"]
}

export type ICreateAttachmentInput = {
  name: Scalars["String"]
  serviceRequestId: Scalars["ID"]
  size: Scalars["Float"]
  type: Scalars["String"]
  url: Scalars["String"]
}

export type ICreateCategoryInput = {
  active: Scalars["Boolean"]
  common: Scalars["Boolean"]
  name: Scalars["String"]
  unit: Scalars["Boolean"]
}

export type ICreateCommunityInput = {
  additionalDescription?: InputMaybe<Scalars["String"]>
  adminFee?: InputMaybe<Scalars["Float"]>
  allowOnlineApplications: Scalars["Boolean"]
  amenities?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>
  applicationFee?: InputMaybe<Scalars["Float"]>
  communityVideoUrl?: InputMaybe<Scalars["String"]>
  districtId?: InputMaybe<Scalars["ID"]>
  drivingDirections?: InputMaybe<Scalars["String"]>
  emailFragment?: InputMaybe<Scalars["String"]>
  images?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>
  longDescription?: InputMaybe<Scalars["String"]>
  name: Scalars["String"]
  officeHours?: InputMaybe<Array<InputMaybe<IOfficeHourCommunityInput>>>
  owned: Scalars["Boolean"]
  parkingSpaces?: InputMaybe<Array<InputMaybe<IParkingSpaceCommunityInput>>>
  petPolicies?: InputMaybe<Array<InputMaybe<IPetPolicyCommunityInput>>>
  primaryPropertyId?: InputMaybe<Scalars["ID"]>
  promotionalMessage?: InputMaybe<Scalars["String"]>
  promotions?: InputMaybe<Array<InputMaybe<IPromotionCommunityInput>>>
  routeToEmailAddress?: InputMaybe<Scalars["String"]>
  shortDescription?: InputMaybe<Scalars["String"]>
  showOnMarketingSites: Scalars["Boolean"]
  showOnWebsite: Scalars["Boolean"]
  socialMedia?: InputMaybe<Array<InputMaybe<ISocialMediaCommunityInput>>>
  squareFeet?: InputMaybe<Scalars["Float"]>
  tourAccessType?: InputMaybe<ITourAccessType>
  unfinishedSquareFeet?: InputMaybe<Scalars["Float"]>
  uriSlug?: InputMaybe<Scalars["String"]>
  utilities?: InputMaybe<Array<InputMaybe<IUtilityCommunityInput>>>
  websiteDescription?: InputMaybe<Scalars["String"]>
  websitePromotionalMessage?: InputMaybe<Scalars["String"]>
}

export type ICreateContactInput = {
  email?: InputMaybe<Scalars["AWSEmail"]>
  message: Scalars["String"]
  name: Scalars["String"]
  phoneNumber?: InputMaybe<Scalars["AWSPhone"]>
}

export type ICreateDistrictInput = {
  displayEmailAddress: Scalars["AWSEmail"]
  name: Scalars["String"]
  regionId: Scalars["ID"]
}

export type ICreateDistrictLeadSourceFeeInput = {
  endDate?: InputMaybe<Scalars["String"]>
  monthlyFee?: InputMaybe<Scalars["Float"]>
  name?: InputMaybe<Scalars["String"]>
  startDate?: InputMaybe<Scalars["String"]>
}

export type ICreateDistrictLeadSourceInput = {
  district: IUsherDistrictDistrictLeadSourceInput
  fees?: InputMaybe<Array<InputMaybe<ICreateDistrictLeadSourceFeeInput>>>
  phoneNumber?: InputMaybe<Scalars["String"]>
}

export type ICreateEmployeeInput = {
  isIT?: InputMaybe<Scalars["Boolean"]>
  isMaintenance?: InputMaybe<Scalars["Boolean"]>
  name: Scalars["String"]
  userId: Scalars["ID"]
}

export type ICreateFloorPlanInput = {
  amenities?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>
  bathrooms?: InputMaybe<Scalars["Float"]>
  bedrooms?: InputMaybe<Scalars["Float"]>
  community?: InputMaybe<Scalars["ID"]>
  dens?: InputMaybe<Scalars["Float"]>
  description?: InputMaybe<Scalars["String"]>
  matterportUrl?: InputMaybe<Scalars["String"]>
  minimumDeposit?: InputMaybe<Scalars["Float"]>
  name?: InputMaybe<Scalars["String"]>
  promotionalEndDate?: InputMaybe<Scalars["AWSDateTime"]>
  promotionalMaxLeaseTerm?: InputMaybe<Scalars["Float"]>
  promotionalMessage?: InputMaybe<Scalars["String"]>
  promotionalMinLeaseTerm?: InputMaybe<Scalars["Float"]>
  promotionalMonthsDiscount?: InputMaybe<Scalars["Float"]>
  promotionalPriceDiscount?: InputMaybe<Scalars["Float"]>
  promotionalStartDate?: InputMaybe<Scalars["AWSDateTime"]>
  residentScreeningRequired?: InputMaybe<Scalars["Boolean"]>
  terms?: InputMaybe<Array<InputMaybe<ITermFeeInput>>>
  urlSlug?: InputMaybe<Scalars["String"]>
  youtubeUrl?: InputMaybe<Scalars["String"]>
}

export type ICreateFloorPlanLeaseTermInput = {
  additionalRent: Scalars["Float"]
  floorPlanId: Scalars["ID"]
  minimumMonths: Scalars["Int"]
}

export type ICreateForgottenPasswordInquiryInput = {
  email: Scalars["AWSEmail"]
  url?: InputMaybe<Scalars["String"]>
}

export type ICreateItAttachmentInput = {
  name: Scalars["String"]
  serviceRequestId: Scalars["ID"]
  size: Scalars["Float"]
  type: Scalars["String"]
  url: Scalars["String"]
}

export type ICreateItCategoryInput = {
  active: Scalars["Boolean"]
  name: Scalars["String"]
}

export type ICreateItServiceRequestInput = {
  callbackPhoneNumber?: InputMaybe<Scalars["AWSPhone"]>
  categoryId?: InputMaybe<Scalars["ID"]>
  description: Scalars["String"]
  employeeId?: InputMaybe<Scalars["ID"]>
  notes?: InputMaybe<Scalars["String"]>
  status?: InputMaybe<IServiceRequestStatus>
  submitterName?: InputMaybe<Scalars["String"]>
  submitterUserId: Scalars["ID"]
}

export type ICreateLeadSourceInput = {
  comment?: InputMaybe<Scalars["String"]>
  directory?: InputMaybe<Scalars["String"]>
  districtLeadSources?: InputMaybe<Array<InputMaybe<ICreateDistrictLeadSourceInput>>>
  fileProtocol?: InputMaybe<Scalars["String"]>
  host?: InputMaybe<Scalars["String"]>
  leadSourceType?: InputMaybe<ILeadSourceType>
  name: Scalars["String"]
  password?: InputMaybe<Scalars["String"]>
  port?: InputMaybe<Scalars["Int"]>
  publicId?: InputMaybe<Scalars["String"]>
  username?: InputMaybe<Scalars["String"]>
}

export type ICreateLeaseNoteInput = {
  id: Scalars["ID"]
  note: Scalars["String"]
}

export type ICreateMoveOutChecklistInput = {
  cleanCarpetCompleted: Scalars["Boolean"]
  cleanCarpetCompletedDate?: InputMaybe<Scalars["AWSDateTime"]>
  cleanCarpetScheduledCompleted: Scalars["Boolean"]
  cleanCarpetScheduledDate?: InputMaybe<Scalars["AWSDateTime"]>
  cleanCompleted: Scalars["Boolean"]
  cleanCompletedDate?: InputMaybe<Scalars["AWSDateTime"]>
  cleanScheduledCompleted: Scalars["Boolean"]
  cleanScheduledDate?: InputMaybe<Scalars["AWSDateTime"]>
  floorReplacement?: InputMaybe<Scalars["String"]>
  garageInspected: Scalars["Boolean"]
  garageInspectedDate?: InputMaybe<Scalars["AWSDateTime"]>
  keysTurnedInCompleted: Scalars["Boolean"]
  keysTurnedInDate?: InputMaybe<Scalars["AWSDateTime"]>
  leasingId: Scalars["ID"]
  maintenanceDate?: InputMaybe<Scalars["AWSDateTime"]>
  maintenanceDoneCompleted: Scalars["Boolean"]
  maintenanceDoneDate?: InputMaybe<Scalars["AWSDateTime"]>
  maintenanceEmployeeId?: InputMaybe<Scalars["ID"]>
  maintenanceType?: InputMaybe<Scalars["String"]>
  moveInPicturesTakenCompleted: Scalars["Boolean"]
  moveInPicturesTakenDate?: InputMaybe<Scalars["AWSDateTime"]>
  notes?: InputMaybe<Scalars["String"]>
  paint?: InputMaybe<Scalars["String"]>
  paperworkSubmitted: Scalars["Boolean"]
  picturesTakenCompleted: Scalars["Boolean"]
  picturesTakenDate?: InputMaybe<Scalars["AWSDateTime"]>
  schedulerMoveOut?: InputMaybe<Scalars["AWSDateTime"]>
  schedulerNextMoveIn?: InputMaybe<Scalars["AWSDateTime"]>
  unitId: Scalars["ID"]
  unitName: Scalars["String"]
  walkThroughtCompleted: Scalars["Boolean"]
  walkThroughtDate?: InputMaybe<Scalars["AWSDateTime"]>
}

export type ICreatePaymentInput = {
  paymentInfo?: InputMaybe<IPaymentInfoInput>
}

export type ICreatePaymentMethodInput = {
  accountHolderName: Scalars["String"]
  address: Scalars["String"]
  city: Scalars["String"]
  companyName?: InputMaybe<Scalars["String"]>
  country?: InputMaybe<ICountry>
  creditCard?: InputMaybe<ICreditCardInput>
  eCheck?: InputMaybe<IECheckInput>
  name?: InputMaybe<Scalars["String"]>
  phone?: InputMaybe<Scalars["String"]>
  postalCode: Scalars["String"]
  state: Scalars["String"]
  userId?: InputMaybe<Scalars["ID"]>
}

export type ICreatePaymentMethodOutput = {
  __typename?: "CreatePaymentMethodOutput"
  paymentMethod: IPaymentMethod
}

export type ICreatePaymentOutput = {
  __typename?: "CreatePaymentOutput"
  payment: IPayment
}

export type ICreatePropertyInput = {
  active: Scalars["Boolean"]
  addressLine1?: InputMaybe<Scalars["String"]>
  addressLine2?: InputMaybe<Scalars["String"]>
  assessorsParcelNumber?: InputMaybe<Scalars["String"]>
  city?: InputMaybe<Scalars["String"]>
  communityID?: InputMaybe<Scalars["ID"]>
  country?: InputMaybe<Scalars["String"]>
  dateAcquired?: InputMaybe<Scalars["AWSDateTime"]>
  dateBuilt?: InputMaybe<Scalars["AWSDateTime"]>
  dateRemodeled?: InputMaybe<Scalars["AWSDateTime"]>
  dateRoofReplaced?: InputMaybe<Scalars["AWSDateTime"]>
  fireSprinklered?: InputMaybe<Scalars["Boolean"]>
  identificationCode?: InputMaybe<Scalars["String"]>
  landAcres?: InputMaybe<Scalars["Float"]>
  latitude?: InputMaybe<Scalars["Float"]>
  longitude?: InputMaybe<Scalars["Float"]>
  marketValue?: InputMaybe<Scalars["Float"]>
  name: Scalars["String"]
  originalCost?: InputMaybe<Scalars["Float"]>
  ownerID?: InputMaybe<Scalars["ID"]>
  postalCode?: InputMaybe<Scalars["String"]>
  primaryUnitType?: InputMaybe<IUnitType>
  squareFeet?: InputMaybe<Scalars["Int"]>
  state?: InputMaybe<Scalars["String"]>
  unitCount?: InputMaybe<Scalars["Int"]>
}

export type ICreateRecurringPaymentInput = {
  amountCalculationType?: InputMaybe<IAmountCalculationType>
  paymentInfo?: InputMaybe<IPaymentInfoInput>
}

export type ICreateRecurringPaymentOutput = {
  __typename?: "CreateRecurringPaymentOutput"
  recurringPayment: IRecurringPayment
}

export type ICreateRegionInput = {
  name: Scalars["String"]
  urlSlug?: InputMaybe<Scalars["String"]>
}

export type ICreateServiceRequestInput = {
  callbackPhoneNumber?: InputMaybe<Scalars["AWSPhone"]>
  categoryId?: InputMaybe<Scalars["ID"]>
  commonArea: Scalars["Boolean"]
  description: Scalars["String"]
  employeeId?: InputMaybe<Scalars["ID"]>
  notes?: InputMaybe<Scalars["String"]>
  onCall?: InputMaybe<Scalars["Boolean"]>
  permissionEndDate?: InputMaybe<Scalars["AWSDateTime"]>
  permissionObtained?: InputMaybe<Scalars["Boolean"]>
  permissionStartDate?: InputMaybe<Scalars["AWSDateTime"]>
  propertyId: Scalars["ID"]
  status?: InputMaybe<IServiceRequestStatus>
  submitterName?: InputMaybe<Scalars["String"]>
  submitterUserId: Scalars["ID"]
  unitId?: InputMaybe<Scalars["ID"]>
}

export type ICreateUnitInput = {
  addressLine1?: InputMaybe<Scalars["String"]>
  addressLine2?: InputMaybe<Scalars["String"]>
  amenities?: InputMaybe<Array<Scalars["ID"]>>
  assessorsParcelNumber?: InputMaybe<Scalars["String"]>
  availabilityNotes?: InputMaybe<Scalars["String"]>
  description?: InputMaybe<Scalars["String"]>
  floor?: InputMaybe<Scalars["Int"]>
  floorPlanId: Scalars["String"]
  name: Scalars["String"]
  notes?: InputMaybe<Scalars["String"]>
  propertyId: Scalars["String"]
  rentPrice?: InputMaybe<Scalars["Float"]>
  rentStartDate?: InputMaybe<Scalars["AWSDateTime"]>
  squareFeet?: InputMaybe<Scalars["Float"]>
  stairwell?: InputMaybe<Scalars["Int"]>
  status?: InputMaybe<IUnitStatus>
  statusNotes?: InputMaybe<Scalars["String"]>
  statusStartDate?: InputMaybe<Scalars["AWSDateTime"]>
  unitNumber?: InputMaybe<Scalars["String"]>
  unitType?: InputMaybe<IUnitType>
}

export type ICreateUserInput = {
  email: Scalars["String"]
  emailOptIn: Scalars["Boolean"]
  password: Scalars["String"]
}

export type ICreateUserOutput = {
  __typename?: "CreateUserOutput"
  authenticationToken: IAuthenticationToken
}

export type ICreditCard = {
  __typename?: "CreditCard"
  cardVerificationValue?: Maybe<Scalars["String"]>
  creditCardNumber?: Maybe<Scalars["String"]>
  expireMonth?: Maybe<Scalars["String"]>
  expireYear?: Maybe<Scalars["String"]>
}

export type ICreditCardInput = {
  cardVerificationValue?: InputMaybe<Scalars["String"]>
  creditCardNumber?: InputMaybe<Scalars["String"]>
  expireMonth?: InputMaybe<Scalars["String"]>
  expireYear?: InputMaybe<Scalars["String"]>
}

export type IDatedType = {
  __typename?: "DatedType"
  count: Scalars["Int"]
  date: Scalars["AWSDateTime"]
}

export enum IDayOfWeek {
  Friday = "FRIDAY",
  Monday = "MONDAY",
  Saturday = "SATURDAY",
  Sunday = "SUNDAY",
  Thursday = "THURSDAY",
  Tuesday = "TUESDAY",
  Wednesday = "WEDNESDAY",
}

export type IDeleteAmenityOutput = {
  __typename?: "DeleteAmenityOutput"
  id: Scalars["ID"]
}

export type IDeleteAttachmentInput = {
  id: Scalars["ID"]
}

export type IDeleteAttachmentOutput = {
  __typename?: "DeleteAttachmentOutput"
  id: Scalars["ID"]
}

export type IDeleteCommunityAmenityInput = {
  communityId: Scalars["ID"]
  id: Scalars["ID"]
}

export type IDeleteCommunityInput = {
  id: Scalars["ID"]
}

export type IDeleteCommunityOutput = {
  __typename?: "DeleteCommunityOutput"
  id: Scalars["ID"]
}

export type IDeleteDistrictInput = {
  id: Scalars["ID"]
}

export type IDeleteDistrictOutput = {
  __typename?: "DeleteDistrictOutput"
  id: Scalars["ID"]
}

export type IDeleteEmployeeInput = {
  id: Scalars["ID"]
}

export type IDeleteFloorPlanAmenityInput = {
  floorPlanId: Scalars["ID"]
  id: Scalars["ID"]
}

export type IDeleteFloorPlanInput = {
  id: Scalars["ID"]
}

export type IDeleteFloorPlanLeaseTermInput = {
  id: Scalars["ID"]
}

export type IDeleteFloorPlanLeaseTermOutput = {
  __typename?: "DeleteFloorPlanLeaseTermOutput"
  id: Scalars["ID"]
}

export type IDeleteFloorPlanOutput = {
  __typename?: "DeleteFloorPlanOutput"
  id?: Maybe<Scalars["ID"]>
}

export type IDeleteItAttachmentInput = {
  id: Scalars["ID"]
}

export type IDeleteItAttachmentOutput = {
  __typename?: "DeleteITAttachmentOutput"
  id: Scalars["ID"]
}

export type IDeleteItServiceRequestInput = {
  id: Scalars["ID"]
}

export type IDeleteItServiceRequestOutput = {
  __typename?: "DeleteITServiceRequestOutput"
  id: Scalars["ID"]
}

export type IDeleteImageInput = {
  id: Scalars["ID"]
}

export type IDeleteImageOutput = {
  __typename?: "DeleteImageOutput"
  id: Scalars["ID"]
}

export type IDeleteInput = {
  id: Scalars["ID"]
}

export type IDeleteOfficeHourInput = {
  id: Scalars["ID"]
}

export type IDeleteOfficeHourOutput = {
  __typename?: "DeleteOfficeHourOutput"
  id: Scalars["ID"]
}

export type IDeleteOutput = {
  __typename?: "DeleteOutput"
  id: Scalars["ID"]
}

export type IDeleteParkingInput = {
  id: Scalars["ID"]
}

export type IDeleteParkingOutput = {
  __typename?: "DeleteParkingOutput"
  id: Scalars["ID"]
}

export type IDeletePaymentMethodInput = {
  id: Scalars["ID"]
}

export type IDeletePaymentMethodOutput = {
  __typename?: "DeletePaymentMethodOutput"
  id: Scalars["ID"]
}

export type IDeletePetPolicyInput = {
  id: Scalars["ID"]
}

export type IDeletePetPolicyOutput = {
  __typename?: "DeletePetPolicyOutput"
  id: Scalars["ID"]
}

export type IDeletePromotionInput = {
  id: Scalars["ID"]
}

export type IDeletePromotionOutput = {
  __typename?: "DeletePromotionOutput"
  id: Scalars["ID"]
}

export type IDeletePropertyInput = {
  id: Scalars["ID"]
}

export type IDeletePropertyOutput = {
  __typename?: "DeletePropertyOutput"
  id: Scalars["ID"]
}

export type IDeleteRecurringPaymentInput = {
  id: Scalars["ID"]
}

export type IDeleteRecurringPaymentOutput = {
  __typename?: "DeleteRecurringPaymentOutput"
  id: Scalars["ID"]
}

export type IDeleteRegionInput = {
  id: Scalars["ID"]
}

export type IDeleteRegionOutput = {
  __typename?: "DeleteRegionOutput"
  id: Scalars["ID"]
}

export type IDeleteServiceRequestInput = {
  id: Scalars["ID"]
}

export type IDeleteServiceRequestOutput = {
  __typename?: "DeleteServiceRequestOutput"
  id: Scalars["ID"]
}

export type IDeleteSocialMediaInput = {
  id: Scalars["ID"]
}

export type IDeleteSocialMediaOutput = {
  __typename?: "DeleteSocialMediaOutput"
  id: Scalars["ID"]
}

export type IDeleteUnitAmenityInput = {
  id: Scalars["ID"]
  unitId: Scalars["ID"]
}

export type IDeleteUnitInput = {
  id: Scalars["ID"]
}

export type IDeleteUnitOutput = {
  __typename?: "DeleteUnitOutput"
  id: Scalars["ID"]
}

export type IDeleteUnitPriceInput = {
  id: Scalars["ID"]
}

export type IDeleteUnitStatusInput = {
  id: Scalars["ID"]
  unitId: Scalars["ID"]
}

export type IDeleteUtilityInput = {
  id: Scalars["ID"]
}

export type IDeleteUtilityOutput = {
  __typename?: "DeleteUtilityOutput"
  id: Scalars["ID"]
}

export type IDistrict = {
  __typename?: "District"
  displayEmailAddress: Scalars["AWSEmail"]
  id: Scalars["ID"]
  name: Scalars["String"]
  region: IRegion
}

export type IDistrictLeadSource = IIDistrictLeadSource & {
  __typename?: "DistrictLeadSource"
  district: IUsherDistrict
  fees?: Maybe<Array<Maybe<IDistrictLeadSourceFeeEmbedded>>>
  id: Scalars["ID"]
  leadSource: ILeadSource
  phoneNumber?: Maybe<Scalars["String"]>
}

export type IDistrictLeadSourceEmbedded = IIDistrictLeadSource & {
  __typename?: "DistrictLeadSourceEmbedded"
  district: IUsherDistrict
  fees?: Maybe<Array<Maybe<IDistrictLeadSourceFeeEmbedded>>>
  id: Scalars["ID"]
  phoneNumber?: Maybe<Scalars["String"]>
}

export type IDistrictLeadSourceFee = IIDistrictLeadSourceFee & {
  __typename?: "DistrictLeadSourceFee"
  districtLeadSource: Scalars["ID"]
  endDate?: Maybe<Scalars["AWSDateTime"]>
  id: Scalars["ID"]
  monthlyFee?: Maybe<Scalars["Float"]>
  name?: Maybe<Scalars["String"]>
  startDate?: Maybe<Scalars["AWSDateTime"]>
}

export type IDistrictLeadSourceFeeEmbedded = IIDistrictLeadSourceFee & {
  __typename?: "DistrictLeadSourceFeeEmbedded"
  endDate?: Maybe<Scalars["AWSDateTime"]>
  id: Scalars["ID"]
  monthlyFee?: Maybe<Scalars["Float"]>
  name?: Maybe<Scalars["String"]>
  startDate?: Maybe<Scalars["AWSDateTime"]>
}

export type IDistrictsWhereInput = {
  id?: InputMaybe<Scalars["ID"]>
  name?: InputMaybe<Scalars["String"]>
}

export type IDom2Application = {
  __typename?: "Dom2Application"
  applicationDate?: Maybe<Scalars["AWSDateTime"]>
  leaseId?: Maybe<Scalars["String"]>
  leaseSignedDate?: Maybe<Scalars["AWSDateTime"]>
}

export type IECheck = {
  __typename?: "ECheck"
  accountNumber?: Maybe<Scalars["String"]>
  eCheckType?: Maybe<IECheckType>
  routingNumber?: Maybe<Scalars["String"]>
}

export type IECheckInput = {
  accountNumber?: InputMaybe<Scalars["String"]>
  eCheckType?: InputMaybe<IECheckType>
  routingNumber?: InputMaybe<Scalars["String"]>
}

export enum IECheckType {
  Checking = "CHECKING",
  Savings = "SAVINGS",
}

export type IEarlyTerminationRate = {
  __typename?: "EarlyTerminationRate"
  bathrooms?: Maybe<Scalars["Float"]>
  bedrooms?: Maybe<Scalars["Float"]>
  community?: Maybe<Scalars["String"]>
  expirationDate?: Maybe<Scalars["AWSDateTime"]>
  moveOutDate?: Maybe<Scalars["AWSDateTime"]>
}

export type IEmployee = {
  __typename?: "Employee"
  id: Scalars["ID"]
  isDeleted?: Maybe<Scalars["Boolean"]>
  isIT?: Maybe<Scalars["Boolean"]>
  isMaintenance?: Maybe<Scalars["Boolean"]>
  name: Scalars["String"]
  userId: Scalars["ID"]
}

export type IEmployeesWhereInput = {
  id?: InputMaybe<Scalars["ID"]>
  name?: InputMaybe<Scalars["String"]>
}

export type IEventRecord = {
  __typename?: "EventRecord"
  action?: Maybe<Scalars["String"]>
  aggregate?: Maybe<Scalars["String"]>
  aggregateId?: Maybe<Scalars["String"]>
  createdDate?: Maybe<Scalars["AWSDateTime"]>
  id: Scalars["ID"]
  metadata?: Maybe<Scalars["String"]>
  order?: Maybe<Scalars["String"]>
  record?: Maybe<Scalars["String"]>
  service?: Maybe<Scalars["String"]>
  topic?: Maybe<Scalars["String"]>
  type?: Maybe<Scalars["String"]>
  version?: Maybe<Scalars["String"]>
}

export type IExecuteRecurringPaymentsInput = {
  recurringPaymentIds: Array<Scalars["ID"]>
}

export type IExecuteRecurringPaymentsOutput = {
  __typename?: "ExecuteRecurringPaymentsOutput"
  successfulRecurringPaymentIds?: Maybe<Array<Scalars["ID"]>>
}

export type IExposureRisk = {
  __typename?: "ExposureRisk"
  bathrooms?: Maybe<Scalars["Float"]>
  bedrooms?: Maybe<Scalars["Float"]>
  communityName?: Maybe<Scalars["String"]>
  vacantDate?: Maybe<Scalars["AWSDateTime"]>
}

export type IFloorPlan = {
  __typename?: "FloorPlan"
  amenities?: Maybe<Array<IFloorPlanAmenity>>
  availableDate?: Maybe<Scalars["AWSDateTime"]>
  bathrooms?: Maybe<Scalars["Float"]>
  bedrooms?: Maybe<Scalars["Float"]>
  community?: Maybe<ICommunity>
  dens?: Maybe<Scalars["Float"]>
  description?: Maybe<Scalars["String"]>
  id: Scalars["ID"]
  images?: Maybe<Array<IImage>>
  matterportUrl?: Maybe<Scalars["String"]>
  minimumDeposit?: Maybe<Scalars["Float"]>
  name?: Maybe<Scalars["String"]>
  promotionalEndDate?: Maybe<Scalars["AWSDateTime"]>
  promotionalMaxLeaseTerm?: Maybe<Scalars["Int"]>
  promotionalMessage?: Maybe<Scalars["String"]>
  promotionalMinLeaseTerm?: Maybe<Scalars["Int"]>
  promotionalMonthsDiscount?: Maybe<Scalars["Float"]>
  promotionalPriceDiscount?: Maybe<Scalars["Float"]>
  promotionalStartDate?: Maybe<Scalars["AWSDateTime"]>
  rentMax?: Maybe<Scalars["Float"]>
  rentMin?: Maybe<Scalars["Float"]>
  residentScreeningRequired?: Maybe<Scalars["Boolean"]>
  squareFeetMax?: Maybe<Scalars["Float"]>
  squareFeetMin?: Maybe<Scalars["Float"]>
  terms?: Maybe<Array<ITermFee>>
  urlSlug?: Maybe<Scalars["String"]>
  youtubeUrl?: Maybe<Scalars["String"]>
}

export type IFloorPlanAmenity = IIAmenity & {
  __typename?: "FloorPlanAmenity"
  amenityGroup?: Maybe<IAmenityGroup>
  amenityType?: Maybe<IAmenityType>
  floorPlanId: Scalars["ID"]
  id: Scalars["ID"]
  mitsName?: Maybe<IMitsName>
  name?: Maybe<Scalars["String"]>
}

export type IFloorPlanDetail = {
  __typename?: "FloorPlanDetail"
  ada?: Maybe<Scalars["Boolean"]>
  averageSquareFeet?: Maybe<Scalars["Float"]>
  bathrooms?: Maybe<Scalars["Float"]>
  bedrooms?: Maybe<Scalars["Float"]>
  communityName?: Maybe<Scalars["String"]>
  den?: Maybe<Scalars["Boolean"]>
  dryer?: Maybe<Scalars["Boolean"]>
  floorPlanId: Scalars["String"]
  floorPlanName?: Maybe<Scalars["String"]>
  largeEntryCloset?: Maybe<Scalars["Boolean"]>
  name?: Maybe<Scalars["String"]>
  privateBalcony?: Maybe<Scalars["Boolean"]>
  splitBedroomLayout?: Maybe<Scalars["Boolean"]>
  terms?: Maybe<Array<IFloorPlanLeaseTermFee>>
  washer?: Maybe<Scalars["Boolean"]>
}

export type IFloorPlanLeaseTermFee = {
  __typename?: "FloorPlanLeaseTermFee"
  additionalRent: Scalars["Float"]
  floorPlanId?: Maybe<Scalars["ID"]>
  id: Scalars["ID"]
  minimumMonths: Scalars["Int"]
}

export type IFloorPlanOrderBy = {
  name?: InputMaybe<ISort>
  promotionalMessage?: InputMaybe<ISort>
}

export type IFloorPlansWhereInput = {
  community?: InputMaybe<Scalars["String"]>
  communityIds?: InputMaybe<Array<Scalars["String"]>>
  id?: InputMaybe<Scalars["ID"]>
  name?: InputMaybe<Scalars["String"]>
}

export type IForgottenPasswordInquiry = {
  __typename?: "ForgottenPasswordInquiry"
  email: Scalars["AWSEmail"]
  expiredDate?: Maybe<Scalars["AWSDateTime"]>
  id: Scalars["ID"]
  used: Scalars["Boolean"]
}

export type IForgottenPasswordInquiryWhereInput = {
  id?: InputMaybe<Scalars["ID"]>
}

export type IGadgetLateServiceRequestsInput = {
  createdDateMax?: InputMaybe<Scalars["AWSDateTime"]>
  createdDateMin: Scalars["AWSDateTime"]
}

export type IGadgetLateServiceRequestsOutput = {
  __typename?: "GadgetLateServiceRequestsOutput"
  serviceRequests: Array<IGadgetServiceRequest>
}

export type IGadgetServiceRequest = {
  __typename?: "GadgetServiceRequest"
  createdDate: Scalars["AWSDateTime"]
  description: Scalars["String"]
  propertyName: Scalars["String"]
  unitName?: Maybe<Scalars["String"]>
}

export type IGadgetServiceRequestCategoriesInput = {
  createdDateMax?: InputMaybe<Scalars["AWSDateTime"]>
  createdDateMin: Scalars["AWSDateTime"]
}

export type IGadgetServiceRequestCategoriesOutput = {
  __typename?: "GadgetServiceRequestCategoriesOutput"
  categories: Array<IGadgetServiceRequestCategory>
}

export type IGadgetServiceRequestCategory = {
  __typename?: "GadgetServiceRequestCategory"
  count: Scalars["Int"]
  name: Scalars["String"]
}

export type IGadgetServiceRequestTurnoverTimeInput = {
  createdDateMax?: InputMaybe<Scalars["AWSDateTime"]>
  createdDateMin: Scalars["AWSDateTime"]
}

export type IGadgetServiceRequestTurnoverTimeOutput = {
  __typename?: "GadgetServiceRequestTurnoverTimeOutput"
  averageMilliseconds: Scalars["Float"]
}

export type IGadgetServiceRequestsCompletedInput = {
  communityId?: InputMaybe<Scalars["ID"]>
  dateMax?: InputMaybe<Scalars["AWSDateTime"]>
  dateMin: Scalars["AWSDateTime"]
}

export type IGadgetServiceRequestsCompletedOutput = {
  __typename?: "GadgetServiceRequestsCompletedOutput"
  completed: Scalars["Int"]
  percent?: Maybe<Scalars["Float"]>
  total: Scalars["Int"]
}

export type IGoogleReviews = {
  __typename?: "GoogleReviews"
  rating: Scalars["Float"]
  reviews: Array<IReview>
  url: Scalars["String"]
}

export type IGoogleReviewsWhereInput = {
  communityId: Scalars["ID"]
}

export type IHealthyUnitInternetPercentageOutput = {
  __typename?: "HealthyUnitInternetPercentageOutput"
  healthy: Scalars["Int"]
  total: Scalars["Int"]
  value?: Maybe<Scalars["Float"]>
}

export type IHistoricalLead = {
  __typename?: "HistoricalLead"
  date?: Maybe<Scalars["AWSDateTime"]>
  district?: Maybe<Scalars["String"]>
  numberOfLeads?: Maybe<Scalars["Float"]>
}

export type IHistoricalLease = {
  __typename?: "HistoricalLease"
  bathrooms?: Maybe<Scalars["Float"]>
  bedrooms?: Maybe<Scalars["Float"]>
  community?: Maybe<Scalars["String"]>
  moveInDate?: Maybe<Scalars["AWSDateTime"]>
}

export type IIAmenity = {
  amenityGroup?: Maybe<IAmenityGroup>
  amenityType?: Maybe<IAmenityType>
  id: Scalars["ID"]
  mitsName?: Maybe<IMitsName>
  name?: Maybe<Scalars["String"]>
}

export type IIDistrictLeadSource = {
  district: IUsherDistrict
  fees?: Maybe<Array<Maybe<IDistrictLeadSourceFeeEmbedded>>>
  id: Scalars["ID"]
  phoneNumber?: Maybe<Scalars["String"]>
}

export type IIDistrictLeadSourceFee = {
  endDate?: Maybe<Scalars["AWSDateTime"]>
  id: Scalars["ID"]
  monthlyFee?: Maybe<Scalars["Float"]>
  name?: Maybe<Scalars["String"]>
  startDate?: Maybe<Scalars["AWSDateTime"]>
}

export type IItAttachment = {
  __typename?: "ITAttachment"
  id: Scalars["ID"]
  name: Scalars["String"]
  serviceRequestId: Scalars["ID"]
  size: Scalars["Float"]
  type: Scalars["String"]
  url: Scalars["String"]
}

export type IItAttachmentsWhereInput = {
  id?: InputMaybe<Scalars["ID"]>
  serviceRequestId?: InputMaybe<Scalars["ID"]>
}

export type IItCategoriesWhereInput = {
  active?: InputMaybe<Scalars["Boolean"]>
  id?: InputMaybe<Scalars["ID"]>
  name?: InputMaybe<Scalars["String"]>
}

export type IItCategory = {
  __typename?: "ITCategory"
  active: Scalars["Boolean"]
  id: Scalars["ID"]
  name: Scalars["String"]
}

export type IItEmployee = {
  __typename?: "ITEmployee"
  id: Scalars["ID"]
  name?: Maybe<Scalars["String"]>
  userId?: Maybe<Scalars["ID"]>
}

export type IItEmployeesWhereInput = {
  id?: InputMaybe<Scalars["ID"]>
  name?: InputMaybe<Scalars["String"]>
}

export type IItServiceRequest = {
  __typename?: "ITServiceRequest"
  attachments?: Maybe<Array<Maybe<IItAttachment>>>
  callbackPhoneNumber?: Maybe<Scalars["AWSPhone"]>
  category?: Maybe<IItCategory>
  completedDate?: Maybe<Scalars["AWSDateTime"]>
  createdDate: Scalars["AWSDateTime"]
  description: Scalars["String"]
  employee?: Maybe<IItEmployee>
  id: Scalars["ID"]
  notes?: Maybe<Scalars["String"]>
  status?: Maybe<IItServiceRequestStatus>
  submitterName?: Maybe<Scalars["String"]>
  submitterUserId: Scalars["ID"]
}

export enum IItServiceRequestStatus {
  Assigned = "ASSIGNED",
  Cancelled = "CANCELLED",
  Completed = "COMPLETED",
  InProgress = "IN_PROGRESS",
  OnHold = "ON_HOLD",
  Opened = "OPENED",
  Reopened = "REOPENED",
  TemporarilyFixed = "TEMPORARILY_FIXED",
}

export type IItServiceRequestsWhereInput = {
  completedDateCeiling?: InputMaybe<Scalars["AWSDateTime"]>
  completedDateFloor?: InputMaybe<Scalars["AWSDateTime"]>
  createdDateCeiling?: InputMaybe<Scalars["AWSDateTime"]>
  createdDateFloor?: InputMaybe<Scalars["AWSDateTime"]>
  description?: InputMaybe<Scalars["String"]>
  id?: InputMaybe<Scalars["ID"]>
  statuses?: InputMaybe<Array<InputMaybe<IItServiceRequestStatus>>>
  submitterUserId?: InputMaybe<Scalars["ID"]>
}

export type IImage = {
  __typename?: "Image"
  altText?: Maybe<Scalars["String"]>
  caption?: Maybe<Scalars["String"]>
  displayOrder?: Maybe<Scalars["Int"]>
  id: Scalars["ID"]
  name?: Maybe<Scalars["String"]>
  s3Url?: Maybe<Scalars["String"]>
  size?: Maybe<Scalars["Float"]>
  tag?: Maybe<ITagEnum>
  type?: Maybe<Scalars["String"]>
}

export type IImageCommunityInput = {
  altText?: InputMaybe<Scalars["String"]>
  caption?: InputMaybe<Scalars["String"]>
  communityId: Scalars["ID"]
  displayOrder?: InputMaybe<Scalars["Int"]>
  name?: InputMaybe<Scalars["String"]>
  s3Url?: InputMaybe<Scalars["String"]>
  size?: InputMaybe<Scalars["Float"]>
  tag?: InputMaybe<ITagEnum>
  type?: InputMaybe<Scalars["String"]>
}

export type IImageFloorPlanInput = {
  altText?: InputMaybe<Scalars["String"]>
  caption?: InputMaybe<Scalars["String"]>
  displayOrder?: InputMaybe<Scalars["Int"]>
  floorPlanId: Scalars["ID"]
  name?: InputMaybe<Scalars["String"]>
  s3Url?: InputMaybe<Scalars["String"]>
  size?: InputMaybe<Scalars["Float"]>
  tag?: InputMaybe<ITagEnum>
  type?: InputMaybe<Scalars["String"]>
}

export type IImagesWhereInput = {
  id?: InputMaybe<Scalars["ID"]>
}

export type IInternetPlan = {
  __typename?: "InternetPlan"
  description?: Maybe<Scalars["String"]>
  id: Scalars["ID"]
  name?: Maybe<Scalars["String"]>
  pricePerMonth?: Maybe<Scalars["Float"]>
}

export type ILeadAndSource = {
  __typename?: "LeadAndSource"
  numberOfLeads: Scalars["Int"]
  source: Scalars["String"]
}

export type ILeadSource = {
  __typename?: "LeadSource"
  comment?: Maybe<Scalars["String"]>
  directory?: Maybe<Scalars["String"]>
  districtLeadSources?: Maybe<Array<Maybe<IDistrictLeadSourceEmbedded>>>
  fileProtocol?: Maybe<Scalars["String"]>
  host?: Maybe<Scalars["String"]>
  id: Scalars["ID"]
  leadSourceType?: Maybe<ILeadSourceType>
  name?: Maybe<Scalars["String"]>
  password?: Maybe<Scalars["String"]>
  port?: Maybe<Scalars["Int"]>
  publicId?: Maybe<Scalars["String"]>
  username?: Maybe<Scalars["String"]>
}

export enum ILeadSourceType {
  NorhartWebsite = "NORHART_WEBSITE",
  Zillow = "ZILLOW",
}

export type ILeadSourceWhereInput = {
  id?: InputMaybe<Scalars["ID"]>
  name?: InputMaybe<Scalars["String"]>
  publicId?: InputMaybe<Scalars["String"]>
}

export type ILeadToMoveInTime = {
  __typename?: "LeadToMoveInTime"
  bathrooms?: Maybe<Scalars["Float"]>
  bedrooms?: Maybe<Scalars["Float"]>
  community?: Maybe<Scalars["String"]>
  emailDateReceived?: Maybe<Scalars["AWSDateTime"]>
  moveInDate?: Maybe<Scalars["AWSDateTime"]>
  phoneDateReceived?: Maybe<Scalars["AWSDateTime"]>
}

export type ILeaseNamesWhereInput = {
  communityId?: InputMaybe<Scalars["ID"]>
  limit?: InputMaybe<Scalars["Int"]>
  name?: InputMaybe<Scalars["String"]>
  offset?: InputMaybe<Scalars["Int"]>
}

export type ILengthOfStay = {
  __typename?: "LengthOfStay"
  bathrooms?: Maybe<Scalars["Float"]>
  bedrooms?: Maybe<Scalars["Float"]>
  community?: Maybe<Scalars["String"]>
  leaseTermInDays?: Maybe<Scalars["Float"]>
  lengthOfStayInDays?: Maybe<Scalars["Float"]>
  moveInDate?: Maybe<Scalars["AWSDateTime"]>
}

export type IMailchimpUser = {
  __typename?: "MailchimpUser"
  emailAddress: Scalars["AWSEmail"]
  firstName?: Maybe<Scalars["String"]>
  lastName?: Maybe<Scalars["String"]>
  phoneNumber?: Maybe<Scalars["String"]>
  tags?: Maybe<Array<Scalars["String"]>>
}

export type IMakeReadyUnit = {
  __typename?: "MakeReadyUnit"
  availableDate?: Maybe<Scalars["AWSDateTime"]>
  moveInDate?: Maybe<Scalars["AWSDateTime"]>
  moveOutChecklist?: Maybe<IMoveOut>
  moveOutChecklistId: Scalars["ID"]
  moveOutDate?: Maybe<Scalars["AWSDateTime"]>
  unitId: Scalars["ID"]
  unitName?: Maybe<Scalars["String"]>
}

export enum IMitsName {
  AccentWalls = "ACCENT_WALLS",
  AdditionalStorage = "ADDITIONAL_STORAGE",
  AdultDayCare = "ADULT_DAY_CARE",
  AirConditioner = "AIR_CONDITIONER",
  Alarm = "ALARM",
  AlzheimersMemoryCare = "ALZHEIMERS_MEMORY_CARE",
  Availability_24Hours = "AVAILABILITY_24_HOURS",
  Balcony = "BALCONY",
  BasketballCourt = "BASKETBALL_COURT",
  Bilingual = "BILINGUAL",
  BoatDocks = "BOAT_DOCKS",
  BusinessCenter = "BUSINESS_CENTER",
  Cable = "CABLE",
  Carpet = "CARPET",
  Carport = "CARPORT",
  CarWashArea = "CAR_WASH_AREA",
  CeilingFan = "CEILING_FAN",
  ChildCare = "CHILD_CARE",
  ClubDiscount = "CLUB_DISCOUNT",
  ClubHouse = "CLUB_HOUSE",
  Concierge = "CONCIERGE",
  ConferenceRoom = "CONFERENCE_ROOM",
  ContinuedCareRetirement = "CONTINUED_CARE_RETIREMENT",
  ControlledAccess = "CONTROLLED_ACCESS",
  Courtyard = "COURTYARD",
  CoverPark = "COVER_PARK",
  DishWasher = "DISH_WASHER",
  Disposal = "DISPOSAL",
  DoorAttendant = "DOOR_ATTENDANT",
  DoubleSinkVanity = "DOUBLE_SINK_VANITY",
  Dryer = "DRYER",
  Elevator = "ELEVATOR",
  Fireplace = "FIREPLACE",
  FitnessCenter = "FITNESS_CENTER",
  FramedMirrors = "FRAMED_MIRRORS",
  FreeWeights = "FREE_WEIGHTS",
  Furnished = "FURNISHED",
  FurnishedAvailable = "FURNISHED_AVAILABLE",
  GamingStations = "GAMING_STATIONS",
  Garage = "GARAGE",
  GarageInUnit = "GARAGE_IN_UNIT",
  Gate = "GATE",
  GroceryService = "GROCERY_SERVICE",
  GroupExercise = "GROUP_EXERCISE",
  GuestRoom = "GUEST_ROOM",
  Handrails = "HANDRAILS",
  HardwoodFlooring = "HARDWOOD_FLOORING",
  HardSurfaceCounterTops = "HARD_SURFACE_COUNTER_TOPS",
  Heat = "HEAT",
  HighSpeed = "HIGH_SPEED",
  Housekeeping = "HOUSEKEEPING",
  HouseSitting = "HOUSE_SITTING",
  IndependentLiving = "INDEPENDENT_LIVING",
  IndividualClimateControl = "INDIVIDUAL_CLIMATE_CONTROL",
  IslandKitchen = "ISLAND_KITCHEN",
  JoggingWalkingTrails = "JOGGING_WALKING_TRAILS",
  LakeAccess = "LAKE_ACCESS",
  LakeFront = "LAKE_FRONT",
  LaminateCounterTops = "LAMINATE_COUNTER_TOPS",
  LargeClosets = "LARGE_CLOSETS",
  Laundry = "LAUNDRY",
  Library = "LIBRARY",
  LinenCloset = "LINEN_CLOSET",
  MealService = "MEAL_SERVICE",
  MediaRoom = "MEDIA_ROOM",
  Microwave = "MICROWAVE",
  MultiUseRoom = "MULTI_USE_ROOM",
  NightPatrol = "NIGHT_PATROL",
  OnSiteMaintenance = "ON_SITE_MAINTENANCE",
  OnSiteManagement = "ON_SITE_MANAGEMENT",
  OtherGeneralAmenity = "OTHER_GENERAL_AMENITY",
  OtherSeniorAmenity = "OTHER_SENIOR_AMENITY",
  OtherSpecificAmenity = "OTHER_SPECIFIC_AMENITY",
  OtherUnitAmenity = "OTHER_UNIT_AMENITY",
  PackageReceiving = "PACKAGE_RECEIVING",
  Pantry = "PANTRY",
  Patio = "PATIO",
  PerDiemAccepted = "PER_DIEM_ACCEPTED",
  Playground = "PLAYGROUND",
  Pool = "POOL",
  PrivateBalcony = "PRIVATE_BALCONY",
  PrivatePatio = "PRIVATE_PATIO",
  Racquetball = "RACQUETBALL",
  Range = "RANGE",
  RecreationRoom = "RECREATION_ROOM",
  Recycling = "RECYCLING",
  Refrigerator = "REFRIGERATOR",
  Satellite = "SATELLITE",
  Sauna = "SAUNA",
  ShortTermLease = "SHORT_TERM_LEASE",
  SkilledNursing = "SKILLED_NURSING",
  Skylight = "SKYLIGHT",
  SmokeFree = "SMOKE_FREE",
  Spa = "SPA",
  StorageSpace = "STORAGE_SPACE",
  Sundeck = "SUNDECK",
  TennisCourt = "TENNIS_COURT",
  TileFlooring = "TILE_FLOORING",
  Transportation = "TRANSPORTATION",
  TvLounge = "TV_LOUNGE",
  ValetTrash = "VALET_TRASH",
  VaultedCeiling = "VAULTED_CEILING",
  View = "VIEW",
  Vintage = "VINTAGE",
  VinylFlooring = "VINYL_FLOORING",
  VolleyballCourt = "VOLLEYBALL_COURT",
  Washer = "WASHER",
  WasherDryerHookup = "WASHER_DRYER_HOOKUP",
  WheelChair = "WHEEL_CHAIR",
  WindowCoverings = "WINDOW_COVERINGS",
  WirelessInternet = "WIRELESS_INTERNET",
}

export type IMoveInDate = {
  __typename?: "MoveInDate"
  bathrooms?: Maybe<Scalars["Float"]>
  bedrooms?: Maybe<Scalars["Float"]>
  moveInDate?: Maybe<Scalars["AWSDateTime"]>
  name?: Maybe<Scalars["String"]>
}

export type IMoveInsAndOutsInput = {
  communityId?: InputMaybe<Scalars["ID"]>
  dateMax: Scalars["AWSDateTime"]
  dateMin: Scalars["AWSDateTime"]
}

export type IMoveInsAndOutsOutput = {
  __typename?: "MoveInsAndOutsOutput"
  moveIns: Array<IDatedType>
  moveOuts: Array<IDatedType>
}

export type IMoveOut = {
  __typename?: "MoveOut"
  cleanCarpetCompleted: Scalars["Boolean"]
  cleanCarpetCompletedDate?: Maybe<Scalars["AWSDateTime"]>
  cleanCarpetScheduledCompleted: Scalars["Boolean"]
  cleanCarpetScheduledDate?: Maybe<Scalars["AWSDateTime"]>
  cleanCompleted: Scalars["Boolean"]
  cleanCompletedDate?: Maybe<Scalars["AWSDateTime"]>
  cleanScheduledCompleted: Scalars["Boolean"]
  cleanScheduledDate?: Maybe<Scalars["AWSDateTime"]>
  floorReplacement?: Maybe<Scalars["String"]>
  garageInspected: Scalars["Boolean"]
  garageInspectedDate?: Maybe<Scalars["AWSDateTime"]>
  id: Scalars["ID"]
  keysTurnedInCompleted: Scalars["Boolean"]
  keysTurnedInDate?: Maybe<Scalars["AWSDateTime"]>
  leasingId: Scalars["ID"]
  maintenanceDate?: Maybe<Scalars["AWSDateTime"]>
  maintenanceDoneCompleted: Scalars["Boolean"]
  maintenanceDoneDate?: Maybe<Scalars["AWSDateTime"]>
  maintenanceEmployeeId?: Maybe<Scalars["ID"]>
  maintenanceEmployeeName?: Maybe<Scalars["String"]>
  maintenanceType?: Maybe<Scalars["String"]>
  moveInPicturesTakenCompleted: Scalars["Boolean"]
  moveInPicturesTakenDate?: Maybe<Scalars["AWSDateTime"]>
  notes?: Maybe<Scalars["String"]>
  paint?: Maybe<Scalars["String"]>
  paperworkSubmitted: Scalars["Boolean"]
  picturesTakenCompleted: Scalars["Boolean"]
  picturesTakenDate?: Maybe<Scalars["AWSDateTime"]>
  schedulerMoveOut?: Maybe<Scalars["AWSDateTime"]>
  schedulerNextMoveIn?: Maybe<Scalars["AWSDateTime"]>
  unitId: Scalars["ID"]
  unitName?: Maybe<Scalars["String"]>
  walkThroughtCompleted: Scalars["Boolean"]
  walkThroughtDate?: Maybe<Scalars["AWSDateTime"]>
}

export type IMoveOutDates = {
  __typename?: "MoveOutDates"
  leaseId: Scalars["ID"]
  moveOutDate?: Maybe<Scalars["AWSDateTime"]>
  nextMoveInDate?: Maybe<Scalars["AWSDateTime"]>
}

export type IMoveOutDatesWhereInput = {
  leaseId: Scalars["ID"]
}

export type IMoveOutWhereInput = {
  id?: InputMaybe<Scalars["ID"]>
  leasingIds?: InputMaybe<Array<Scalars["ID"]>>
  unitIds?: InputMaybe<Array<Scalars["ID"]>>
}

export type IMutation = {
  __typename?: "Mutation"
  calculateFullRecurringPayments?: Maybe<ICalculateFullRecurringPaymentsOutput>
  createAmenity: IAmenity
  createAttachment: IAttachment
  createCategory: ICategory
  createCommunity: ICommunity
  createCommunityAmenity: ICommunityAmenity
  createContact: IContact
  createDistrict: IDistrict
  createEmployee: IEmployee
  createFloorPlan: IFloorPlan
  createFloorPlanAmenity: IFloorPlanAmenity
  createFloorPlanImage: IImage
  createFloorPlanLeaseTerm: ITermFee
  createForgottenPasswordInquiry: IForgottenPasswordInquiry
  createITAttachment: IItAttachment
  createITCategory: IItCategory
  createITServiceRequest: IItServiceRequest
  createImage: IImage
  createLeadSource: ILeadSource
  createLeaseNote: INote
  createMoveOutChecklist: IMoveOut
  createOfficeHour: IOfficeHour
  createParkingSpace: IParkingSpace
  createParkingViolationLetter: IParkingViolationLetter
  createPayment: ICreatePaymentOutput
  createPaymentMethod: ICreatePaymentMethodOutput
  createPetPolicy: IPetPolicy
  createPromotion: IPromotion
  createProperty: IProperty
  createRecurringPayment: ICreateRecurringPaymentOutput
  createRegion: IRegion
  createServiceRequest: IServiceRequest
  createSocialMedia: ISocialMedia
  createUnit: IUnit
  createUnitAmenity: IUnitAmenity
  createUser: ICreateUserOutput
  createUtility: IUtility
  createWebsiteEvent: IWebsiteEvent
  deleteAmenity: IDeleteOutput
  deleteAttachment: IDeleteAttachmentOutput
  deleteCommunity: IDeleteCommunityOutput
  deleteCommunityAmenity: IDeleteAmenityOutput
  deleteDistrict: IDeleteDistrictOutput
  deleteEmployee: IEmployee
  deleteFloorPlan: IDeleteFloorPlanOutput
  deleteFloorPlanAmenity: IDeleteAmenityOutput
  deleteFloorPlanImage: IDeleteImageOutput
  deleteFloorPlanLeaseTerm: IDeleteFloorPlanLeaseTermOutput
  deleteITAttachment: IDeleteItAttachmentOutput
  deleteITServiceRequest: IDeleteItServiceRequestOutput
  deleteImage: IDeleteImageOutput
  deleteLeadSource: Scalars["ID"]
  deleteMoveOutChecklist: IDeleteOutput
  deleteOfficeHour: IDeleteOfficeHourOutput
  deleteParkingSpace: IDeleteParkingOutput
  deletePaymentMethod: IDeletePaymentMethodOutput
  deletePetPolicy: IDeletePetPolicyOutput
  deletePromotion: IDeletePromotionOutput
  deleteProperty: IDeletePropertyOutput
  deleteRecurringPayment: IDeleteRecurringPaymentOutput
  deleteRegion: IDeleteRegionOutput
  deleteServiceRequest: IDeleteServiceRequestOutput
  deleteSocialMedia: IDeleteSocialMediaOutput
  deleteUnit: IDeleteUnitOutput
  deleteUnitAmenity: IDeleteAmenityOutput
  deleteUnitRent: Array<IUnitRentHistory>
  deleteUnitStatus: Array<IUnitStatusHistory>
  deleteUtility: IDeleteUtilityOutput
  executeRecurringPayments: IExecuteRecurringPaymentsOutput
  paUpdateFloorPlanLeaseTerms: Array<IFloorPlanLeaseTermFee>
  sendCampaign: Scalars["Boolean"]
  sendNewCampaign: Scalars["Boolean"]
  signS3Request: ISignS3RequestOutput
  updateAmenity: IAmenity
  updateCategory: ICategory
  updateCommunity: ICommunity
  updateDistrict: IDistrict
  updateEmployee: IEmployee
  updateFloorPlan: IFloorPlan
  updateFloorPlanImage: IImage
  updateFloorPlanLeaseTerm: ITermFee
  updateFloorPlanLeaseTerms: Array<ITermFee>
  updateITCategory: IItCategory
  updateITServiceRequest: IItServiceRequest
  updateImage: IImage
  updateLeadSource: ILeadSource
  updateMailchimpUser: Scalars["AWSEmail"]
  updateMoveOutChecklist: IMoveOut
  updateOfficeHour: IUpdateOfficeHourOutput
  updateParkingSpace: IParkingSpace
  updateParkingViolationLetter: IParkingViolationLetter
  updatePassword: IUpdatePasswordOutput
  updatePaymentMethod: IUpdatePaymentMethodOutput
  updatePetPolicy: IPetPolicy
  updatePromotion: IUpdatePromotionOutput
  updateProperty: IProperty
  updateRecurringPayment: IUpdateRecurringPaymentOutput
  updateRegion: IRegion
  updateServiceRequest: IServiceRequest
  updateSocialMedia: ISocialMedia
  updateUnit: IUnit
  updateUnitPrice: Array<IUnitPrice>
  updateUnitRents: Array<IUnitRentHistory>
  updateUnitStatus: Array<IUnitStatusHistory>
  updateUtility: IUpdateUtilityOutput
}

export type IMutationCalculateFullRecurringPaymentsArgs = {
  input?: InputMaybe<ICalculateFullRecurringPaymentsInput>
}

export type IMutationCreateAmenityArgs = {
  input: ICreateAmenityInput
}

export type IMutationCreateAttachmentArgs = {
  input: ICreateAttachmentInput
}

export type IMutationCreateCategoryArgs = {
  input: ICreateCategoryInput
}

export type IMutationCreateCommunityArgs = {
  input: ICreateCommunityInput
}

export type IMutationCreateCommunityAmenityArgs = {
  input: ICommunityAmenityInput
}

export type IMutationCreateContactArgs = {
  input: ICreateContactInput
}

export type IMutationCreateDistrictArgs = {
  input: ICreateDistrictInput
}

export type IMutationCreateEmployeeArgs = {
  input?: InputMaybe<ICreateEmployeeInput>
}

export type IMutationCreateFloorPlanArgs = {
  input: ICreateFloorPlanInput
}

export type IMutationCreateFloorPlanAmenityArgs = {
  input: IAmenityFloorPlanInput
}

export type IMutationCreateFloorPlanImageArgs = {
  input: IImageFloorPlanInput
}

export type IMutationCreateFloorPlanLeaseTermArgs = {
  input: ICreateFloorPlanLeaseTermInput
}

export type IMutationCreateForgottenPasswordInquiryArgs = {
  input: ICreateForgottenPasswordInquiryInput
}

export type IMutationCreateItAttachmentArgs = {
  input: ICreateItAttachmentInput
}

export type IMutationCreateItCategoryArgs = {
  input: ICreateItCategoryInput
}

export type IMutationCreateItServiceRequestArgs = {
  input: ICreateItServiceRequestInput
}

export type IMutationCreateImageArgs = {
  input: IImageCommunityInput
}

export type IMutationCreateLeadSourceArgs = {
  input: ICreateLeadSourceInput
}

export type IMutationCreateLeaseNoteArgs = {
  input?: InputMaybe<ICreateLeaseNoteInput>
}

export type IMutationCreateMoveOutChecklistArgs = {
  input?: InputMaybe<ICreateMoveOutChecklistInput>
}

export type IMutationCreateOfficeHourArgs = {
  input: IOfficeHourInput
}

export type IMutationCreateParkingSpaceArgs = {
  input: IParkingSpaceInput
}

export type IMutationCreateParkingViolationLetterArgs = {
  input: IParkingViolationLetterInput
}

export type IMutationCreatePaymentArgs = {
  input: ICreatePaymentInput
}

export type IMutationCreatePaymentMethodArgs = {
  input: ICreatePaymentMethodInput
}

export type IMutationCreatePetPolicyArgs = {
  input: IPetPolicyInput
}

export type IMutationCreatePromotionArgs = {
  input: IPromotionInput
}

export type IMutationCreatePropertyArgs = {
  input: ICreatePropertyInput
}

export type IMutationCreateRecurringPaymentArgs = {
  input: ICreateRecurringPaymentInput
}

export type IMutationCreateRegionArgs = {
  input: ICreateRegionInput
}

export type IMutationCreateServiceRequestArgs = {
  input: ICreateServiceRequestInput
}

export type IMutationCreateSocialMediaArgs = {
  input: ISocialMediaInput
}

export type IMutationCreateUnitArgs = {
  input: ICreateUnitInput
}

export type IMutationCreateUnitAmenityArgs = {
  input: IAmenityUnitInput
}

export type IMutationCreateUserArgs = {
  input: ICreateUserInput
}

export type IMutationCreateUtilityArgs = {
  input: IUtilityInput
}

export type IMutationCreateWebsiteEventArgs = {
  input: IWebsiteEventInput
}

export type IMutationDeleteAmenityArgs = {
  input: IDeleteInput
}

export type IMutationDeleteAttachmentArgs = {
  input: IDeleteAttachmentInput
}

export type IMutationDeleteCommunityArgs = {
  input: IDeleteCommunityInput
}

export type IMutationDeleteCommunityAmenityArgs = {
  input: IDeleteCommunityAmenityInput
}

export type IMutationDeleteDistrictArgs = {
  input: IDeleteDistrictInput
}

export type IMutationDeleteEmployeeArgs = {
  input?: InputMaybe<IDeleteEmployeeInput>
}

export type IMutationDeleteFloorPlanArgs = {
  input: IDeleteFloorPlanInput
}

export type IMutationDeleteFloorPlanAmenityArgs = {
  input: IDeleteFloorPlanAmenityInput
}

export type IMutationDeleteFloorPlanImageArgs = {
  input: IDeleteImageInput
}

export type IMutationDeleteFloorPlanLeaseTermArgs = {
  input: IDeleteFloorPlanLeaseTermInput
}

export type IMutationDeleteItAttachmentArgs = {
  input: IDeleteItAttachmentInput
}

export type IMutationDeleteItServiceRequestArgs = {
  input: IDeleteItServiceRequestInput
}

export type IMutationDeleteImageArgs = {
  input: IDeleteImageInput
}

export type IMutationDeleteLeadSourceArgs = {
  input: IDeleteInput
}

export type IMutationDeleteMoveOutChecklistArgs = {
  input: IDeleteInput
}

export type IMutationDeleteOfficeHourArgs = {
  input: IDeleteOfficeHourInput
}

export type IMutationDeleteParkingSpaceArgs = {
  input: IDeleteParkingInput
}

export type IMutationDeletePaymentMethodArgs = {
  input: IDeletePaymentMethodInput
}

export type IMutationDeletePetPolicyArgs = {
  input: IDeletePetPolicyInput
}

export type IMutationDeletePromotionArgs = {
  input: IDeletePromotionInput
}

export type IMutationDeletePropertyArgs = {
  input: IDeletePropertyInput
}

export type IMutationDeleteRecurringPaymentArgs = {
  input: IDeleteRecurringPaymentInput
}

export type IMutationDeleteRegionArgs = {
  input: IDeleteRegionInput
}

export type IMutationDeleteServiceRequestArgs = {
  input: IDeleteServiceRequestInput
}

export type IMutationDeleteSocialMediaArgs = {
  input: IDeleteSocialMediaInput
}

export type IMutationDeleteUnitArgs = {
  input: IDeleteUnitInput
}

export type IMutationDeleteUnitAmenityArgs = {
  input: IDeleteUnitAmenityInput
}

export type IMutationDeleteUnitRentArgs = {
  input: IDeleteUnitPriceInput
}

export type IMutationDeleteUnitStatusArgs = {
  input: IDeleteUnitStatusInput
}

export type IMutationDeleteUtilityArgs = {
  input: IDeleteUtilityInput
}

export type IMutationExecuteRecurringPaymentsArgs = {
  input: IExecuteRecurringPaymentsInput
}

export type IMutationPaUpdateFloorPlanLeaseTermsArgs = {
  input: Array<IUpdateFloorPlanLeaseTermInput>
}

export type IMutationSendCampaignArgs = {
  input: ISendCampaignInput
}

export type IMutationSendNewCampaignArgs = {
  input: ISendNewCampaignInput
}

export type IMutationSignS3RequestArgs = {
  input: ISignS3RequestInput
}

export type IMutationUpdateAmenityArgs = {
  input: IUpdateAmenityInput
}

export type IMutationUpdateCategoryArgs = {
  input: IUpdateCategoryInput
}

export type IMutationUpdateCommunityArgs = {
  input: IUpdateCommunityInput
}

export type IMutationUpdateDistrictArgs = {
  input: IUpdateDistrictInput
}

export type IMutationUpdateEmployeeArgs = {
  input?: InputMaybe<IUpdateEmployeeInput>
}

export type IMutationUpdateFloorPlanArgs = {
  input: IUpdateFloorPlanInput
}

export type IMutationUpdateFloorPlanImageArgs = {
  input: IUpdateImageInput
}

export type IMutationUpdateFloorPlanLeaseTermArgs = {
  input: IUpdateFloorPlanLeaseTermInput
}

export type IMutationUpdateFloorPlanLeaseTermsArgs = {
  input: Array<IUpdateFloorPlanLeaseTermInput>
}

export type IMutationUpdateItCategoryArgs = {
  input: IUpdateItCategoryInput
}

export type IMutationUpdateItServiceRequestArgs = {
  input: IUpdateItServiceRequestInput
}

export type IMutationUpdateImageArgs = {
  input: IUpdateImageInput
}

export type IMutationUpdateLeadSourceArgs = {
  input: IUpdateLeadSourceInput
}

export type IMutationUpdateMailchimpUserArgs = {
  input: IUpdateMailchimpUserInput
}

export type IMutationUpdateMoveOutChecklistArgs = {
  input: IUpdateMoveOutChecklistInput
}

export type IMutationUpdateOfficeHourArgs = {
  input: IUpdateOfficeHourInput
}

export type IMutationUpdateParkingSpaceArgs = {
  input: IUpdateParkingSpaceInput
}

export type IMutationUpdateParkingViolationLetterArgs = {
  input: IParkingViolationLetterUpdateInput
}

export type IMutationUpdatePasswordArgs = {
  input: IUpdatePasswordInput
}

export type IMutationUpdatePaymentMethodArgs = {
  input: IUpdatePaymentMethodInput
}

export type IMutationUpdatePetPolicyArgs = {
  input: IUpdatePetPolicyInput
}

export type IMutationUpdatePromotionArgs = {
  input: IUpdatePromotionInput
}

export type IMutationUpdatePropertyArgs = {
  input: IUpdatePropertyInput
}

export type IMutationUpdateRecurringPaymentArgs = {
  input: IUpdateRecurringPaymentInput
}

export type IMutationUpdateRegionArgs = {
  input: IUpdateRegionInput
}

export type IMutationUpdateServiceRequestArgs = {
  input: IUpdateServiceRequestInput
}

export type IMutationUpdateSocialMediaArgs = {
  input: IUpdateSocialMediaInput
}

export type IMutationUpdateUnitArgs = {
  input: IUpdateUnitInput
}

export type IMutationUpdateUnitPriceArgs = {
  input: Array<IUpdateUnitPricingInput>
}

export type IMutationUpdateUnitRentsArgs = {
  input: Array<IUpdateUnitPriceInput>
}

export type IMutationUpdateUnitStatusArgs = {
  input?: InputMaybe<Array<IUpdateUnitStatusInput>>
}

export type IMutationUpdateUtilityArgs = {
  input: IUpdateUtilityInput
}

export type IOccupiedUnit = {
  __typename?: "OccupiedUnit"
  bathrooms?: Maybe<Scalars["Float"]>
  bedrooms?: Maybe<Scalars["Float"]>
  community?: Maybe<Scalars["String"]>
  moveInDate?: Maybe<Scalars["AWSDateTime"]>
  moveOutDate?: Maybe<Scalars["AWSDateTime"]>
}

export type IOfficeHour = {
  __typename?: "OfficeHour"
  closeTime?: Maybe<Scalars["String"]>
  communityId: Scalars["ID"]
  dayOfWeek: IDayOfWeek
  id: Scalars["ID"]
  openTime?: Maybe<Scalars["String"]>
}

export type IOfficeHourCommunityInput = {
  closeTime?: InputMaybe<Scalars["String"]>
  dayOfWeek: IDayOfWeek
  openTime?: InputMaybe<Scalars["String"]>
}

export type IOfficeHourInput = {
  closeTime?: InputMaybe<Scalars["AWSTime"]>
  communityId: Scalars["ID"]
  dayOfWeek: IDayOfWeek
  openTime?: InputMaybe<Scalars["AWSTime"]>
}

export type IOwner = {
  __typename?: "Owner"
  id: Scalars["ID"]
  name: Scalars["String"]
}

export type IOwnersWhereInput = {
  id?: InputMaybe<Scalars["ID"]>
}

export type IPaAmenity = {
  __typename?: "PAAmenity"
  id?: Maybe<Scalars["String"]>
  name?: Maybe<Scalars["String"]>
}

export type IPaCommunityAmenity = {
  __typename?: "PACommunityAmenity"
  amenities?: Maybe<Array<IPaAmenity>>
  bathrooms?: Maybe<Scalars["Float"]>
  bedrooms?: Maybe<Scalars["Float"]>
  communityName?: Maybe<Scalars["String"]>
}

export type IPaFloorPlanAmenity = {
  __typename?: "PAFloorPlanAmenity"
  amenities?: Maybe<Array<IPaAmenity>>
  bathrooms?: Maybe<Scalars["Float"]>
  bedrooms?: Maybe<Scalars["Float"]>
  communityName?: Maybe<Scalars["String"]>
  floorPlanName: Scalars["String"]
  unitNumber?: Maybe<Scalars["String"]>
}

export type IPaUnitAmenity = {
  __typename?: "PAUnitAmenity"
  amenities?: Maybe<Array<IPaAmenity>>
  bathrooms?: Maybe<Scalars["Float"]>
  bedrooms?: Maybe<Scalars["Float"]>
  communityName?: Maybe<Scalars["String"]>
  propertyName?: Maybe<Scalars["String"]>
  unitNumber?: Maybe<Scalars["String"]>
}

export type IPaUnitStatus = {
  __typename?: "PAUnitStatus"
  bathrooms?: Maybe<Scalars["Float"]>
  bedrooms?: Maybe<Scalars["Float"]>
  communityName?: Maybe<Scalars["String"]>
  currentStatus?: Maybe<IStatus>
  leaseExpirationDate?: Maybe<Scalars["AWSDateTime"]>
  statusAfterMoveOut?: Maybe<IStatus>
  statusStartDate?: Maybe<Scalars["AWSDateTime"]>
  unitName?: Maybe<Scalars["String"]>
}

export type IParkingSpace = {
  __typename?: "ParkingSpace"
  assigned?: Maybe<Scalars["Boolean"]>
  comment?: Maybe<Scalars["String"]>
  communityId: Scalars["ID"]
  fee?: Maybe<Scalars["Float"]>
  id: Scalars["ID"]
  maxSpacesPerUnit?: Maybe<Scalars["Int"]>
  parkingType: IParkingType
  spacesPerUnit?: Maybe<Scalars["Int"]>
}

export type IParkingSpaceCommunityInput = {
  assigned?: InputMaybe<Scalars["Boolean"]>
  comment?: InputMaybe<Scalars["String"]>
  fee?: InputMaybe<Scalars["Float"]>
  maxSpacesPerUnit?: InputMaybe<Scalars["Int"]>
  parkingType: IParkingType
  spacesPerUnit?: InputMaybe<Scalars["Int"]>
}

export type IParkingSpaceInput = {
  assigned?: InputMaybe<Scalars["Boolean"]>
  comment?: InputMaybe<Scalars["String"]>
  communityId: Scalars["ID"]
  fee?: InputMaybe<Scalars["Float"]>
  maxSpacesPerUnit?: InputMaybe<Scalars["Int"]>
  parkingType: IParkingType
  spacesPerUnit?: InputMaybe<Scalars["Int"]>
}

export type IParkingSpacePermission = {
  __typename?: "ParkingSpacePermission"
  lease?: Maybe<ICellarLease>
  licensePlates?: Maybe<Array<Maybe<Scalars["String"]>>>
  unit: ICellarUnit
}

export type IParkingSpacePermissionsWhereInput = {
  propertyId?: InputMaybe<Scalars["ID"]>
}

export enum IParkingType {
  Garage = "GARAGE",
  Surface = "SURFACE",
  Underground = "UNDERGROUND",
}

export type IParkingViolationLetter = {
  __typename?: "ParkingViolationLetter"
  date: Scalars["AWSDateTime"]
  id: Scalars["ID"]
  propertyId: Scalars["ID"]
  s3Url: Scalars["String"]
  unit: ICellarUnit
  violation: IParkingViolationType
}

export type IParkingViolationLetterInput = {
  propertyId: Scalars["ID"]
  unit: ICellarUnitInput
  violation: IParkingViolationType
}

export type IParkingViolationLetterUpdateInput = {
  date?: InputMaybe<Scalars["AWSDateTime"]>
  id: Scalars["ID"]
  propertyId?: InputMaybe<Scalars["ID"]>
  s3Url?: InputMaybe<Scalars["String"]>
  unit?: InputMaybe<ICellarUnitInput>
  violation?: InputMaybe<IParkingViolationType>
}

export type IParkingViolationLettersWhereInput = {
  dateMax?: InputMaybe<Scalars["AWSDateTime"]>
  dateMin?: InputMaybe<Scalars["AWSDateTime"]>
  propertyId?: InputMaybe<Scalars["ID"]>
}

export enum IParkingViolationType {
  Ada = "ADA",
  ItemsInSpace = "ITEMS_IN_SPACE",
  SpaceNotLeased = "SPACE_NOT_LEASED",
  WrongVehiclePlates = "WRONG_VEHICLE_PLATES",
}

export type IPastDue = {
  __typename?: "PastDue"
  value?: Maybe<Scalars["Float"]>
}

export type IPayment = {
  __typename?: "Payment"
  date?: Maybe<Scalars["AWSDateTime"]>
  id: Scalars["ID"]
  paymentEventId?: Maybe<Scalars["ID"]>
  paymentInfo?: Maybe<IPaymentInfo>
  processorPaymentId?: Maybe<Scalars["String"]>
  recurringPaymentId?: Maybe<Scalars["ID"]>
  responseCode?: Maybe<Scalars["String"]>
  responseEnum?: Maybe<IResponseEnum>
  responseMessage?: Maybe<Scalars["String"]>
  responseProcessor?: Maybe<Scalars["String"]>
  status?: Maybe<IPaymentStatus>
}

export type IPaymentInfo = {
  __typename?: "PaymentInfo"
  amount?: Maybe<Scalars["String"]>
  applicationId?: Maybe<Scalars["ID"]>
  authorizingIpAddress?: Maybe<Scalars["String"]>
  authorizingMessage?: Maybe<Scalars["String"]>
  feeAmount?: Maybe<Scalars["String"]>
  leaseId?: Maybe<Scalars["ID"]>
  ownerId?: Maybe<Scalars["ID"]>
  paymentId?: Maybe<Scalars["ID"]>
  paymentMethodId?: Maybe<Scalars["ID"]>
  paymentMethodName?: Maybe<Scalars["String"]>
  paymentMethodType?: Maybe<IPaymentMethodType>
  propertyId?: Maybe<Scalars["ID"]>
  totalAmount?: Maybe<Scalars["String"]>
}

export type IPaymentInfoInput = {
  amount?: InputMaybe<Scalars["String"]>
  applicationId?: InputMaybe<Scalars["ID"]>
  authorizingIpAddress?: InputMaybe<Scalars["String"]>
  authorizingMessage?: InputMaybe<Scalars["String"]>
  feeAmount?: InputMaybe<Scalars["String"]>
  leaseId?: InputMaybe<Scalars["ID"]>
  ownerId?: InputMaybe<Scalars["ID"]>
  paymentMethodId?: InputMaybe<Scalars["ID"]>
  paymentMethodName?: InputMaybe<Scalars["String"]>
  paymentMethodType?: InputMaybe<IPaymentMethodType>
  propertyId?: InputMaybe<Scalars["ID"]>
  totalAmount?: InputMaybe<Scalars["String"]>
}

export type IPaymentMethod = {
  __typename?: "PaymentMethod"
  accountHolderName?: Maybe<Scalars["String"]>
  address?: Maybe<Scalars["String"]>
  checkBounced?: Maybe<Scalars["Boolean"]>
  city?: Maybe<Scalars["String"]>
  companyName?: Maybe<Scalars["String"]>
  country?: Maybe<ICountry>
  creditCard?: Maybe<ICreditCard>
  eCheck?: Maybe<IECheck>
  id: Scalars["ID"]
  name?: Maybe<Scalars["String"]>
  paymentMethodType?: Maybe<IPaymentMethodType>
  phone?: Maybe<Scalars["String"]>
  postalCode?: Maybe<Scalars["String"]>
  processorAccountId?: Maybe<Scalars["String"]>
  processorPaymentMethodId?: Maybe<Scalars["String"]>
  state?: Maybe<Scalars["String"]>
  userId?: Maybe<Scalars["ID"]>
}

export enum IPaymentMethodType {
  CreditCard = "CREDIT_CARD",
  Echeck = "ECHECK",
}

export type IPaymentMethodWhereInput = {
  id?: InputMaybe<Scalars["ID"]>
  returnAll: Scalars["Boolean"]
  userId?: InputMaybe<Scalars["ID"]>
}

export enum IPaymentStatus {
  Accepted = "ACCEPTED",
  Accounted = "ACCOUNTED",
  Declined = "DECLINED",
  Error = "ERROR",
  Pending = "PENDING",
}

export type IPaymentWhereInput = {
  amountMax?: InputMaybe<Scalars["String"]>
  amountMin?: InputMaybe<Scalars["String"]>
  applicationId?: InputMaybe<Scalars["ID"]>
  authorizingIpAddress?: InputMaybe<Scalars["String"]>
  dateMax?: InputMaybe<Scalars["AWSDate"]>
  dateMin?: InputMaybe<Scalars["AWSDate"]>
  feeMax?: InputMaybe<Scalars["String"]>
  feeMin?: InputMaybe<Scalars["String"]>
  id?: InputMaybe<Scalars["ID"]>
  leaseId?: InputMaybe<Scalars["ID"]>
  ownerId?: InputMaybe<Scalars["ID"]>
  paymentMethodName?: InputMaybe<Scalars["String"]>
  paymentMethodType?: InputMaybe<IPaymentMethodType>
  propertyId?: InputMaybe<Scalars["ID"]>
  recurringPayment?: InputMaybe<Scalars["Boolean"]>
  returnAll: Scalars["Boolean"]
  totalAmountMax?: InputMaybe<Scalars["String"]>
  totalAmountMin?: InputMaybe<Scalars["String"]>
}

export type IPetPolicy = {
  __typename?: "PetPolicy"
  additionalRestrictions?: Maybe<Scalars["String"]>
  allowed: Scalars["Boolean"]
  comment?: Maybe<Scalars["String"]>
  communityId: Scalars["ID"]
  deposit?: Maybe<Scalars["Float"]>
  dnaFee?: Maybe<Scalars["Float"]>
  fee?: Maybe<Scalars["Float"]>
  id: Scalars["ID"]
  maxAllowed?: Maybe<Scalars["Int"]>
  maxWeight?: Maybe<Scalars["Int"]>
  petType: IPetType
  rent?: Maybe<Scalars["Float"]>
}

export type IPetPolicyCommunityInput = {
  additionalRestrictions?: InputMaybe<Scalars["String"]>
  allowed: Scalars["Boolean"]
  comment?: InputMaybe<Scalars["String"]>
  deposit?: InputMaybe<Scalars["Float"]>
  dnaFee?: InputMaybe<Scalars["Float"]>
  fee?: InputMaybe<Scalars["Float"]>
  maxAllowed?: InputMaybe<Scalars["Int"]>
  maxWeight?: InputMaybe<Scalars["Int"]>
  petType: IPetType
  rent?: InputMaybe<Scalars["Float"]>
}

export type IPetPolicyInput = {
  additionalRestrictions?: InputMaybe<Scalars["String"]>
  allowed: Scalars["Boolean"]
  comment?: InputMaybe<Scalars["String"]>
  communityId: Scalars["ID"]
  deposit?: InputMaybe<Scalars["Float"]>
  dnaFee?: InputMaybe<Scalars["Float"]>
  fee?: InputMaybe<Scalars["Float"]>
  maxAllowed?: InputMaybe<Scalars["Int"]>
  maxWeight?: InputMaybe<Scalars["Int"]>
  petType: IPetType
  rent?: InputMaybe<Scalars["Float"]>
}

export enum IPetType {
  Cat = "CAT",
  Dog = "DOG",
}

export type IPrintParkingViolationLettersWhereInput = {
  ids: Array<Scalars["ID"]>
}

export type IPrintUrl = {
  __typename?: "PrintUrl"
  s3Url: Scalars["String"]
}

export type IPromotion = {
  __typename?: "Promotion"
  description?: Maybe<Scalars["String"]>
  endDate?: Maybe<Scalars["String"]>
  id: Scalars["ID"]
  startDate?: Maybe<Scalars["String"]>
  value?: Maybe<Scalars["Float"]>
}

export type IPromotionCommunityInput = {
  description?: InputMaybe<Scalars["String"]>
  endDate?: InputMaybe<Scalars["String"]>
  startDate?: InputMaybe<Scalars["String"]>
  value?: InputMaybe<Scalars["Float"]>
}

export type IPromotionInput = {
  communityId: Scalars["ID"]
  description?: InputMaybe<Scalars["String"]>
  endDate?: InputMaybe<Scalars["AWSDateTime"]>
  startDate?: InputMaybe<Scalars["AWSDateTime"]>
  value?: InputMaybe<Scalars["Float"]>
}

export type IPropertiesWhereInput = {
  id?: InputMaybe<Scalars["ID"]>
  name?: InputMaybe<Scalars["String"]>
}

export type IProperty = {
  __typename?: "Property"
  active: Scalars["Boolean"]
  addressLine1?: Maybe<Scalars["String"]>
  addressLine2?: Maybe<Scalars["String"]>
  assessorsParcelNumber?: Maybe<Scalars["String"]>
  city?: Maybe<Scalars["String"]>
  community?: Maybe<ICommunity>
  country?: Maybe<Scalars["String"]>
  dateAcquired?: Maybe<Scalars["AWSDateTime"]>
  dateBuilt?: Maybe<Scalars["AWSDateTime"]>
  dateRemodeled?: Maybe<Scalars["AWSDateTime"]>
  dateRoofReplaced?: Maybe<Scalars["AWSDateTime"]>
  fireSprinklered?: Maybe<Scalars["Boolean"]>
  id: Scalars["ID"]
  identificationCode?: Maybe<Scalars["String"]>
  landAcres?: Maybe<Scalars["Float"]>
  latitude?: Maybe<Scalars["Float"]>
  longitude?: Maybe<Scalars["Float"]>
  marketValue?: Maybe<Scalars["Float"]>
  name: Scalars["String"]
  originalCost?: Maybe<Scalars["Float"]>
  owner?: Maybe<IOwner>
  postalCode?: Maybe<Scalars["String"]>
  primaryUnitType?: Maybe<IUnitType>
  squareFeet?: Maybe<Scalars["Int"]>
  state?: Maybe<Scalars["String"]>
  unitCount?: Maybe<Scalars["Int"]>
}

export type IQuery = {
  __typename?: "Query"
  ITAttachments: Array<IItAttachment>
  ITCategories: Array<IItCategory>
  ITServiceRequests: Array<IItServiceRequest>
  amenities: Array<IAmenity>
  applicationAndLeases: IApplicationsAndLeasesOutput
  applicationEmails: Array<Maybe<IApplicationEmail>>
  applicationLeads: Array<Maybe<IApplicationLead>>
  applicationLeases: Array<Maybe<IApplicationLease>>
  applicationPhoneNumbers: Array<IApplicationPhoneNumber>
  applicationUnits: Array<IApplicationUnit>
  applications: Array<IApplication>
  attachments: Array<IAttachment>
  averageTurnoverCosts: Array<IAverageTurnoverCost>
  callAnswerPercentage: ICallAnswerPercentageOutput
  categories: Array<ICategory>
  communities: Array<ICommunity>
  communityAmenities: Array<IPaCommunityAmenity>
  communityDetails: Array<ICommunityDetail>
  contacts: Array<IContact>
  districts: Array<IDistrict>
  dom2Applications: Array<IDom2Application>
  earlyTerminationRates: Array<IEarlyTerminationRate>
  earsEventRecords: Array<IEventRecord>
  employees: Array<IEmployee>
  exposureRisk: Array<IExposureRisk>
  floorPlanAmenities: Array<IPaFloorPlanAmenity>
  floorPlans: Array<IFloorPlan>
  floorplanDetails: Array<IFloorPlanDetail>
  forgottenPasswordInquiries: Array<IForgottenPasswordInquiry>
  gadgetLateServiceRequests: IGadgetLateServiceRequestsOutput
  gadgetServiceRequestCategories: IGadgetServiceRequestCategoriesOutput
  gadgetServiceRequestTurnoverTime: IGadgetServiceRequestTurnoverTimeOutput
  gadgetServiceRequestsCompleted: IGadgetServiceRequestsCompletedOutput
  googleReviews: IGoogleReviews
  healthyUnitInternetPercentage: IHealthyUnitInternetPercentageOutput
  historicalLeads: Array<IHistoricalLead>
  historicalLeases: Array<IHistoricalLease>
  images: Array<IImage>
  leadSources: Array<ILeadSource>
  leadToMoveInTimes: Array<ILeadToMoveInTime>
  leadsAndSources: Array<ILeadAndSource>
  leaseNames: Array<ILeaseName>
  lengthOfStays: Array<ILengthOfStay>
  mailchimpUsers: Array<IMailchimpUser>
  makeReadyUnits: Array<IMakeReadyUnit>
  moveInDates: Array<IMoveInDate>
  moveInsAndOuts: IMoveInsAndOutsOutput
  moveOut: Array<IMoveOut>
  moveOutDates: IMoveOutDates
  occupiedUnits: Array<IOccupiedUnit>
  owners: Array<IOwner>
  parkingSpacePermissions: Array<IParkingSpacePermission>
  parkingViolationLetters: Array<IParkingViolationLetter>
  pastDue: IPastDue
  paymentMethods: Array<IPaymentMethod>
  payments: Array<IPayment>
  printParkingViolationLetters: IPrintUrl
  properties: Array<IProperty>
  recurringPayments: Array<IRecurringPayment>
  regions: Array<IRegion>
  renewalRates: Array<IRenewalRate>
  serviceRequestEmployees: Array<IEmployee>
  serviceRequests: Array<IServiceRequest>
  servicesCompleted: IServicesCompletedOutput
  typicalVacancies: Array<ITypicalVacancy>
  unitAmenities: Array<IPaUnitAmenity>
  unitData: Array<IUnitData>
  unitDetails: Array<IUnitDetail>
  unitStatuses: Array<IPaUnitStatus>
  unitStatusesHistory: Array<IUnitStatusHistory>
  units: Array<IUnit>
  unitsCount: IUnitCount
  users: Array<IUser>
  vacancyPercentage: IVacancy
}

export type IQueryItAttachmentsArgs = {
  limit?: InputMaybe<Scalars["Int"]>
  offset?: InputMaybe<Scalars["Int"]>
  where?: InputMaybe<IItAttachmentsWhereInput>
}

export type IQueryItCategoriesArgs = {
  limit?: InputMaybe<Scalars["Int"]>
  offset?: InputMaybe<Scalars["Int"]>
  where?: InputMaybe<IItCategoriesWhereInput>
}

export type IQueryItServiceRequestsArgs = {
  limit?: InputMaybe<Scalars["Int"]>
  offset?: InputMaybe<Scalars["Int"]>
  where?: InputMaybe<IItServiceRequestsWhereInput>
}

export type IQueryAmenitiesArgs = {
  limit?: InputMaybe<Scalars["Int"]>
  offset?: InputMaybe<Scalars["Int"]>
  where?: InputMaybe<IAmenitiesWhereInput>
}

export type IQueryApplicationAndLeasesArgs = {
  where: ICommunityAndTimeWhereInput
}

export type IQueryApplicationLeadsArgs = {
  limit?: InputMaybe<Scalars["Int"]>
  offset?: InputMaybe<Scalars["Int"]>
  where?: InputMaybe<IApplicationLeadsWhereInput>
}

export type IQueryAttachmentsArgs = {
  limit?: InputMaybe<Scalars["Int"]>
  offset?: InputMaybe<Scalars["Int"]>
  where?: InputMaybe<IAttachmentsWhereInput>
}

export type IQueryCallAnswerPercentageArgs = {
  where: ICommunityAndTimeWhereInput
}

export type IQueryCategoriesArgs = {
  limit?: InputMaybe<Scalars["Int"]>
  offset?: InputMaybe<Scalars["Int"]>
  where?: InputMaybe<ICategoriesWhereInput>
}

export type IQueryCommunitiesArgs = {
  limit?: InputMaybe<Scalars["Int"]>
  offset?: InputMaybe<Scalars["Int"]>
  where?: InputMaybe<ICommunitiesWhereInput>
}

export type IQueryContactsArgs = {
  limit?: InputMaybe<Scalars["Int"]>
  offset?: InputMaybe<Scalars["Int"]>
  where?: InputMaybe<IContactsWhereInput>
}

export type IQueryDistrictsArgs = {
  limit?: InputMaybe<Scalars["Int"]>
  offset?: InputMaybe<Scalars["Int"]>
  where?: InputMaybe<IDistrictsWhereInput>
}

export type IQueryEmployeesArgs = {
  where?: InputMaybe<IQueryEmployeeInput>
}

export type IQueryFloorPlansArgs = {
  limit?: InputMaybe<Scalars["Int"]>
  offset?: InputMaybe<Scalars["Int"]>
  orderBy?: InputMaybe<IFloorPlanOrderBy>
  where?: InputMaybe<IFloorPlansWhereInput>
}

export type IQueryForgottenPasswordInquiriesArgs = {
  where?: InputMaybe<IForgottenPasswordInquiryWhereInput>
}

export type IQueryGadgetLateServiceRequestsArgs = {
  where: IGadgetLateServiceRequestsInput
}

export type IQueryGadgetServiceRequestCategoriesArgs = {
  where: IGadgetServiceRequestCategoriesInput
}

export type IQueryGadgetServiceRequestTurnoverTimeArgs = {
  where: IGadgetServiceRequestTurnoverTimeInput
}

export type IQueryGadgetServiceRequestsCompletedArgs = {
  where: IGadgetServiceRequestsCompletedInput
}

export type IQueryGoogleReviewsArgs = {
  where: IGoogleReviewsWhereInput
}

export type IQueryHealthyUnitInternetPercentageArgs = {
  where: ICommunityWhereInput
}

export type IQueryImagesArgs = {
  limit?: InputMaybe<Scalars["Int"]>
  offset?: InputMaybe<Scalars["Int"]>
  where?: InputMaybe<IImagesWhereInput>
}

export type IQueryLeadSourcesArgs = {
  limit?: InputMaybe<Scalars["Int"]>
  offset?: InputMaybe<Scalars["Int"]>
  where?: InputMaybe<ILeadSourceWhereInput>
}

export type IQueryLeadsAndSourcesArgs = {
  where: ICommunityAndTimeWhereInput
}

export type IQueryLeaseNamesArgs = {
  where?: InputMaybe<ILeaseNamesWhereInput>
}

export type IQueryMailchimpUsersArgs = {
  where?: InputMaybe<IWhereMailchimpUserInput>
}

export type IQueryMakeReadyUnitsArgs = {
  limit?: InputMaybe<Scalars["Int"]>
  offset?: InputMaybe<Scalars["Int"]>
}

export type IQueryMoveInsAndOutsArgs = {
  where: IMoveInsAndOutsInput
}

export type IQueryMoveOutArgs = {
  limit?: InputMaybe<Scalars["Int"]>
  offset?: InputMaybe<Scalars["Int"]>
  where?: InputMaybe<IMoveOutWhereInput>
}

export type IQueryMoveOutDatesArgs = {
  where: IMoveOutDatesWhereInput
}

export type IQueryOwnersArgs = {
  limit?: InputMaybe<Scalars["Int"]>
  offset?: InputMaybe<Scalars["Int"]>
  where?: InputMaybe<IOwnersWhereInput>
}

export type IQueryParkingSpacePermissionsArgs = {
  where?: InputMaybe<IParkingSpacePermissionsWhereInput>
}

export type IQueryParkingViolationLettersArgs = {
  where?: InputMaybe<IParkingViolationLettersWhereInput>
}

export type IQueryPastDueArgs = {
  where: ICommunityWhereInput
}

export type IQueryPaymentMethodsArgs = {
  limit?: InputMaybe<Scalars["Int"]>
  offset?: InputMaybe<Scalars["Int"]>
  where?: InputMaybe<IPaymentMethodWhereInput>
}

export type IQueryPaymentsArgs = {
  limit?: InputMaybe<Scalars["Int"]>
  offset?: InputMaybe<Scalars["Int"]>
  where?: InputMaybe<IPaymentWhereInput>
}

export type IQueryPrintParkingViolationLettersArgs = {
  where?: InputMaybe<IPrintParkingViolationLettersWhereInput>
}

export type IQueryPropertiesArgs = {
  limit?: InputMaybe<Scalars["Int"]>
  offset?: InputMaybe<Scalars["Int"]>
  where?: InputMaybe<IPropertiesWhereInput>
}

export type IQueryRecurringPaymentsArgs = {
  limit?: InputMaybe<Scalars["Int"]>
  offset?: InputMaybe<Scalars["Int"]>
  where?: InputMaybe<IRecurringPaymentWhereInput>
}

export type IQueryRegionsArgs = {
  limit?: InputMaybe<Scalars["Int"]>
  offset?: InputMaybe<Scalars["Int"]>
  where?: InputMaybe<IRegionsWhereInput>
}

export type IQueryServiceRequestEmployeesArgs = {
  limit?: InputMaybe<Scalars["Int"]>
  offset?: InputMaybe<Scalars["Int"]>
  where?: InputMaybe<IEmployeesWhereInput>
}

export type IQueryServiceRequestsArgs = {
  limit?: InputMaybe<Scalars["Int"]>
  offset?: InputMaybe<Scalars["Int"]>
  where?: InputMaybe<IServiceRequestsWhereInput>
}

export type IQueryServicesCompletedArgs = {
  where: ICommunityAndTimeWhereInput
}

export type IQueryUnitStatusesHistoryArgs = {
  limit?: InputMaybe<Scalars["Int"]>
  offset?: InputMaybe<Scalars["Int"]>
  where?: InputMaybe<IUnitStatusHistoryWhereInput>
}

export type IQueryUnitsArgs = {
  limit?: InputMaybe<Scalars["Int"]>
  offset?: InputMaybe<Scalars["Int"]>
  orderBy?: InputMaybe<IUnitOrderBy>
  where?: InputMaybe<IUnitsWhereInput>
}

export type IQueryUnitsCountArgs = {
  where: ICommunityWhereInput
}

export type IQueryUsersArgs = {
  limit?: InputMaybe<Scalars["Int"]>
  offset?: InputMaybe<Scalars["Int"]>
  where?: InputMaybe<IUserWhereInput>
}

export type IQueryVacancyPercentageArgs = {
  where: IVacancyWhereInput
}

export type IQueryEmployeeInput = {
  id?: InputMaybe<Scalars["ID"]>
  isDeleted?: InputMaybe<Scalars["Boolean"]>
  isIT?: InputMaybe<Scalars["Boolean"]>
  isMaintenance?: InputMaybe<Scalars["Boolean"]>
}

export type IRecurringPayment = {
  __typename?: "RecurringPayment"
  amountCalculationType?: Maybe<IAmountCalculationType>
  id: Scalars["ID"]
  lastPaymentDate?: Maybe<Scalars["AWSDate"]>
  paymentInfo?: Maybe<IPaymentInfo>
  userId?: Maybe<Scalars["ID"]>
}

export type IRecurringPaymentWhereInput = {
  amountCalculationType?: InputMaybe<IAmountCalculationType>
  amountMax?: InputMaybe<Scalars["String"]>
  amountMin?: InputMaybe<Scalars["String"]>
  applicationId?: InputMaybe<Scalars["ID"]>
  authorizingIpAddress?: InputMaybe<Scalars["String"]>
  feeMax?: InputMaybe<Scalars["String"]>
  feeMin?: InputMaybe<Scalars["String"]>
  id?: InputMaybe<Scalars["ID"]>
  leaseId?: InputMaybe<Scalars["ID"]>
  ownerId?: InputMaybe<Scalars["ID"]>
  paymentMethodId?: InputMaybe<Scalars["ID"]>
  propertyId?: InputMaybe<Scalars["ID"]>
  returnAll: Scalars["Boolean"]
  totalAmountMax?: InputMaybe<Scalars["String"]>
  totalAmountMin?: InputMaybe<Scalars["String"]>
}

export type IRegion = {
  __typename?: "Region"
  id: Scalars["ID"]
  name: Scalars["String"]
  urlSlug?: Maybe<Scalars["String"]>
}

export type IRegionsWhereInput = {
  id?: InputMaybe<Scalars["ID"]>
}

export type IRenewalRate = {
  __typename?: "RenewalRate"
  bathrooms?: Maybe<Scalars["Float"]>
  bedrooms?: Maybe<Scalars["Float"]>
  community?: Maybe<Scalars["String"]>
  followingLeaseTermStartDate?: Maybe<Scalars["AWSDateTime"]>
  moveInDate?: Maybe<Scalars["AWSDateTime"]>
  moveOutDate?: Maybe<Scalars["AWSDateTime"]>
  termEndDate?: Maybe<Scalars["AWSDateTime"]>
  termStartDate?: Maybe<Scalars["AWSDateTime"]>
}

export enum IResponseEnum {
  DeclinedCard = "DECLINED_CARD",
  InvalidAccountNumber = "INVALID_ACCOUNT_NUMBER",
  InvalidCard = "INVALID_CARD",
  InvalidExpirationDate = "INVALID_EXPIRATION_DATE",
  InvalidRoutingNumber = "INVALID_ROUTING_NUMBER",
  InvalidTransaction = "INVALID_TRANSACTION",
  NotSufficientFunds = "NOT_SUFFICIENT_FUNDS",
  OtherError = "OTHER_ERROR",
  RestrictedCard = "RESTRICTED_CARD",
}

export type IReview = {
  __typename?: "Review"
  author_name?: Maybe<Scalars["String"]>
  author_url?: Maybe<Scalars["String"]>
  language?: Maybe<Scalars["String"]>
  profile_photo_url?: Maybe<Scalars["String"]>
  rating?: Maybe<Scalars["Float"]>
  relative_time_description?: Maybe<Scalars["String"]>
  text?: Maybe<Scalars["String"]>
  time?: Maybe<Scalars["Float"]>
}

export type ISendCampaignInput = {
  campaignId: Scalars["Int"]
  scheduleDate?: InputMaybe<Scalars["AWSDateTime"]>
  tags: Array<Scalars["String"]>
}

export type ISendNewCampaignInput = {
  previewText?: InputMaybe<Scalars["String"]>
  replyEmail: Scalars["AWSEmail"]
  scheduleDate?: InputMaybe<Scalars["AWSDateTime"]>
  subjectLine: Scalars["String"]
  tags: Array<Scalars["String"]>
  templateBody: Scalars["String"]
  title: Scalars["String"]
  type: ICampaignType
  userName: Scalars["String"]
}

export type IServiceRequest = {
  __typename?: "ServiceRequest"
  attachments?: Maybe<Array<Maybe<IAttachment>>>
  callbackPhoneNumber?: Maybe<Scalars["AWSPhone"]>
  category?: Maybe<ICategory>
  commonArea?: Maybe<Scalars["Boolean"]>
  completedDate?: Maybe<Scalars["AWSDateTime"]>
  createdDate: Scalars["AWSDateTime"]
  description: Scalars["String"]
  employee?: Maybe<IEmployee>
  id: Scalars["ID"]
  notes?: Maybe<Scalars["String"]>
  onCall?: Maybe<Scalars["Boolean"]>
  permissionEndDate?: Maybe<Scalars["AWSDateTime"]>
  permissionObtained?: Maybe<Scalars["Boolean"]>
  permissionStartDate?: Maybe<Scalars["AWSDateTime"]>
  propertyId: Scalars["ID"]
  propertyName: Scalars["String"]
  status?: Maybe<IServiceRequestStatus>
  submitterName?: Maybe<Scalars["String"]>
  submitterUserId: Scalars["ID"]
  unitId?: Maybe<Scalars["ID"]>
  unitName?: Maybe<Scalars["String"]>
}

export enum IServiceRequestStatus {
  Assigned = "ASSIGNED",
  Cancelled = "CANCELLED",
  Completed = "COMPLETED",
  InProgress = "IN_PROGRESS",
  OnHold = "ON_HOLD",
  Opened = "OPENED",
  Reopened = "REOPENED",
  TemporarilyFixed = "TEMPORARILY_FIXED",
  WaitingForContractor = "WAITING_FOR_CONTRACTOR",
  WaitingForParts = "WAITING_FOR_PARTS",
  WaitingOnWeather = "WAITING_ON_WEATHER",
}

export type IServiceRequestsWhereInput = {
  completedDateCeiling?: InputMaybe<Scalars["AWSDateTime"]>
  completedDateFloor?: InputMaybe<Scalars["AWSDateTime"]>
  createdDateCeiling?: InputMaybe<Scalars["AWSDateTime"]>
  createdDateFloor?: InputMaybe<Scalars["AWSDateTime"]>
  description?: InputMaybe<Scalars["String"]>
  id?: InputMaybe<Scalars["ID"]>
  statuses?: InputMaybe<Array<InputMaybe<IServiceRequestStatus>>>
  submitterUserId?: InputMaybe<Scalars["ID"]>
  unitId?: InputMaybe<Scalars["ID"]>
}

export type IServicesCompletedOutput = {
  __typename?: "ServicesCompletedOutput"
  completed: Scalars["Int"]
  percent?: Maybe<Scalars["Float"]>
  total: Scalars["Int"]
}

export type IShortCommunity = {
  __typename?: "ShortCommunity"
  id: Scalars["String"]
  name: Scalars["String"]
}

export type IShortFloorPlan = {
  __typename?: "ShortFloorPlan"
  bathrooms?: Maybe<Scalars["Float"]>
  bedrooms?: Maybe<Scalars["Float"]>
  community?: Maybe<IShortCommunity>
  dens?: Maybe<Scalars["Float"]>
  id: Scalars["String"]
  name: Scalars["String"]
  promotionalEndDate?: Maybe<Scalars["AWSDateTime"]>
  promotionalMaxLeaseTerm?: Maybe<Scalars["Int"]>
  promotionalMessage?: Maybe<Scalars["String"]>
  promotionalMinLeaseTerm?: Maybe<Scalars["Int"]>
  promotionalMonthsDiscount?: Maybe<Scalars["Float"]>
  promotionalPriceDiscount?: Maybe<Scalars["Float"]>
  promotionalStartDate?: Maybe<Scalars["AWSDateTime"]>
  terms?: Maybe<Array<ITermFee>>
}

export type IShortProperty = {
  __typename?: "ShortProperty"
  id: Scalars["String"]
  name: Scalars["String"]
}

export type ISignS3RequestInput = {
  filename: Scalars["String"]
  filetype: Scalars["String"]
  folder: Scalars["String"]
  operation: Scalars["String"]
}

export type ISignS3RequestOutput = {
  __typename?: "SignS3RequestOutput"
  s3FileURL?: Maybe<Scalars["String"]>
  signedURL?: Maybe<Scalars["String"]>
}

export type ISocialMedia = {
  __typename?: "SocialMedia"
  communityId: Scalars["ID"]
  id: Scalars["ID"]
  name: ISocialMediaName
  url: Scalars["String"]
}

export type ISocialMediaCommunityInput = {
  name: ISocialMediaName
  url: Scalars["String"]
}

export type ISocialMediaInput = {
  communityId: Scalars["ID"]
  name: ISocialMediaName
  url: Scalars["String"]
}

export enum ISocialMediaName {
  Facebook = "FACEBOOK",
  GooglePlus = "GOOGLE_PLUS",
  Yelp = "YELP",
}

export enum ISort {
  Asc = "ASC",
  Desc = "DESC",
}

export enum IStatus {
  CompanyUsed = "COMPANY_USED",
  Damaged = "DAMAGED",
  InRenovation = "IN_RENOVATION",
  Occupied = "OCCUPIED",
  Turnover = "TURNOVER",
  Unmanaged = "UNMANAGED",
  Unrentable = "UNRENTABLE",
  Vacant = "VACANT",
}

export enum ITagEnum {
  Floorplan = "FLOORPLAN",
  Logo = "LOGO",
  Other = "OTHER",
  Photo = "PHOTO",
  Video = "VIDEO",
}

export type ITermFee = {
  __typename?: "TermFee"
  additionalRent: Scalars["Float"]
  floorPlanId?: Maybe<Scalars["ID"]>
  id: Scalars["ID"]
  minimumMonths: Scalars["Int"]
}

export type ITermFeeInput = {
  additionalRent: Scalars["Float"]
  minimumMonths: Scalars["Int"]
}

export enum ITourAccessType {
  Key = "KEY",
  PhoneEntry = "PHONE_ENTRY",
}

export type ITypicalVacancy = {
  __typename?: "TypicalVacancy"
  bathrooms?: Maybe<Scalars["Float"]>
  bedrooms?: Maybe<Scalars["Float"]>
  community?: Maybe<Scalars["String"]>
  daysVacant?: Maybe<Scalars["Float"]>
  movedInDate?: Maybe<Scalars["AWSDateTime"]>
  vacatedDate?: Maybe<Scalars["AWSDateTime"]>
}

export type IUnit = {
  __typename?: "Unit"
  addressLine1?: Maybe<Scalars["String"]>
  addressLine2?: Maybe<Scalars["String"]>
  amenities: Array<IUnitAmenity>
  assessorsParcelNumber?: Maybe<Scalars["String"]>
  availabilityNotes?: Maybe<Scalars["String"]>
  availableDate?: Maybe<Scalars["AWSDateTime"]>
  city?: Maybe<Scalars["String"]>
  country?: Maybe<Scalars["String"]>
  currentRent?: Maybe<Scalars["Float"]>
  currentStatus?: Maybe<IUnitStatus>
  description?: Maybe<Scalars["String"]>
  floor?: Maybe<Scalars["Int"]>
  floorPlan?: Maybe<IShortFloorPlan>
  hasWasher?: Maybe<Scalars["Boolean"]>
  id: Scalars["ID"]
  livableUnit: Scalars["Boolean"]
  managedStartDate?: Maybe<Scalars["AWSDateTime"]>
  moveInDate?: Maybe<Scalars["AWSDateTime"]>
  moveOutDate?: Maybe<Scalars["AWSDateTime"]>
  name: Scalars["String"]
  notes?: Maybe<Scalars["String"]>
  postalCode?: Maybe<Scalars["String"]>
  property?: Maybe<IShortProperty>
  releaseDate?: Maybe<Scalars["AWSDateTime"]>
  rents: Array<IUnitRentHistory>
  squareFeet?: Maybe<Scalars["Int"]>
  stairwell?: Maybe<Scalars["Int"]>
  state?: Maybe<Scalars["String"]>
  statuses: Array<IUnitStatusHistory>
  unitNumber?: Maybe<Scalars["String"]>
  unitType: IUnitType
  vacantDate?: Maybe<Scalars["AWSDateTime"]>
}

export type IUnitAmenity = IIAmenity & {
  __typename?: "UnitAmenity"
  amenityGroup?: Maybe<IAmenityGroup>
  amenityType?: Maybe<IAmenityType>
  id: Scalars["ID"]
  mitsName?: Maybe<IMitsName>
  name?: Maybe<Scalars["String"]>
  unitId: Scalars["ID"]
}

export type IUnitCount = {
  __typename?: "UnitCount"
  value?: Maybe<Scalars["Float"]>
}

export type IUnitData = {
  __typename?: "UnitData"
  SQFT?: Maybe<Scalars["Float"]>
  bathrooms?: Maybe<Scalars["Float"]>
  bedrooms?: Maybe<Scalars["Float"]>
  community?: Maybe<Scalars["String"]>
  floor?: Maybe<Scalars["Float"]>
  floorPlan?: Maybe<Scalars["String"]>
  property?: Maybe<Scalars["String"]>
  unitNumber?: Maybe<Scalars["Float"]>
}

export type IUnitDetail = {
  __typename?: "UnitDetail"
  bathrooms?: Maybe<Scalars["Float"]>
  bedrooms?: Maybe<Scalars["Float"]>
  communityName?: Maybe<Scalars["String"]>
  currentPrice?: Maybe<Scalars["Float"]>
  eastFacing?: Maybe<Scalars["Boolean"]>
  floor?: Maybe<Scalars["Int"]>
  floorPlanName?: Maybe<Scalars["String"]>
  northFacing?: Maybe<Scalars["Boolean"]>
  northeastFacing?: Maybe<Scalars["Boolean"]>
  northwestFacing?: Maybe<Scalars["Boolean"]>
  parkingView?: Maybe<Scalars["Boolean"]>
  poolView?: Maybe<Scalars["Boolean"]>
  preferredView?: Maybe<Scalars["Boolean"]>
  priceStartDate?: Maybe<Scalars["AWSDateTime"]>
  smart?: Maybe<Scalars["Boolean"]>
  southFacing?: Maybe<Scalars["Boolean"]>
  southeastFacing?: Maybe<Scalars["Boolean"]>
  southwestFacing?: Maybe<Scalars["Boolean"]>
  squareFeet?: Maybe<Scalars["Float"]>
  standardView?: Maybe<Scalars["Boolean"]>
  streetView?: Maybe<Scalars["Boolean"]>
  trailView?: Maybe<Scalars["Boolean"]>
  unitId?: Maybe<Scalars["String"]>
  unitName?: Maybe<Scalars["String"]>
  westFacing?: Maybe<Scalars["Boolean"]>
}

export type IUnitOrderBy = {
  availableDate?: InputMaybe<ISort>
  bathrooms?: InputMaybe<ISort>
  bedrooms?: InputMaybe<ISort>
  communityName?: InputMaybe<ISort>
  currentRent?: InputMaybe<ISort>
  currentStatus?: InputMaybe<ISort>
  floor?: InputMaybe<ISort>
  managedStartDate?: InputMaybe<ISort>
  moveInDate?: InputMaybe<ISort>
  moveOutDate?: InputMaybe<ISort>
  name?: InputMaybe<ISort>
  propertyName?: InputMaybe<ISort>
  squareFeet?: InputMaybe<ISort>
  unitType?: InputMaybe<ISort>
}

export type IUnitPrice = {
  __typename?: "UnitPrice"
  endDate?: Maybe<Scalars["String"]>
  id: Scalars["ID"]
  price: Scalars["Float"]
  startDate?: Maybe<Scalars["String"]>
  unitId: Scalars["ID"]
}

export type IUnitRentHistory = {
  __typename?: "UnitRentHistory"
  endDate?: Maybe<Scalars["AWSDateTime"]>
  id: Scalars["ID"]
  price?: Maybe<Scalars["Float"]>
  startDate?: Maybe<Scalars["AWSDateTime"]>
  unitId: Scalars["ID"]
}

export type IUnitRentHistoryInput = {
  endDate?: InputMaybe<Scalars["String"]>
  id: Scalars["ID"]
  price?: InputMaybe<Scalars["Float"]>
  startDate?: InputMaybe<Scalars["String"]>
  unitId: Scalars["ID"]
}

export enum IUnitStatus {
  CompanyUsed = "COMPANY_USED",
  Damaged = "DAMAGED",
  InRenovation = "IN_RENOVATION",
  Occupied = "OCCUPIED",
  Turnover = "TURNOVER",
  Unmanaged = "UNMANAGED",
  Unrentable = "UNRENTABLE",
  Vacant = "VACANT",
}

export type IUnitStatusHistory = {
  __typename?: "UnitStatusHistory"
  endDate?: Maybe<Scalars["AWSDateTime"]>
  id: Scalars["ID"]
  notes?: Maybe<Scalars["String"]>
  releaseDate?: Maybe<Scalars["AWSDateTime"]>
  startDate?: Maybe<Scalars["AWSDateTime"]>
  status?: Maybe<IUnitStatus>
  unitId: Scalars["ID"]
}

export type IUnitStatusHistoryWhereInput = {
  id?: InputMaybe<Scalars["ID"]>
  notStatuses?: InputMaybe<Array<InputMaybe<IUnitStatus>>>
  statuses?: InputMaybe<Array<InputMaybe<IUnitStatus>>>
  unitId?: InputMaybe<Scalars["ID"]>
}

export enum IUnitType {
  Amenity = "AMENITY",
  Apartment = "APARTMENT",
  Commercial = "COMMERCIAL",
  Garage = "GARAGE",
  House = "HOUSE",
  OutdoorParking = "OUTDOOR_PARKING",
  Storage = "STORAGE",
  Townhome = "TOWNHOME",
  UndergroundParking = "UNDERGROUND_PARKING",
  Utility = "UTILITY",
}

export type IUnitsWhereInput = {
  amenityIds?: InputMaybe<Array<Scalars["ID"]>>
  availableDateMax?: InputMaybe<Scalars["AWSDateTime"]>
  availableDateMin?: InputMaybe<Scalars["AWSDateTime"]>
  availableUnits?: InputMaybe<IAvailableUnits>
  bathroomsMax?: InputMaybe<Scalars["Float"]>
  bathroomsMin?: InputMaybe<Scalars["Float"]>
  bedroomsMax?: InputMaybe<Scalars["Float"]>
  bedroomsMin?: InputMaybe<Scalars["Float"]>
  communityIds?: InputMaybe<Array<Scalars["ID"]>>
  communityName?: InputMaybe<Scalars["String"]>
  currentRentMax?: InputMaybe<Scalars["Float"]>
  currentRentMin?: InputMaybe<Scalars["Float"]>
  currentStatus?: InputMaybe<Array<IUnitStatus>>
  floorMax?: InputMaybe<Scalars["Int"]>
  floorMin?: InputMaybe<Scalars["Int"]>
  floorPlanIds?: InputMaybe<Array<Scalars["ID"]>>
  id?: InputMaybe<Scalars["ID"]>
  livableUnit?: InputMaybe<Scalars["Boolean"]>
  managedStartDateMax?: InputMaybe<Scalars["AWSDateTime"]>
  managedStartDateMin?: InputMaybe<Scalars["AWSDateTime"]>
  maxVacantDate?: InputMaybe<Scalars["AWSDateTime"]>
  moveInDateMax?: InputMaybe<Scalars["AWSDateTime"]>
  moveInDateMin?: InputMaybe<Scalars["AWSDateTime"]>
  moveOutDateMax?: InputMaybe<Scalars["AWSDateTime"]>
  moveOutDateMin?: InputMaybe<Scalars["AWSDateTime"]>
  name?: InputMaybe<Scalars["String"]>
  notes?: InputMaybe<Scalars["String"]>
  ownerIds?: InputMaybe<Array<Scalars["ID"]>>
  propertyIds?: InputMaybe<Array<Scalars["ID"]>>
  propertyName?: InputMaybe<Scalars["String"]>
  squareFeetMax?: InputMaybe<Scalars["Int"]>
  squareFeetMin?: InputMaybe<Scalars["Int"]>
  unitTypes?: InputMaybe<Array<IUnitType>>
}

export type IUpdateAmenityInput = {
  amenityGroup?: InputMaybe<Scalars["String"]>
  amenityType?: InputMaybe<Scalars["String"]>
  id: Scalars["ID"]
  mitsName?: InputMaybe<Scalars["String"]>
  name?: InputMaybe<Scalars["String"]>
}

export type IUpdateCategoryInput = {
  active?: InputMaybe<Scalars["Boolean"]>
  common?: InputMaybe<Scalars["Boolean"]>
  id: Scalars["ID"]
  name?: InputMaybe<Scalars["String"]>
  unit?: InputMaybe<Scalars["Boolean"]>
}

export type IUpdateCommunityInput = {
  additionalDescription?: InputMaybe<Scalars["String"]>
  adminFee?: InputMaybe<Scalars["Float"]>
  allowOnlineApplications: Scalars["Boolean"]
  applicationFee?: InputMaybe<Scalars["Float"]>
  communityVideoUrl?: InputMaybe<Scalars["String"]>
  districtId?: InputMaybe<Scalars["ID"]>
  drivingDirections?: InputMaybe<Scalars["String"]>
  emailFragment?: InputMaybe<Scalars["String"]>
  id: Scalars["ID"]
  longDescription?: InputMaybe<Scalars["String"]>
  name: Scalars["String"]
  owned: Scalars["Boolean"]
  primaryPropertyId?: InputMaybe<Scalars["ID"]>
  promotionalMessage?: InputMaybe<Scalars["String"]>
  routeToEmailAddress?: InputMaybe<Scalars["String"]>
  shortDescription?: InputMaybe<Scalars["String"]>
  showOnMarketingSites: Scalars["Boolean"]
  showOnWebsite: Scalars["Boolean"]
  squareFeet?: InputMaybe<Scalars["Float"]>
  tourAccessType?: InputMaybe<ITourAccessType>
  unfinishedSquareFeet?: InputMaybe<Scalars["Float"]>
  uriSlug?: InputMaybe<Scalars["String"]>
  websiteDescription?: InputMaybe<Scalars["String"]>
  websitePromotionalMessage?: InputMaybe<Scalars["String"]>
}

export type IUpdateDistrictInput = {
  displayEmailAddress: Scalars["AWSEmail"]
  id: Scalars["ID"]
  name: Scalars["String"]
  region: Scalars["ID"]
}

export type IUpdateDistrictLeadSourceFeeInput = {
  endDate?: InputMaybe<Scalars["String"]>
  id?: InputMaybe<Scalars["ID"]>
  monthlyFee?: InputMaybe<Scalars["Float"]>
  name?: InputMaybe<Scalars["String"]>
  startDate?: InputMaybe<Scalars["String"]>
}

export type IUpdateDistrictLeadSourceInput = {
  district: IUsherDistrictDistrictLeadSourceInput
  fees?: InputMaybe<Array<InputMaybe<IUpdateDistrictLeadSourceFeeInput>>>
  id?: InputMaybe<Scalars["ID"]>
  phoneNumber?: InputMaybe<Scalars["String"]>
}

export type IUpdateEmployeeInput = {
  id: Scalars["ID"]
  isIT?: InputMaybe<Scalars["Boolean"]>
  isMaintenance?: InputMaybe<Scalars["Boolean"]>
  name?: InputMaybe<Scalars["String"]>
}

export type IUpdateFloorPlanInput = {
  bathrooms?: InputMaybe<Scalars["Float"]>
  bedrooms?: InputMaybe<Scalars["Float"]>
  communityId?: InputMaybe<Scalars["ID"]>
  dens?: InputMaybe<Scalars["Float"]>
  description?: InputMaybe<Scalars["String"]>
  id: Scalars["ID"]
  matterportUrl?: InputMaybe<Scalars["String"]>
  minimumDeposit?: InputMaybe<Scalars["Float"]>
  name?: InputMaybe<Scalars["String"]>
  promotionalEndDate?: InputMaybe<Scalars["AWSDateTime"]>
  promotionalMaxLeaseTerm?: InputMaybe<Scalars["Float"]>
  promotionalMessage?: InputMaybe<Scalars["String"]>
  promotionalMinLeaseTerm?: InputMaybe<Scalars["Float"]>
  promotionalMonthsDiscount?: InputMaybe<Scalars["Float"]>
  promotionalPriceDiscount?: InputMaybe<Scalars["Float"]>
  promotionalStartDate?: InputMaybe<Scalars["AWSDateTime"]>
  residentScreeningRequired?: InputMaybe<Scalars["Boolean"]>
  urlSlug?: InputMaybe<Scalars["String"]>
  youtubeUrl?: InputMaybe<Scalars["String"]>
}

export type IUpdateFloorPlanLeaseTermInput = {
  additionalRent: Scalars["Float"]
  id: Scalars["ID"]
  minimumMonths: Scalars["Int"]
}

export type IUpdateItCategoryInput = {
  active?: InputMaybe<Scalars["Boolean"]>
  id: Scalars["ID"]
  name?: InputMaybe<Scalars["String"]>
}

export type IUpdateItServiceRequestInput = {
  categoryId?: InputMaybe<Scalars["ID"]>
  completedDate?: InputMaybe<Scalars["AWSDateTime"]>
  description?: InputMaybe<Scalars["String"]>
  employeeId?: InputMaybe<Scalars["ID"]>
  id: Scalars["ID"]
  notes?: InputMaybe<Scalars["String"]>
  status?: InputMaybe<IServiceRequestStatus>
}

export type IUpdateImageInput = {
  altText?: InputMaybe<Scalars["String"]>
  caption?: InputMaybe<Scalars["String"]>
  displayOrder?: InputMaybe<Scalars["Int"]>
  id: Scalars["ID"]
  tag?: InputMaybe<ITagEnum>
}

export type IUpdateLeadSourceInput = {
  comment?: InputMaybe<Scalars["String"]>
  directory?: InputMaybe<Scalars["String"]>
  districtLeadSources?: InputMaybe<Array<InputMaybe<IUpdateDistrictLeadSourceInput>>>
  fileProtocol?: InputMaybe<Scalars["String"]>
  host?: InputMaybe<Scalars["String"]>
  id: Scalars["ID"]
  leadSourceType?: InputMaybe<ILeadSourceType>
  name?: InputMaybe<Scalars["String"]>
  password?: InputMaybe<Scalars["String"]>
  port?: InputMaybe<Scalars["Int"]>
  publicId?: InputMaybe<Scalars["String"]>
  username?: InputMaybe<Scalars["String"]>
}

export type IUpdateMailchimpUserInput = {
  emailAddress: Scalars["AWSEmail"]
  firstName?: InputMaybe<Scalars["String"]>
  lastName?: InputMaybe<Scalars["String"]>
  phoneNumber?: InputMaybe<Scalars["String"]>
  tags?: InputMaybe<Array<Scalars["String"]>>
}

export type IUpdateMoveOutChecklistInput = {
  cleanCarpetCompleted?: InputMaybe<Scalars["Boolean"]>
  cleanCarpetCompletedDate?: InputMaybe<Scalars["AWSDateTime"]>
  cleanCarpetScheduledCompleted?: InputMaybe<Scalars["Boolean"]>
  cleanCarpetScheduledDate?: InputMaybe<Scalars["AWSDateTime"]>
  cleanCompleted?: InputMaybe<Scalars["Boolean"]>
  cleanCompletedDate?: InputMaybe<Scalars["AWSDateTime"]>
  cleanScheduledCompleted?: InputMaybe<Scalars["Boolean"]>
  cleanScheduledDate?: InputMaybe<Scalars["AWSDateTime"]>
  floorReplacement?: InputMaybe<Scalars["String"]>
  garageInspected?: InputMaybe<Scalars["Boolean"]>
  garageInspectedDate?: InputMaybe<Scalars["AWSDateTime"]>
  id: Scalars["ID"]
  keysTurnedInCompleted?: InputMaybe<Scalars["Boolean"]>
  keysTurnedInDate?: InputMaybe<Scalars["AWSDateTime"]>
  maintenanceDate?: InputMaybe<Scalars["AWSDateTime"]>
  maintenanceDoneCompleted?: InputMaybe<Scalars["Boolean"]>
  maintenanceDoneDate?: InputMaybe<Scalars["AWSDateTime"]>
  maintenanceEmployeeId?: InputMaybe<Scalars["ID"]>
  maintenanceType?: InputMaybe<Scalars["String"]>
  moveInPicturesTakenCompleted?: InputMaybe<Scalars["Boolean"]>
  moveInPicturesTakenDate?: InputMaybe<Scalars["AWSDateTime"]>
  notes?: InputMaybe<Scalars["String"]>
  paint?: InputMaybe<Scalars["String"]>
  paperworkSubmitted?: InputMaybe<Scalars["Boolean"]>
  picturesTakenCompleted?: InputMaybe<Scalars["Boolean"]>
  picturesTakenDate?: InputMaybe<Scalars["AWSDateTime"]>
  schedulerMoveOut?: InputMaybe<Scalars["AWSDateTime"]>
  schedulerNextMoveIn?: InputMaybe<Scalars["AWSDateTime"]>
  walkThroughtCompleted?: InputMaybe<Scalars["Boolean"]>
  walkThroughtDate?: InputMaybe<Scalars["AWSDateTime"]>
}

export type IUpdateOfficeHourInput = {
  closeTime?: InputMaybe<Scalars["AWSTime"]>
  dayOfWeek: IDayOfWeek
  id: Scalars["ID"]
  openTime?: InputMaybe<Scalars["AWSTime"]>
}

export type IUpdateOfficeHourOutput = {
  __typename?: "UpdateOfficeHourOutput"
  id: Scalars["ID"]
}

export type IUpdateParkingSpaceInput = {
  assigned?: InputMaybe<Scalars["Boolean"]>
  comment?: InputMaybe<Scalars["String"]>
  fee?: InputMaybe<Scalars["Float"]>
  id: Scalars["ID"]
  maxSpacesPerUnit?: InputMaybe<Scalars["Int"]>
  parkingType: IParkingType
  spacesPerUnit?: InputMaybe<Scalars["Int"]>
}

export type IUpdatePasswordInput = {
  forgottenPasswordInquiryId: Scalars["ID"]
  newPassword: Scalars["String"]
}

export type IUpdatePasswordOutput = {
  __typename?: "UpdatePasswordOutput"
  isSuccessful: Scalars["Boolean"]
}

export type IUpdatePaymentMethodInput = {
  accountHolderName?: InputMaybe<Scalars["String"]>
  address?: InputMaybe<Scalars["String"]>
  city?: InputMaybe<Scalars["String"]>
  companyName?: InputMaybe<Scalars["String"]>
  country?: InputMaybe<ICountry>
  creditCard?: InputMaybe<ICreditCardInput>
  eCheck?: InputMaybe<IECheckInput>
  id: Scalars["ID"]
  name?: InputMaybe<Scalars["String"]>
  phone?: InputMaybe<Scalars["String"]>
  postalCode?: InputMaybe<Scalars["String"]>
  state?: InputMaybe<Scalars["String"]>
  userId?: InputMaybe<Scalars["ID"]>
}

export type IUpdatePaymentMethodOutput = {
  __typename?: "UpdatePaymentMethodOutput"
  paymentMethod: IPaymentMethod
}

export type IUpdatePetPolicyInput = {
  additionalRestrictions?: InputMaybe<Scalars["String"]>
  allowed: Scalars["Boolean"]
  comment?: InputMaybe<Scalars["String"]>
  deposit?: InputMaybe<Scalars["Float"]>
  dnaFee?: InputMaybe<Scalars["Float"]>
  fee?: InputMaybe<Scalars["Float"]>
  id: Scalars["ID"]
  maxAllowed?: InputMaybe<Scalars["Int"]>
  maxWeight?: InputMaybe<Scalars["Int"]>
  petType: IPetType
  rent?: InputMaybe<Scalars["Float"]>
}

export type IUpdatePromotionInput = {
  description?: InputMaybe<Scalars["String"]>
  endDate?: InputMaybe<Scalars["AWSDateTime"]>
  id: Scalars["ID"]
  startDate?: InputMaybe<Scalars["AWSDateTime"]>
  value?: InputMaybe<Scalars["Float"]>
}

export type IUpdatePromotionOutput = {
  __typename?: "UpdatePromotionOutput"
  id: Scalars["ID"]
}

export type IUpdatePropertyInput = {
  active: Scalars["Boolean"]
  addressLine1?: InputMaybe<Scalars["String"]>
  addressLine2?: InputMaybe<Scalars["String"]>
  assessorsParcelNumber?: InputMaybe<Scalars["String"]>
  city?: InputMaybe<Scalars["String"]>
  communityID?: InputMaybe<Scalars["ID"]>
  country?: InputMaybe<Scalars["String"]>
  dateAcquired?: InputMaybe<Scalars["AWSDateTime"]>
  dateBuilt?: InputMaybe<Scalars["AWSDateTime"]>
  dateRemodeled?: InputMaybe<Scalars["AWSDateTime"]>
  dateRoofReplaced?: InputMaybe<Scalars["AWSDateTime"]>
  fireSprinklered?: InputMaybe<Scalars["Boolean"]>
  id: Scalars["ID"]
  identificationCode?: InputMaybe<Scalars["String"]>
  landAcres?: InputMaybe<Scalars["Float"]>
  latitude?: InputMaybe<Scalars["Float"]>
  longitude?: InputMaybe<Scalars["Float"]>
  marketValue?: InputMaybe<Scalars["Float"]>
  name: Scalars["String"]
  originalCost?: InputMaybe<Scalars["Float"]>
  ownerID?: InputMaybe<Scalars["ID"]>
  postalCode?: InputMaybe<Scalars["String"]>
  primaryUnitType?: InputMaybe<IUnitType>
  squareFeet?: InputMaybe<Scalars["Int"]>
  state?: InputMaybe<Scalars["String"]>
  unitCount?: InputMaybe<Scalars["Int"]>
}

export type IUpdateRecurringPaymentInput = {
  amountCalculationType?: InputMaybe<IAmountCalculationType>
  id: Scalars["ID"]
  paymentInfo?: InputMaybe<IPaymentInfoInput>
}

export type IUpdateRecurringPaymentOutput = {
  __typename?: "UpdateRecurringPaymentOutput"
  recurringPayment: IRecurringPayment
}

export type IUpdateRegionInput = {
  id: Scalars["ID"]
  name: Scalars["String"]
  urlSlug?: InputMaybe<Scalars["String"]>
}

export type IUpdateServiceRequestInput = {
  categoryId?: InputMaybe<Scalars["ID"]>
  completedDate?: InputMaybe<Scalars["AWSDateTime"]>
  description?: InputMaybe<Scalars["String"]>
  employeeId?: InputMaybe<Scalars["ID"]>
  id: Scalars["ID"]
  notes?: InputMaybe<Scalars["String"]>
  onCall?: InputMaybe<Scalars["Boolean"]>
  status?: InputMaybe<IServiceRequestStatus>
}

export type IUpdateSocialMediaInput = {
  id: Scalars["ID"]
  name: ISocialMediaName
  url: Scalars["String"]
}

export type IUpdateUnitInput = {
  addressLine1?: InputMaybe<Scalars["String"]>
  addressLine2?: InputMaybe<Scalars["String"]>
  assessorsParcelNumber?: InputMaybe<Scalars["String"]>
  availabilityNotes?: InputMaybe<Scalars["String"]>
  description?: InputMaybe<Scalars["String"]>
  floor?: InputMaybe<Scalars["Int"]>
  floorPlanId?: InputMaybe<Scalars["ID"]>
  id: Scalars["ID"]
  name: Scalars["String"]
  notes?: InputMaybe<Scalars["String"]>
  propertyId?: InputMaybe<Scalars["ID"]>
  squareFeet?: InputMaybe<Scalars["Int"]>
  stairwell?: InputMaybe<Scalars["Int"]>
  unitNumber?: InputMaybe<Scalars["String"]>
  unitType: IUnitType
}

export type IUpdateUnitPriceInput = {
  id?: InputMaybe<Scalars["ID"]>
  price: Scalars["Float"]
  startDate: Scalars["AWSDateTime"]
  unitId: Scalars["ID"]
}

export type IUpdateUnitPricingInput = {
  id?: InputMaybe<Scalars["ID"]>
  price: Scalars["Float"]
  startDate: Scalars["AWSDateTime"]
  unitId: Scalars["ID"]
}

export type IUpdateUnitStatusInput = {
  id?: InputMaybe<Scalars["ID"]>
  notes?: InputMaybe<Scalars["String"]>
  startDate?: InputMaybe<Scalars["AWSDateTime"]>
  status?: InputMaybe<IUnitStatus>
  unitId: Scalars["ID"]
}

export type IUpdateUtilityInput = {
  id: Scalars["ID"]
  included: Scalars["Boolean"]
  utilityType: IUtilityType
}

export type IUpdateUtilityOutput = {
  __typename?: "UpdateUtilityOutput"
  id: Scalars["ID"]
}

export type IUser = {
  __typename?: "User"
  email?: Maybe<Scalars["String"]>
  emailOptIn?: Maybe<Scalars["Boolean"]>
  entityId?: Maybe<Scalars["ID"]>
  id: Scalars["ID"]
  passwordHash?: Maybe<Scalars["String"]>
  phoneNumber?: Maybe<Scalars["String"]>
  scopes?: Maybe<Array<Scalars["String"]>>
  username?: Maybe<Scalars["String"]>
}

export type IUserWhereInput = {
  emailAddress?: InputMaybe<Scalars["String"]>
  id?: InputMaybe<Scalars["ID"]>
}

export type IUsherDistrict = {
  __typename?: "UsherDistrict"
  id: Scalars["ID"]
  name?: Maybe<Scalars["String"]>
}

export type IUsherDistrictDistrictLeadSourceInput = {
  id: Scalars["ID"]
  name?: InputMaybe<Scalars["String"]>
}

export type IUtility = {
  __typename?: "Utility"
  id: Scalars["ID"]
  included: Scalars["Boolean"]
  utilityType: IUtilityType
}

export type IUtilityCommunityInput = {
  included: Scalars["Boolean"]
  utilityType: IUtilityType
}

export type IUtilityInput = {
  communityId: Scalars["ID"]
  included: Scalars["Boolean"]
  utilityType: IUtilityType
}

export enum IUtilityType {
  Gas = "GAS",
  Heat = "HEAT",
  HotWater = "HOT_WATER",
  Sewer = "SEWER",
  Trash = "TRASH",
  Water = "WATER",
}

export type IVacancy = {
  __typename?: "Vacancy"
  value?: Maybe<Scalars["Float"]>
}

export type IVacancyWhereInput = {
  communityId: Scalars["ID"]
  maxVacantDate?: InputMaybe<Scalars["AWSDateTime"]>
}

export type IVouchCognitoToken = {
  __typename?: "VouchCognitoToken"
  payload?: Maybe<Scalars["String"]>
  token: Scalars["String"]
}

export type IVouchCognitoUser = {
  __typename?: "VouchCognitoUser"
  cognitoUserSession: IVouchCognitoUserSession
}

export type IVouchCognitoUserSession = {
  __typename?: "VouchCognitoUserSession"
  accessToken: IVouchCognitoToken
  idToken: IVouchCognitoToken
  refreshToken: IVouchCognitoToken
}

export type IWebsiteEvent = {
  __typename?: "WebsiteEvent"
  createdDate?: Maybe<Scalars["AWSDateTime"]>
  event?: Maybe<Scalars["String"]>
  facebookUserId?: Maybe<Scalars["String"]>
  googleClientId?: Maybe<Scalars["String"]>
  id: Scalars["ID"]
  items?: Maybe<Array<IWebsiteEventItem>>
  leadSourceId?: Maybe<Scalars["String"]>
  sessionId?: Maybe<Scalars["String"]>
  userId?: Maybe<Scalars["String"]>
}

export type IWebsiteEventInput = {
  event?: InputMaybe<Scalars["String"]>
  facebookUserId?: InputMaybe<Scalars["String"]>
  googleClientId?: InputMaybe<Scalars["String"]>
  items?: InputMaybe<Array<IWebsiteEventItemInput>>
  leadSourceId?: InputMaybe<Scalars["String"]>
  sessionId?: InputMaybe<Scalars["String"]>
  userId?: InputMaybe<Scalars["String"]>
}

export type IWebsiteEventItem = {
  __typename?: "WebsiteEventItem"
  communityId?: Maybe<Scalars["String"]>
  communityName?: Maybe<Scalars["String"]>
  floorPlanId?: Maybe<Scalars["String"]>
  floorPlanName?: Maybe<Scalars["String"]>
  price?: Maybe<Scalars["String"]>
  regionId?: Maybe<Scalars["String"]>
}

export type IWebsiteEventItemInput = {
  communityId?: InputMaybe<Scalars["String"]>
  communityName?: InputMaybe<Scalars["String"]>
  floorPlanId?: InputMaybe<Scalars["String"]>
  floorPlanName?: InputMaybe<Scalars["String"]>
  price?: InputMaybe<Scalars["String"]>
  regionId?: InputMaybe<Scalars["String"]>
}

export type IWhereMailchimpUserInput = {
  emailAddress?: InputMaybe<Scalars["AWSEmail"]>
}

export type ILeaseName = {
  __typename?: "leaseName"
  id: Scalars["ID"]
  name?: Maybe<Scalars["String"]>
}

export type INote = {
  __typename?: "note"
  note?: Maybe<Scalars["String"]>
}

export type IFrontierQueryVariables = Exact<{ [key: string]: never }>

export type IFrontierQuery = {
  __typename?: "Query"
  regions: Array<{ __typename?: "Region"; id: string; name: string; urlSlug?: string | null }>
  districts: Array<{ __typename?: "District"; id: string; displayEmailAddress: any }>
  leadSources: Array<{
    __typename?: "LeadSource"
    name?: string | null
    publicId?: string | null
    districtLeadSources?: Array<{
      __typename?: "DistrictLeadSourceEmbedded"
      phoneNumber?: string | null
      district: { __typename?: "UsherDistrict"; id: string }
    } | null> | null
  }>
  communities: Array<{
    __typename?: "Community"
    id: string
    name: string
    uriSlug?: string | null
    communityVideoUrl?: string | null
    promotionalMessage?: string | null
    websitePromotionalMessage?: string | null
    shortDescription?: string | null
    longDescription?: string | null
    additionalDescription?: string | null
    drivingDirections?: string | null
    adminFee?: number | null
    applicationFee?: number | null
    emailFragment?: string | null
    allowOnlineApplications: boolean
    showOnWebsite: boolean
    primaryProperty?: { __typename?: "Property"; id: string } | null
    district?: { __typename?: "District"; id: string } | null
    parkingSpaces?: Array<{
      __typename?: "ParkingSpace"
      parkingType: IParkingType
      maxSpacesPerUnit?: number | null
      spacesPerUnit?: number | null
      assigned?: boolean | null
      fee?: number | null
    } | null> | null
    petPolicies?: Array<{
      __typename?: "PetPolicy"
      petType: IPetType
      allowed: boolean
      deposit?: number | null
      fee?: number | null
      dnaFee?: number | null
      rent?: number | null
      maxAllowed?: number | null
      maxWeight?: number | null
      additionalRestrictions?: string | null
      comment?: string | null
    } | null> | null
    region?: { __typename?: "Region"; id: string } | null
    images?: Array<{
      __typename?: "Image"
      id: string
      caption?: string | null
      s3Url?: string | null
      altText?: string | null
      tag?: ITagEnum | null
      displayOrder?: number | null
    } | null> | null
    amenities?: Array<{
      __typename?: "Amenity"
      name?: string | null
      amenityGroup?: IAmenityGroup | null
    } | null> | null
    utilities?: Array<{ __typename?: "Utility"; utilityType: IUtilityType; included: boolean } | null> | null
    officeHours?: Array<{
      __typename?: "OfficeHour"
      dayOfWeek: IDayOfWeek
      openTime?: string | null
      closeTime?: string | null
    } | null> | null
  }>
  floorPlans: Array<{
    __typename?: "FloorPlan"
    id: string
    bathrooms?: number | null
    dens?: number | null
    bedrooms?: number | null
    name?: string | null
    description?: string | null
    minimumDeposit?: number | null
    urlSlug?: string | null
    youtubeUrl?: string | null
    matterportUrl?: string | null
    promotionalMessage?: string | null
    promotionalStartDate?: any | null
    promotionalEndDate?: any | null
    rentMin?: number | null
    rentMax?: number | null
    squareFeetMin?: number | null
    squareFeetMax?: number | null
    availableDate?: any | null
    community?: { __typename?: "Community"; id: string; name: string } | null
    images?: Array<{
      __typename?: "Image"
      id: string
      caption?: string | null
      s3Url?: string | null
      altText?: string | null
      tag?: ITagEnum | null
      displayOrder?: number | null
    }> | null
    amenities?: Array<{
      __typename?: "FloorPlanAmenity"
      name?: string | null
      amenityGroup?: IAmenityGroup | null
    }> | null
    terms?: Array<{ __typename?: "TermFee"; minimumMonths: number; additionalRent: number }> | null
  }>
  properties: Array<{
    __typename?: "Property"
    id: string
    addressLine1?: string | null
    addressLine2?: string | null
    assessorsParcelNumber?: string | null
    city?: string | null
    country?: string | null
    dateAcquired?: any | null
    dateBuilt?: any | null
    dateRemodeled?: any | null
    dateRoofReplaced?: any | null
    fireSprinklered?: boolean | null
    identificationCode?: string | null
    landAcres?: number | null
    latitude?: number | null
    longitude?: number | null
    marketValue?: number | null
    name: string
    originalCost?: number | null
    postalCode?: string | null
    primaryUnitType?: IUnitType | null
    squareFeet?: number | null
    state?: string | null
    community?: { __typename?: "Community"; id: string } | null
  }>
  units: Array<{
    __typename?: "Unit"
    id: string
    name: string
    squareFeet?: number | null
    unitType: IUnitType
    unitNumber?: string | null
    assessorsParcelNumber?: string | null
    addressLine1?: string | null
    addressLine2?: string | null
    city?: string | null
    state?: string | null
    postalCode?: string | null
    country?: string | null
    floor?: number | null
    description?: string | null
    notes?: string | null
    availabilityNotes?: string | null
    vacantDate?: any | null
    releaseDate?: any | null
    availableDate?: any | null
    currentRent?: number | null
    currentStatus?: IUnitStatus | null
    stairwell?: number | null
    floorPlan?: {
      __typename?: "ShortFloorPlan"
      id: string
      bedrooms?: number | null
      community?: { __typename?: "ShortCommunity"; id: string } | null
    } | null
    amenities: Array<{
      __typename?: "UnitAmenity"
      id: string
      name?: string | null
      amenityType?: IAmenityType | null
      amenityGroup?: IAmenityGroup | null
      mitsName?: IMitsName | null
    }>
    property?: { __typename?: "ShortProperty"; id: string } | null
  }>
}

export const FrontierDocument = gql`
  query Frontier {
    regions(offset: 0, limit: 10000) {
      id
      name
      urlSlug
    }
    districts(offset: 0, limit: 1000000) {
      id
      displayEmailAddress
    }
    leadSources(offset: 0, limit: 1000000) {
      name
      publicId
      districtLeadSources {
        district {
          id
        }
        phoneNumber
      }
    }
    communities(offset: 0, limit: 10000) {
      id
      name
      uriSlug
      communityVideoUrl
      primaryProperty {
        id
      }
      district {
        id
      }
      promotionalMessage
      websitePromotionalMessage
      shortDescription
      longDescription
      additionalDescription
      drivingDirections
      adminFee
      applicationFee
      emailFragment
      parkingSpaces {
        parkingType
        maxSpacesPerUnit
        spacesPerUnit
        assigned
        fee
      }
      petPolicies {
        petType
        allowed
        deposit
        fee
        dnaFee
        rent
        maxAllowed
        maxWeight
        additionalRestrictions
        comment
      }
      region {
        id
      }
      images {
        id
        caption
        s3Url
        altText
        tag
        displayOrder
      }
      allowOnlineApplications
      showOnWebsite
      amenities {
        name
        amenityGroup
      }
      utilities {
        utilityType
        included
      }
      officeHours {
        dayOfWeek
        openTime
        closeTime
      }
    }
    floorPlans(offset: 0, limit: 10000, orderBy: { name: ASC }) {
      id
      bathrooms
      dens
      bedrooms
      name
      description
      minimumDeposit
      urlSlug
      community {
        id
        name
      }
      youtubeUrl
      matterportUrl
      images {
        id
        caption
        s3Url
        altText
        tag
        displayOrder
      }
      amenities {
        name
        amenityGroup
      }
      terms {
        minimumMonths
        additionalRent
      }
      promotionalMessage
      promotionalStartDate
      promotionalEndDate
      rentMin
      rentMax
      squareFeetMin
      squareFeetMax
      availableDate
    }
    properties(offset: 0, limit: 10000) {
      id
      addressLine1
      addressLine2
      assessorsParcelNumber
      city
      community {
        id
      }
      country
      dateAcquired
      dateBuilt
      dateRemodeled
      dateRoofReplaced
      fireSprinklered
      identificationCode
      landAcres
      latitude
      longitude
      marketValue
      name
      originalCost
      postalCode
      primaryUnitType
      squareFeet
      state
    }
    units(where: { unitTypes: [APARTMENT, TOWNHOME, HOUSE] }, offset: 0, limit: 10000, orderBy: { name: ASC }) {
      id
      name
      floorPlan {
        id
        bedrooms
        community {
          id
        }
      }
      amenities {
        id
        name
        amenityType
        amenityGroup
        mitsName
      }
      squareFeet
      unitType
      unitNumber
      assessorsParcelNumber
      addressLine1
      addressLine2
      city
      state
      postalCode
      country
      floor
      description
      notes
      availabilityNotes
      vacantDate
      releaseDate
      availableDate
      currentRent
      currentStatus
      property {
        id
      }
      stairwell
    }
  }
`

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string
) => Promise<T>

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action()

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    Frontier(
      variables?: IFrontierQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<IFrontierQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<IFrontierQuery>(FrontierDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        "Frontier",
        "query"
      )
    },
  }
}
export type Sdk = ReturnType<typeof getSdk>
