import { Helmet } from "react-helmet"
import config from "../../site.config"
import React, { useEffect } from "react"
import AOS from "aos"

/** components */
import SiteNavCommunity from "./SiteNavCommunity"
import { ColorPalette } from "./Theme"
import Footer from "./Footer"

/** props */
interface Props {
  title: string
  description: string
  keywords: string
  convertUrl?: string
  luvWidget?: boolean
  imageTwitter: string
  imageOpenGraph: string
  colorPalette: ColorPalette
}

/** const */
const LayoutCommunity: React.FC<Props> = (props) => {
  useEffect(() => {
    AOS.init()
    AOS.refresh()
  }, [])

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta http-equiv="content-language" content="us-en" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0" />
        <meta name="HandheldFriendly" content="true" />
        <meta name="application-name" content="Norhart Apartments" />
        <meta name="author" content="Norhart" />
        <title>{props.title}</title>
        <meta name="description" content={props.description} />
        <meta name="keywords" content={props.keywords} />
        <meta name="theme-color" content="#f0f0ff" media="(prefers-color-scheme: light)" />
        <meta name="theme-color" content="#f0f0ff" media="(prefers-color-scheme: dark)" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@norhart" />
        <meta name="twitter:creator" content="@norhart" />
        <meta name="twitter:title" content={props.title} />
        <meta name="twitter:description" content={props.description} />
        <meta name="twitter:image:src" content={props.imageTwitter} />
        <meta name="twitter:app:country" content="US" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Norhart" />
        <meta property="og:title" content={props.title} />
        <meta property="og:description" content={props.description} />
        <meta property="og:url" content="norhart-website" />
        <meta property="og:image:type" content="imagepng" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image" content={props.imageOpenGraph} />
        <meta property="og:locale" content="en_US" />
        <meta name="yandex-verification" content="bbc58cdf4b62fcca" />

        {/* Safari Pinned Tabs */}
        <link rel="mask-icon" href="/general/pinned-tab-icon.svg" color="#ff0000" />

        {/* OLDER APPLE DEVICES */}
        <link rel="apple-touch-icon" sizes="57x57" href="/general/apple-touch-icon-57.png" />
        <link rel="apple-touch-icon" sizes="60x60" href="/general/apple-touch-icon-60.png" />
        <link rel="apple-touch-icon" sizes="72x72" href="/general/apple-touch-icon-72.png" />
        <link rel="apple-touch-icon" sizes="76x76" href="/general/apple-touch-icon-76.png" />
        <link rel="apple-touch-icon" sizes="114x114" href="/general/apple-touch-icon-114.png" />

        {/* APPLE TOUCH ROOT DIR */}
        <link rel="apple-touch-icon" sizes="512x512" href="/apple-touch-icon.png" />

        {/* APPLE TOUCH ICONS */}
        <link rel="apple-touch-icon" sizes="120x120" href="/general/apple-touch-icon-120.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/general/apple-touch-icon-152.png" />
        <link rel="apple-touch-icon" sizes="167x167" href="/general/apple-touch-icon-167.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/general/apple-touch-icon-180.png" />

        {/* FAV ICON ROOT DIR */}
        <link rel="icon" type="image/png" sizes="64x64" href="/favicon.ico" />

        {/* FAV ICONS */}
        <link rel="icon" type="image/png" sizes="16x16" href="/general/favicon-16x16.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/general/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="48x48" href="/general/favicon-48x48.png" />
        <link rel="icon" type="image/png" sizes="96x96" href="/general/favicon-96x96.png" />
        <link rel="icon" type="image/png" sizes="128x128" href="/general/favicon-128x128.png" />
        <link rel="icon" type="image/png" sizes="180x180" href="/general/favicon-180x180.png" />
        <link rel="icon" type="image/png" sizes="192x192" href="/general/favicon-192x192.png" />

        {/* WINDOWS 8 & 10 TILE */}
        <meta name="msapplication-config" content="/tile.xml" />

        {/* MANIFEST FOR ANDROID */}
        <link rel="manifest" href="/general/site.webmanifest" />

        <link rel="canonical" href={config.siteMetadata.siteUrl} />
        <link rel="preconnect" href={config.siteMetadata.siteUrl} />
        <script src="https://s.yimg.com/wi/ytc.js" defer={true}></script>
        <script src="https://connect.facebook.net/en_US/fbevents.js" defer={true}></script>
        <script
          src="https://connect.facebook.net/signals/config/376828226139532?v=2.9.45&amp;r=stable"
          defer={true}
        ></script>
        <script
          src="https://www.googletagmanager.com/gtag/js?id=G-ZWKZ00SK9F&amp;l=dataLayer&amp;cx=c"
          defer={true}
        ></script>
      </Helmet>

      <SiteNavCommunity convertUrl={props.convertUrl} luvWidget={props.luvWidget} colorPalette={props.colorPalette} />

      {props.children}

      <Footer colorPalette={props.colorPalette} />
    </div>
  )
}

/** export */
/** export */
export default LayoutCommunity
